"use client";
import React from "react";


export default function ListingSteps({ step1, step2, step3, step4 }) {

  const Step = ({ num, name, isOn }) => {
    return (
      <div className={`border-brandYellow border-solid ${isOn ? "border-b-2" : ""} flex flex-col lg:flex-row  items-start lg:items-center `}>
        <span className={`text-2xl mr-2 ${isOn ? "text-brandYellow" : ""}`}>
          {num}
        </span>
        <span className={`text-sm ${isOn ? "text-brandYellow" : ""}`}> 
         {name}
        </span>
      </div>
    );
  };

  return (
    <div className="w-full flex justify-between border-b border-solid border-borderGray my-4">
      <Step num="1" name="Watch Details" isOn={step1 || step2 || step3 || step4} />
      <Step num="2" name="Watch Images" isOn={step2 || step3 || step4} />
      <Step num="3" name="Seller Information" isOn={step3 || step4} />
      <Step num="4" name="Summary" isOn={step4} />
    </div>
  );
}
