
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";

export const USER_LIST_REQUEST = "USER_LIST_REQUEST";
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS";
export const USER_LIST_FAIL = "USER_LIST_FAIL";

export const NEW_USER_REQUEST = "NEW_USER_REQUEST";
export const NEW_USER_SUCCESS = "NEW_USER_SUCCESS";
export const NEW_USER_FAIL = "NEW_USER_FAIL";

export const EDIT_USER_REQUEST = "EDIT_USER_REQUEST";
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS";
export const EDIT_USER_FAIL = "EDIT_USER_FAIL";

export const USER_DETAILS_REQUEST = "USER_DETAILS_REQUEST";
export const USER_DETAILS_SUCCESS = "USER_DETAILS_SUCCESS";
export const USER_DETAILS_FAIL = "USER_DETAILS_FAIL";

export const MESSAGE_LIST_REQUEST = "MESSAGE_LIST_REQUEST";
export const MESSAGE_LIST_SUCCESS = "MESSAGE_LIST_SUCCESS";
export const MESSAGE_LIST_FAIL = "MESSAGE_LIST_FAIL";

export const MESSAGE_DETAILS_REQUEST = "MESSAGE_DETAILS_REQUEST";
export const MESSAGE_DETAILS_SUCCESS = "MESSAGE_DETAILS_SUCCESS";
export const MESSAGE_DETAILS_FAIL = "MESSAGE_DETAILS_FAIL";

export const PRODUCT_LIST_REQUEST = "PRODUCT_LIST_REQUEST";
export const PRODUCT_LIST_SUCCESS = "PRODUCT_LIST_SUCCESS";
export const PRODUCT_LIST_FAIL = "PRODUCT_LIST_FAIL";

export const PRODUCT_DETAILS_REQUEST = "PRODUCT_DETAILS_REQUEST";
export const PRODUCT_DETAILS_SUCCESS = "PRODUCT_DETAILS_SUCCESS";
export const PRODUCT_DETAILS_FAIL = "PRODUCT_DETAILS_FAIL";

export const USER_OPTIONS_LIST_REQUEST = "USER_OPTIONS_LIST_REQUEST";
export const USER_OPTIONS_LIST_SUCCESS = "USER_OPTIONS_LIST_SUCCESS";
export const USER_OPTIONS_LIST_FAIL = "USER_OPTIONS_LIST_FAIL";

export const UNREAD_MESSAGE_REQUEST = "UNREAD_MESSAGE_REQUEST";
export const UNREAD_MESSAGE_SUCCESS = "UNREAD_MESSAGE_SUCCESS";
export const UNREAD_MESSAGE_FAIL = "UNREAD_MESSAGE_FAIL";