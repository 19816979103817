import Breadcrumbs from "@mui/material/Breadcrumbs";
import React, { useEffect, useState } from "react";
import { GrSecure } from "react-icons/gr";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import { convToLocalDate, convtPrice } from "../../api/functions";
import ScreenTitle from "../../components/ScreenTitle";
import {
  AddressLine,
  ErrorMessage,
  FormattedText,
  H3,
  Loader,
  OrderStatusTag,
  ProductName,
  ProductNameLink,
  UserAvatar,
} from "../../components/smallComponents";
import {
  orderDetailsRequest,
  updateOrderPriceRequest,
  updateOrderShippingFeeRequest,
} from "../../redux/actions/orderActions";
import commonStyle from "../../style/commonStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle";
import {
  ActionWithFileButton,
  AdminCancelOrderActionButton,
  AdminUpdatePriceButton,
  ContactButton,
  OrderActionButton,
  ShipOrderButton,
} from "./orderActionButtons";
import { PureTextField } from "../../components/inputs";
import { AdminNotesDialog, ViewFilesDialog } from "../../components/dialogs";
import { Button } from "../../components/buttons";

const S3_DOMAIN = process.env.REACT_APP_S3_DOMAIN;

export default function OrderDetails() {
  const { id } = useParams();
  useDocumentTitle("Order Details");
  const dispatch = useDispatch();

  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  const { loading, details, success, error } = useSelector(
    (state) => state.orderDetails
  );

  const {
    success: priceUpdateSuccess,
    loading: priceUpdateLoading,
    error: priceUpdateError,
  } = useSelector((state) => state.updateOrderPrice);

  const {
    success: shippingUpdateSuccess,
    loading: shippingUpdateLoading,
    error: shippingUpdateError,
  } = useSelector((state) => state.updateOrderShippingFee);

  const [saveStatus, setSaveStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const [courierInfo, setCourierInfo] = useState("");

  const [editPrice, setEditPrice] = useState(false);
  const [editShipping, setEditShipping] = useState(false);
  const [newPrice, setNewPrice] = useState("");
  const [shippingFee, setShippingFee] = useState("");

  const [showPaymentResultModal, setShowPaymentResultModal] = useState(false);
  const [paymentResultFiles, setPaymentResultFiles] = useState("");

  const [adminNotesModal, setAdminNotesModal] = useState(false);
  const [updateDetailsCnt, setUpdateDetailsCnt] = useState(0);

  useEffect(() => {
    dispatch(orderDetailsRequest(id));
    setSaveStatus({
      loading: false,
      success: false,
      error: "",
    });
  }, [dispatch, saveStatus.success, shippingUpdateSuccess, priceUpdateSuccess, updateDetailsCnt]);

  useEffect(() => {
    if (success) {
      setNewPrice(details.price);
      setShippingFee(details.shippingFee);

      if (
        details.actionRecords.filter(
          (x) => x.action === "SHIPPED_PRODUCT_TO_BUYER"
        ).length
      ) {
        setCourierInfo(
          JSON.parse(
            details.actionRecords.filter(
              (x) => x.action === "SHIPPED_PRODUCT_TO_BUYER"
            )[0].action_info
          )
        );
      }
      if (
        details.actionRecords.filter(
          (x) => x.action === "BUYER_UPLOAD_PAYMENT_RESULT"
        ).length
      ) {
        let fileRecords = details.actionRecords.filter(
          (x) => x.action === "BUYER_UPLOAD_PAYMENT_RESULT"
        );
        let files = [];
        fileRecords.forEach((ele) => {
          files.push(JSON.parse(ele.action_info));
        });
        setPaymentResultFiles({
          notes: "",
          attachments: files,
        });
      }
    } else {
      setNewPrice("");
      setShippingFee("");
      setCourierInfo("");
      setPaymentResultFiles("");
    }
  }, [success]);

  const successActionDone = () => {
    dispatch(orderDetailsRequest(id));
  };
  const saveNewPrice = () => {
    let data = {
      newPrice: newPrice,
    };
    dispatch(updateOrderPriceRequest(id, data));
    setEditPrice(false);
  };

  const saveNewShippingFee = () => {
    let data = {
      shippingFee: shippingFee,
    };
    dispatch(updateOrderShippingFeeRequest(id, data));
    setEditShipping(false);
  };

  const openAdminNotesModal = () => {
    setAdminNotesModal(true)
  }

  const closeNotesDialogWithSuccess = () => {
    setAdminNotesModal(false);
    setUpdateDetailsCnt(updateDetailsCnt + 1);
  }

  return (
    <div className={commonStyle.pageContainer}>
      {showPaymentResultModal && (
        <ViewFilesDialog
          isDialogOpen={showPaymentResultModal}
          closeDialog={() => setShowPaymentResultModal(false)}
          file={paymentResultFiles}
          modalTitle="Payment Result"
        />
      )}
      {adminNotesModal && (
        <AdminNotesDialog
          modalOpen={adminNotesModal}
          modalTitle={"Order Notes"}
          successActionText={"Order notes updated successfully"}
          orderId={id}
          onClose={() => setAdminNotesModal(false)}
          modalText={"Add Notes to order (Printed on order PDF)"}
          successActionDone={closeNotesDialogWithSuccess}
          modalButtonText={"Submit"}
          oriAdminNotes={success ? details.adminNotes : ""}
        />
      )}
       

      <div className="w-full p-8">
        <Breadcrumbs aria-label="breadcrumb">
          <NavLink underline="hover" color="inherit" to="/">
            Home
          </NavLink>
          <NavLink underline="hover" color="inherit" to="/orders">
            Orders
          </NavLink>
          <NavLink underline="hover" color="inherit">
            Details
          </NavLink>
        </Breadcrumbs>
        <ScreenTitle title="Orders Details" />

        {loading ? (
          <Loader mess="Requesting details..." />
        ) : success ? (
          <div className="w-full">
            <div className="w-full h-full border-b border-solid border-borderGray mt-6 lg:justify-between">
              <div className="w-full flex items-center p-4">
                <span>Order Number: {details.orderNumber}</span>

                <OrderStatusTag currState={details.currStateDesc} />
              </div>

              <div className="w-full flex justify-between p-4 ">
                <div className="w-1/2 flex ">
                  <ProductNameLink
                    productId={details.productId}
                    image={details.imageUris.length ? details.imageUris[0] : ""}
                    brandName={details.brandName}
                    modelName={details.modelName}
                    referenceNumber={details.referenceNumber}
                    price={""}
                  />
                </div>

                {details.actions.includes("SEND_PAYMENT_METHOD") ? (
                  <div className="w-full lg:w-1/2">
                    {priceUpdateError && (
                      <div className="w-full my-2">
                        <ErrorMessage mess={priceUpdateError} />
                      </div>
                    )}
                    {shippingUpdateError && (
                      <div className="w-full my-2">
                        <ErrorMessage mess={shippingUpdateError} />
                      </div>
                    )}

                    <table className="w-full table-auto mt-8 lg:mt-0">
                      <tbody>
                        <tr>
                          <td className="w-1/3 py-1">Product Price</td>
                          <td className="w-1/3 py-1">
                            {editPrice ? (
                              <PureTextField
                                type="text"
                                name="keyword"
                                placeholder="Price"
                                autofocus={true}
                                value={newPrice}
                                hasBorder={true}
                                showSearchIcon={false}
                                dollarIcon={true}
                                onChange={(query) => setNewPrice(query)}
                              />
                            ) : (
                              <span>{convtPrice(details.price)}</span>
                            )}
                          </td>
                          <td>
                            {editPrice ? (
                              <>
                                {priceUpdateLoading ? (
                                  <Loader mess="Updating" />
                                ) : (
                                  <button
                                    className="text-sm hover:text-brandYellow ml-4"
                                    onClick={saveNewPrice}
                                  >
                                    Update
                                  </button>
                                )}
                                <button
                                  className="text-sm hover:text-brandYellow ml-4"
                                  onClick={() => setEditPrice(false)}
                                >
                                  Cancel
                                </button>
                              </>
                            ) : (
                              <button
                                className="underline hover:text-brandYellow"
                                onClick={() => setEditPrice(true)}
                              >
                                Change Price
                              </button>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td className="w-1/3 py-1">Shipping Fee</td>
                          <td className="w-1/3 py-1">
                            {editShipping ? (
                              <PureTextField
                                type="text"
                                name="keyword"
                                placeholder="Shipping fee"
                                autofocus={true}
                                value={shippingFee}
                                hasBorder={true}
                                showSearchIcon={false}
                                dollarIcon={true}
                                onChange={(query) => setShippingFee(query)}
                              />
                            ) : (
                              <span>{convtPrice(details.shippingFee)}</span>
                            )}
                          </td>
                          <td>
                            {editShipping ? (
                              <>
                                {shippingUpdateLoading ? (
                                  <Loader mess="Updating" />
                                ) : (
                                  <button
                                    className="text-sm hover:text-brandYellow ml-4"
                                    onClick={saveNewShippingFee}
                                  >
                                    Update
                                  </button>
                                )}
                                <button
                                  className="text-sm hover:text-brandYellow ml-4"
                                  onClick={() => setEditShipping(false)}
                                >
                                  Cancel
                                </button>
                              </>
                            ) : (
                              <button
                                className="underline hover:text-brandYellow"
                                onClick={() => setEditShipping(true)}
                              >
                                Add Shipping Fee
                              </button>
                            )}
                          </td>
                        </tr>

                        <tr>
                          <td className="w-1/3 py-1 text-lg font-semibold">
                            Total Price
                          </td>
                          <td className="w-1/3 py-1 text-lg font-semibold">
                            <span>
                              {convtPrice(
                                Number(details.price) +
                                  Number(details.shippingFee)
                              )}
                            </span>
                          </td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div className="w-full lg:w-1/2 flex flex-col mt-8 lg:mt-0 justify-start items-start lg:justify-end lg:items-end">
                    <div className="flex items-center">
                      <p>Product Price: {convtPrice(details.price)}</p>
                    </div>

                    <div className="flex items-center mt-2">
                      <p>Shipping Fee: {convtPrice(details.shippingFee)}</p>
                    </div>

                    <div className="flex items-center mt-2 text-lg font-semibold">
                      Order Total:
                      {convtPrice(
                        Number(details.shippingFee) + Number(details.price)
                      )}
                    </div>

                    {paymentResultFiles && (
                      <button
                        onClick={() => setShowPaymentResultModal(true)}
                        className="w-full text-left lg:text-right hover:text-brandYellow underline text-sm mt-4"
                      >
                        View Payment Result
                      </button>
                    )}
                    {details.orderPdfUri && (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={S3_DOMAIN + details.orderPdfUri}
                        className="mt-4 underline hover:text-brandYellow"
                      >
                        Download Order Certificate
                      </a>
                    )}

                  </div>
                )}
              </div>
              <div className="w-full p-4">
                {details.actions.includes("SEND_SHIPPING_LABEL_TO_SELLER") ? (
                  <div className="mt-4">
                    <ActionWithFileButton
                      orderType={"standardOrder"}
                      actionName="SEND_SHIPPING_LABEL_TO_SELLER"
                      modalTitle={"Send Shipping Label"}
                      modalText="Please upload the shipping label"
                      actionButtonName="Send Shipping Label"
                      successActionText={
                        " Shipping label files sent successfully."
                      }
                      orderId={details.id}
                      userType={"platform"}
                      modalButtonText="Send Shipping Label"
                      successActionDone={successActionDone}
                    />
                  </div>
                ) : (
                  <></>
                )}
                {details.actions.includes("PRODUCT_RECEIVED") ? (
                  <div className="mt-4">
                    <OrderActionButton
                      orderType={"standardOrder"}
                      actionName="PRODUCT_RECEIVED"
                      actionButtonName="Confirm Received Product"
                      modalTitle="Confirm Received Product"
                      modalText="Are you sure you have received the product?"
                      successActionText="Product received!"
                      modalButtonText="Yes, Proceed"
                      orderId={details.id}
                      userType={"platform"}
                      successActionDone={successActionDone}
                    />
                  </div>
                ) : (
                  <></>
                )}

                {details.actions.includes("PREORDER_PRODUCT_RECEIVED") ? (
                  <div className="mt-4">
                    <OrderActionButton
                      orderType={"standardOrder"}
                      actionName="PREORDER_PRODUCT_RECEIVED"
                      actionButtonName="Confirm Received Product"
                      modalTitle="Confirm Received Product"
                      modalText="Are you sure you have received the product?"
                      successActionText="Product received!"
                      modalButtonText="Yes, Proceed"
                      orderId={details.id}
                      userType={"platform"}
                      successActionDone={successActionDone}
                    />
                  </div>
                ) : (
                  <></>
                )}

                {details.actions.includes("SEND_PAYMENT_METHOD") ? (
                  <div className="mt-4">
                    <OrderActionButton
                      orderType={"standardOrder"}
                      actionName="SEND_PAYMENT_METHOD"
                      actionButtonName="Confirm Order"
                      modalTitle="Confirm Order"
                      modalText="After confirm the order, the order price can not be changed, are you sure you want to proceed?"
                      successActionText="Order has been confirmed!"
                      modalButtonText="Yes, Proceed"
                      orderId={details.id}
                      userType={"platform"}
                      successActionDone={successActionDone}
                    />
                  </div>
                ) : (
                  <></>
                )}

                {details.actions.includes("DONE_OFFLINE_PAYMENT") ? (
                  <div className="mt-4">
                    <OrderActionButton
                      orderType={"standardOrder"}
                      actionName="DONE_OFFLINE_PAYMENT"
                      actionButtonName="Confirm Received Payment"
                      modalTitle="Confirm Received Payment"
                      modalText="Are you sure you have received the payment?"
                      successActionText="Order payment received!"
                      modalButtonText="Yes, Proceed"
                      orderId={details.id}
                      userType={"platform"}
                      successActionDone={successActionDone}
                    />
                  </div>
                ) : (
                  <></>
                )}

                {details.currState === "PRODUCT_RECEIVED_BY_PLATFORM" && (
                  <div className="w-full flex">
                    {details.actions.includes("PRODUCT_INSPECTION_PASS") ? (
                      <div className="w-1/2 mt-4">
                        <ActionWithFileButton
                          orderType={"standardOrder"}
                          actionName="PRODUCT_INSPECTION_PASS"
                          actionButtonName="Passing the Inspection"
                          modalTitle="Passing the Inspection"
                          modalText="Please upload the certificate of the inspection"
                          successActionText="Product successfully passed inspection."
                          modalButtonText="Submit"
                          orderId={details.id}
                          userType={"platform"}
                          successActionDone={successActionDone}
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                    {details.actions.includes("PRODUCT_INSPECTION_FAIL") ? (
                      <div className="w-1/2 mt-4 ml-4">
                        <ActionWithFileButton
                          orderType={"standardOrder"}
                          actionName="PRODUCT_INSPECTION_FAIL"
                          actionButtonName="Failing the Inspection"
                          modalTitle="Failing the Inspection"
                          modalText="Please upload the certificate of the inspection"
                          successActionText="Product did not pass inspection."
                          modalButtonText="Submit"
                          orderId={details.id}
                          userType={"platform"}
                          successActionDone={successActionDone}
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                )}
                {details.actions.includes("SHIPPED_PRODUCT_TO_BUYER") ? (
                  <div className="mt-4">
                    <ShipOrderButton
                      orderType={"standardOrder"}
                      actionName="SHIPPED_PRODUCT_TO_BUYER"
                      actionButtonName="Ship Order"
                      modalTitle="Ship Order"
                      modalText="Please enter order tracking details"
                      successActionText="Shipping details updated successfully."
                      modalButtonText="Submit"
                      orderId={details.id}
                      userType={"platform"}
                      successActionDone={successActionDone}
                    />
                  </div>
                ) : (
                  <></>
                )}

                {details.actions.includes("PICK_UP_AT_STORE") ? (
                  <div className="mt-4">
                    <OrderActionButton
                      orderType={"standardOrder"}
                      actionName="PICK_UP_AT_STORE"
                      actionButtonName="Watch picked up at store"
                      modalTitle="Watch Picked Up"
                      modalText="Are you sure the user has picked up watch from store?"
                      successActionText="Order completed!"
                      modalButtonText="Yes, Proceed"
                      orderId={details.id}
                      userType={"platform"}
                      successActionDone={successActionDone}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
            {/* Authenticae Order */}
            {(details.type === "SELLERINSPECTION" ||
              details.type === "BUYERINSPECTION") && (
              <div className="w-full h-full border-b border-solid border-borderGray mt-6  p-4 ">
                <div>
                  <div className="flex items-center">
                    <GrSecure className="w-8 h-8 text-brandYellow" />
                    <span className="text-xl ml-2">Authenticate Guarantee</span>
                  </div>

                  <div className="pl-10 w-full">
                    <p className="">
                      Service Fee: ${convtPrice(details.serviceFee)}
                    </p>
                    {details.type === "BUYERINSPECTION" ? (
                      <p className="mt-1 text-sm text-gray">
                        (Buyer pay service fee)
                      </p>
                    ) : (
                      <p className="mt-1 text-sm text-gray">
                        (Seller pay service fee)
                      </p>
                    )}
                    {details.serviceFeePaid ? (
                      <p className="mt-1">Service Fee Payment Status: Paid</p>
                    ) : (
                      <></>
                    )}
                    {/* seller or buyer pay service fee */}
                  </div>
                </div>
              </div>
            )}

            {/* seller info */}
            {details.otherInfo && details.type === "PREORDER" && (
              <div className="w-full h-full border-b border-solid border-borderGray mt-6 p-4">
                <p>Deliver Method : {details.otherInfo.deliver_method} </p>
              </div>
            )}

            {details.type !== "PLATFORMDIRECT" &&
              details.type !== "PREORDER" && (
                <div className="w-full h-full border-b border-solid border-borderGray mt-6  p-4">
                  <H3 title={"Seller"} />

                  <div className="flex justify-between flex-col lg:flex-row">
                    <div className="flex items-center mt-4">
                      <UserAvatar name={details.sellerName} />{" "}
                      <span className="ml-4">{details.sellerName}</span>
                    </div>

                    <div className="w-fit text-sm text-darkGray mt-4">
                      <ContactButton
                        recipientId={details.sellerId}
                        productId={details.productId}
                        refType={"PRODUCT"}
                        buttonName={"Contact Seller"}
                      />
                    </div>
                  </div>
                </div>
              )}

            {/* buyer info */}
            <div className="w-full h-full border-b border-solid border-borderGray mt-6  p-4">
              <H3 title={"Buyer"} />

              <div className="flex justify-between flex-col lg:flex-row">
                <div className="flex items-center mt-4">
                  <UserAvatar name={details.buyerName} />{" "}
                  <span className="ml-4">{details.buyerName}</span>
                </div>

                <div className="w-fit text-sm text-darkGray mt-4">
                  <ContactButton
                    recipientId={details.buyerId}
                    productId={details.productId}
                    refType={"PRODUCT"}
                    buttonName={"Contact Buyer"}
                  />
                </div>
              </div>
            </div>

            {/* buyer info */}
            <div className="w-full h-full border-b border-solid border-borderGray mt-6 p-4">
              <H3 title={"Shipping Address"} />

              {courierInfo && (
                <div className="text-sm text-darkGray mt-4">
                  <p>Courier: {courierInfo.courier.label}</p>
                  <p>Tracking Number: {courierInfo.trackingNumber}</p>
                </div>
              )}
              <div className="text-sm text-darkGray mt-4">
                <AddressLine
                  firstName={details.shippingAddr.firstName}
                  lastName={details.shippingAddr.lastName}
                  phone={details.shippingAddr.phone}
                  streetLine1={details.shippingAddr.streetLine1}
                  city={details.shippingAddr.city}
                  country={
                    details.shippingAddr.country
                      ? details.shippingAddr.country.label
                      : ""
                  }
                  region={details.shippingAddr.region}
                  postalCode={details.shippingAddr.postalCode}
                  showName={true}
                  showPhone={true}
                />
              </div>
            </div>
            {/* buyer info */}
            <div className="w-full h-full border-b border-solid border-borderGray mt-6 lg:justify-between p-4">
              <H3 title={"Billing Address"} />
              <div className="text-sm text-darkGray mt-4">
                <AddressLine
                  firstName={details.billingAddr.firstName}
                  lastName={details.billingAddr.lastName}
                  phone={details.billingAddr.phone}
                  streetLine1={details.billingAddr.streetLine1}
                  city={details.billingAddr.city}
                  country={
                    details.billingAddr.country
                      ? details.billingAddr.country.label
                      : ""
                  }
                  region={details.billingAddr.region}
                  postalCode={details.billingAddr.postalCode}
                  showName={true}
                  showPhone={true}
                />
              </div>
            </div>

            {/* order status change details */}
            <div className="w-full h-full border-solid border-b border-borderGray mt-6 lg:justify-between p-4">
              <H3 title={"Order Logs"} />
              <div className="text-sm text-darkGray mt-4">
                <div>
                  <span className="text-sm">
                    {convToLocalDate(details.atime)}
                  </span>
                  <span className="text-sm ml-4">Order created</span>
                </div>

                {details.actionRecords.length ? (
                  details.actionRecords.map((item, index) => (
                    <div key={index} className="mt-2">
                      <span className="text-sm">
                        {convToLocalDate(item.atime)}
                      </span>
                      <span className="text-sm ml-4"> {item.actionName}</span>
                      {item.userType && (
                        <span className="text-sm">
                          {" by " + item.userType}
                        </span>
                      )}
                    </div>
                  ))
                ) : (
                  <></>
                )}
              </div>
            </div>
            {/* order actions */}
            <div className="w-full h-full border-b border-solid border-borderGray mt-6 lg:justify-between p-4">
              <H3 title={"Order Notes"} />
              <div className="text-sm text-darkGray mt-4">
                {details.adminNotes && <FormattedText text= {details.adminNotes} />}
                {!details.orderPdfUri && 
                <div className="w-fit mt-4">
                  <Button name={details.adminNotes ? "Update Order Notes" : "Add Order Notes"} action={openAdminNotesModal} />
                </div>}
              </div>
            </div>
            <div className="w-full flex flex-col justify-start p-4">
              {details.actions.includes("CANCEL_ORDER") ? (
                <div className="mt-4">
                  <OrderActionButton
                    orderType={"standardOrder"}
                    actionName="CANCEL_ORDER"
                    actionButtonName="Cancel Order"
                    modalTitle="Cancel Order"
                    modalText="Are you sure you want to cancel the order?"
                    successActionText="Order has been cancelled!"
                    modalButtonText="Yes, Please Cancel The Order"
                    orderId={details.id}
                    userType={"platform"}
                    successActionDone={successActionDone}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
            {/* only for order created by admin*/}

            {
              details.currState === "COMPLETED" && Number(details.adminId) > 0 ? 
              <div className="grid grid-cols-1 gap-6 pt-8 pl-4 border-t border-solid border-borderGray">
                  <AdminCancelOrderActionButton
                    orderType={"standardOrder"}
                    actionName="CANCEL_ORDER"
                    actionButtonName="Cancel Order"
                    modalTitle="Cancel Order"
                    modalText="Are you sure you want to cancel the order?"
                    successActionText="Order has been cancelled!"
                    modalButtonText="Yes, Please Cancel The Order"
                    orderId={details.id}
                    userType={"platform"}
                    successActionDone={successActionDone}
                  />
                  <AdminUpdatePriceButton
                    orderType={"standardOrder"}
                    actionButtonName="Update Order Price"
                    modalTitle="Update Order Price"
                    modalText="Please enter the new order price"
                    successActionText="Order price has been updated!"
                    modalButtonText="Update"
                    orderId={details.id}
                    userType={"platform"}
                    successActionDone={successActionDone}
                    oriPrice={details.price}
                  />
              </div> : <></>
            }
          </div>
        ) : error ? (
          <ErrorMessage mess={error} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
