import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import React from "react";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import Header from "./components/Header/Header";
import SideMenu from "./components/SideMenu/SideMenu";
import { RequireAuth } from "./routesFunctions";
import Dashboard from "./screens/dashboard/Dashboard";
import Login from "./screens/auth/Login";
import BlogEdit from "./screens/blog/BlogEdit";
import BlogList from "./screens/blog/BlogList";
import BlogPreview from "./screens/blog/BlogPreview";
import NewBlog from "./screens/blog/NewBlog";
import ErrorBoundary from "./screens/errorPages/ErrorBoundary";
import NoAuthority from "./screens/errorPages/NoAuthority";
import NotFound from "./screens/errorPages/NotFound";
import DialogDetails from "./screens/message/DialogDetails";
import DialogList from "./screens/message/DialogList";
import OrderDetails from "./screens/order/OrderDetails";
import OrderList from "./screens/order/OrderList";
import ProductDetails from "./screens/product/ProductDetails";
import ProductList from "./screens/product/ProductList";
import UserDetails from "./screens/user/UserDetails";
import UserList from "./screens/user/UserList";
import BrandList from "./screens/watch/BrandList";
import EditBrand from "./screens/watch/EditBrand";
import EditWatch from "./screens/watch/EditWatch";
import ModelList from "./screens/watch/ModelList";
import NewBrand from "./screens/watch/NewBrand";
import NewModel from "./screens/watch/NewModel";
import NewWatch from "./screens/watch/NewWatch";
import WatchList from "./screens/watch/WatchList";
import AgreementEdit from "./screens/webContent/AgreementEdit";
import AgreementList from "./screens/webContent/AgreementList";
import CareerEdit from "./screens/webContent/CareerEdit";
import CareerList from "./screens/webContent/CareerList";
import CompanyInfoEdit from "./screens/webContent/CompanyInfoEdit";
import CompanyInfoList from "./screens/webContent/CompanyInfoList";
import EditQuestion from "./screens/webContent/FAQ/EditQuestion";
import FaqList from "./screens/webContent/FAQ/FaqList";
import NewQuestion from "./screens/webContent/FAQ/NewQuestion";
import ImageEdit from "./screens/webContent/ImageEdit";
import ImageList from "./screens/webContent/ImageList";
import NewCareer from "./screens/webContent/NewCareer";
import NewProduct from "./screens/product/NewProduct";
import EditProduct from "./screens/product/EditProduct";
import NewUser from "./screens/user/NewUser";
import EditUser from "./screens/user/EditUser";
import AdsList from "./screens/webContent/ads/AdsList";
import NewAd from "./screens/webContent/ads/NewAd";
import EditAd from "./screens/webContent/ads/EditAd";
import ConsignmentOrderList from "./screens/order/consignment/ConsignmentOrderList";
import ConsignmentOrderDetails from "./screens/order/consignment/ConsignmentOrderDetails";
import NewProductByDealer from "./screens/product/NewProductByDealer";
import NewProductByPlatform from "./screens/product/NewProductByPlatform";
import NewProductByConsignment from "./screens/product/NewProductByConsignment";
import NewConsignmentQuotation from "./screens/order/consignment/NewConsignmentQuotation";
import EditConsignmentQuotation from "./screens/order/consignment/EditConsignmentQuotation";
import NewOder from "./screens/order/NewOder";
import ServiceOrderList from "./screens/order/service/ServiceOrderList";
import ServiceOrderDetails from "./screens/order/service/ServiceOrderDetails";
import NewServiceQuotation from "./screens/order/service/NewServiceQuotation";
import EditServiceQuotation from "./screens/order/service/EditServiceQuotation";
import CollectionList from "./screens/webContent/collections/CollectionList";
import NewCollection from "./screens/webContent/collections/NewCollection";
import EditCollection from "./screens/webContent/collections/EditCollection";
import ManageCollectionProducts from "./screens/webContent/collections/ManageCollectionProducts";
import WatchRequestOrderList from "./screens/order/watchRequest/WatchRequestOrderList";
import WatchRequestOrderDetails from "./screens/order/watchRequest/WatchRequestOrderDetails";
import SoldProductList from "./screens/product/SoldProductList";
import TaskList from "./screens/emailMarketing/TaskList";
import NewEmailTask from "./screens/emailMarketing/NewEmailTask";
import EditEmailTask from "./screens/emailMarketing/EditEmailTask";
import ManageTaskProducts from "./screens/emailMarketing/ManageTaskProducts";


function App() {
  const SidebarLayout = () => (
    <div>
      <Header />
      <div
        style={{
          display: "flex",
          height: "calc(100vh - 50px)",
          color: "var(--font-color)",
          fontSize: "var(--main-size)",
          position: "relative",
        }}
      >
        <SideMenu />
        <Outlet />
      </div>
    </div>
  );

  const routes = [
    { menuName: "dashboard", path: "/", component: <Dashboard /> },
    { menuName: "Blog list", path: "/blogs", component: <BlogList /> },
    { menuName: "New Blog", path: "/blog/new", component: <NewBlog /> },
    {
      menuName: "Edit Blog",
      path: "/blog/edit/:id",
      component: <BlogEdit />,
    },
    {
      menuName: "Preview Blog",
      path: "/blog/preview/:id",
      component: <BlogPreview />,
    },
    { menuName: "Brand List", path: "/watch/brands", component: <BrandList /> },
    { menuName: "Watch List", path: "/watches", component: <WatchList /> },
    { menuName: "New Watch", path: "/watch/new", component: <NewWatch /> },
    { menuName: "Edit Watch", path: "/watch/edit/:id", component: <EditWatch /> },
    { menuName: "New Brand", path: "/watch/brand/new", component: <NewBrand /> },
    { menuName: "Edit Brand", path: "/watch/brand/edit/:id", component: <EditBrand /> },
    { menuName: "Model List", path: "/watch/models", component: <ModelList /> },
    { menuName: "New Model", path: "/watch/model/new", component: <NewModel /> },
    // { menuName: "Edit Watch", path: "/watch/edit/:id", component: <EditProduct /> },

    { menuName: "Standard User List", path: "/users", component: <UserList userType={"Normal User"}/> },
    { menuName: "Private Seller List", path: "/users/private-seller", component: <UserList userType={"Verified Seller"}/> },
    { menuName: "Dealer List", path: "/users/dealer", component: <UserList userType={"Dealer"}/> },
    { menuName: "User Details", path: "/users/details/:id", component: <UserDetails /> },
    { menuName: "New User", path: "/users/new", component: <NewUser /> },
    { menuName: "Edit User", path: "/users/edit/:id", component: <EditUser /> },
    // products
    { menuName: "Product List", path: "/products", component: <ProductList/> },
    { menuName: "Product Details", path: "/products/details/:id", component: <ProductDetails /> },
    { menuName: "New Product", path: "/products/new", component: <NewProduct /> },
    { menuName: "New Product by Dealer", path: "/products/new/on-behalf-of-dealer", component: <NewProductByDealer /> },
    { menuName: "New Product by Platform", path: "/products/new/platform", component: <NewProductByPlatform /> },
    { menuName: "New Product by Consignment", path: "/products/new/consignment/:id", component: <NewProductByConsignment /> },
    { menuName: "Edit Product", path: "/products/seller/edit/:id", component: <EditProduct productType="SELLERPRODUCT" pricEditable={true}/> },
    { menuName: "Edit Product", path: "/products/platform/edit/:id", component: <EditProduct productType="PLATFORMPRODUCT" pricEditable={true}/> },
    { menuName: "Edit Product", path: "/products/consignment/edit/:id", component: <EditProduct productType="CONSIGNEDPRODUCT" pricEditable={false}/> },
    { menuName: "Sold Product List", path: "/products/sold", component: <SoldProductList/> },

    // orders
    { menuName: "Order List", path: "/orders", component: <OrderList /> },
    { menuName: "Order Details", path: "/orders/details/:id", component: <OrderDetails /> },
    { menuName: "Consignment Quote List", path: "/consignment/quote-list", component: <ConsignmentOrderList /> },
    { menuName: "Consignment Quote Details", path: "/consignment/details/:id", component: <ConsignmentOrderDetails /> },
    { menuName: "New Consignment Quotation", path: "/consignment/quotation/new", component: <NewConsignmentQuotation /> },
    { menuName: "Edit Consignment Quotation", path: "/consignment/quotation/edit/:id", component: <EditConsignmentQuotation /> },
    { menuName: "New Order", path: "/orders/new", component: <NewOder /> },
    { menuName: "Service Quote List", path: "/service/quote-list", component: <ServiceOrderList /> },
    { menuName: "Service Quote Details", path: "/service/order/details/:id", component: <ServiceOrderDetails /> },
    { menuName: "New Service Order", path: "/service/order/new", component: <NewServiceQuotation /> },
    { menuName: "Edit Service Order", path: "/service/order/edit/:id", component: <EditServiceQuotation /> },
    { menuName: "Watch Request List", path: "/watch-request/list", component: <WatchRequestOrderList /> },
    { menuName: "Watch Request Details", path: "/watch-request/details/:id", component: <WatchRequestOrderDetails /> },

     // messages
     { menuName: "Messages", path: "/messages", component: <DialogList/> },
     { menuName: "Dialog", path: "/messages/details/:id", component: <DialogDetails /> },
     // web content
     { menuName: "FAQ", path: "/web-content/faq", component: <FaqList /> },
     { menuName: "FAQ", path: "/web-content/faq/new", component: <NewQuestion /> },
     { menuName: "FAQ", path: "/web-content/faq/edit/:id", component: <EditQuestion /> },

      // web content -ads
      { menuName: "Ads", path: "/web-content/ads", component: <AdsList /> },
      { menuName: "Ads", path: "/web-content/ads/new", component: <NewAd /> },
      { menuName: "Ads", path: "/web-content/ads/edit/:id", component: <EditAd /> },
      // web content-collections
      { menuName: "Collections", path: "/web-content/collections", component: <CollectionList /> },
      { menuName: "Collections", path: "/web-content/collections/new", component: <NewCollection /> },
      { menuName: "Collections", path: "/web-content/collections/edit/:id", component: <EditCollection /> },
      { menuName: "Collections", path: "/web-content/collections/manage-products/:id", component: <ManageCollectionProducts /> },
  
      { menuName: "Web Images", path: "/web-content/images", component: <ImageList /> },
      { menuName: "Update Images", path: "/web-content/images/edit/:id", component: <ImageEdit /> },
      { menuName: "Web Agreements", path: "/web-content/agreements", component: <AgreementList /> },
      { menuName: "Update Agreements", path: "/web-content/agreements/edit/:id", component: <AgreementEdit /> },
      { menuName: "Edit Career", path: "/web-content/career/edit/:id", component: <CareerEdit /> },
      { menuName: "New Career", path: "/web-content/career/new", component: <NewCareer /> },
      { menuName: "Career List", path: "/web-content/career", component: <CareerList /> },
      { menuName: "Company Details", path: "/web-content/company-details", component: <CompanyInfoList /> },
      { menuName: "Update Company Detail", path: "/web-content/company-details/edit/:id", component: <CompanyInfoEdit /> },
      // email marketing
      { menuName: "Marketing Emails", path: "/marketing-email/tasks", component: <TaskList /> },
      { menuName: "New Email Task", path: "/marketing-email/tasks/new", component: <NewEmailTask /> },
      { menuName: "Edit Email Task", path: "/marketing-email/tasks/edit/:id", component: <EditEmailTask /> },
      { menuName: "Manage Email Task Products", path: "/marketing-email/tasks/manage-products/:id", component: <ManageTaskProducts /> },
  ];

  return (
    <BrowserRouter>
      <ErrorBoundary>
        <Routes>
          <Route element={<SidebarLayout />}>
            {routes.map((route, index) => (
              <Route
                key={index}
                exact
                path={route.path}
                element={
                  <RequireAuth permisson={true}>{route.component}</RequireAuth>
                }
              />
            ))}
          </Route>
          {/* <Route exact path="/billing" element={<BillingScreen />} /> */}
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/no_permission" element={<NoAuthority />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </ErrorBoundary>
    </BrowserRouter>
  );
}

export default App;
