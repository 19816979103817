"use client";
import { consignmentQuoteDetailsApiRequest } from "../../../api/consignmentRequests";
import { getAllBrands, getModelsByBrand } from "../../../api/watchRequests";
import { Button } from "../../../components/buttons";
import {
  H1,
  ErrorMessage,
  Loader,
  H2,
  H3,
} from "../../../components/smallComponents";
import {
  FieldLabel,
  PureTextField,
  RatioField,
  SelectField,
  TextAreaField,
  TextField,
} from "../../../components/inputs";
import { K_BUY_METHODS } from "../../../constantData/K_BUY_METHODS";
import { K_MONTHS } from "../../../constantData/K_MONTHS";
import { getYears } from "../../../constantData/K_YEARS";
import { watchConstantOptionsRequest } from "../../../redux/actions/watchActions";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SearchWatch from "../../product/SearchWatch";
import ImagesUploader from "../../product/ImagesUploader";
import SearchConsignmentWatch from "../consignment/SearchConsignmentWatch";
import { K_SERVICE_TYPES } from "../../../constantData/K_SERVICE_TYPES";
import { K_COUNTRIES } from "../../../constantData/K_COUNTRIES";
import { serviceQuoteDetailsRequest } from "../../../redux/actions/orderActions";
import { serviceQuoteDetailsApiRequest } from "../../../api/serviceRequests";
import { convtPrice } from "../../../api/functions";
import AddressCollector from "../../../components/AddressCollector";

const NEXT_PUBLIC_S3_DOMAIN = process.env.NEXT_PUBLIC_S3_DOMAIN;

export default function ServiceQuotationForm({ type, orderId, onSave }) {


  const { userInfo } = useSelector((state) => state.userLogin);
  const {
    loading: userDetailsLoading,
    details,
    success: userDetailsSuccess,
    error: userDetailsError,
  } = useSelector((state) => state.userDetails);

  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo ? userInfo.data.token : ""}`,
    },
  };

  const [reqStatus, setReqStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const [brandReqStatus, setBrandReqStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const [modelReqStatus, setModelReqStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const [saveStatus, setSaveStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const [serviceFee, setServiceFee] = useState("");
  const [otherServices, setOtherServices] = useState({
    polishing: "",
    replaceStrap: "",
    reshaping: ""
  });

  const [modelInfoManually, setModelInfoManually] = useState(false);
  const [requestBrands, setRequestBrands] = useState(false);
  const [brandOptions, setBrandOptions] = useState([]);
  const [modelOptions, setModelOptions] = useState([]);

  const [formState, setFormState] = useState({
    watchModel: "",
    brandId: "",
    modelId: "",
    referenceNumber: "",
    year: null,
    serialNumber: "",
    services: [],
    servicesOther: "",
    notes: "",
    imageUris: [],
  });

  const [formErrors, setFormErrors] = useState({});

  const [shippingAddress, setShippingAddress] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    streetLine1: "",
    city: "",
    region: "",
    country: "",
    postalCode: "",
  });
  const [shippingAddressErrors, setShippingAddressErrors] = useState({});

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (userDetailsSuccess && type === "new") {
      let contactDetails = {
        firstName: details.firstName,
        lastName: details.lastName,
        phone: details.phone,
        streetLine1: details.streetLine1,
        region: details.region,
        country: K_COUNTRIES.filter((x) => x.value === details.country)[0],
        city: details.city,
        postalCode: details.postalCode,
      };
      setShippingAddress(contactDetails);
    }
  }, [userDetailsSuccess, type]);

  useEffect(() => {
    const getOrderDetails = async () => {
      setReqStatus({ ...reqStatus, loading: true });
      try {
        const responseData = await serviceQuoteDetailsApiRequest(
          orderId,
          config
        );
        if (responseData.data.success) {
          let initialData = responseData.data.data;
          let images = [];

          if (initialData.images.length) {
            initialData.images.forEach((x) => {
              const uri = x;
              const fileNameParts = x.split("/");
              const fileName = fileNameParts[fileNameParts.length - 1];

              images.push({
                fileName: fileName,
                isLoading: false,
                error: "",
                uri: uri,
              });
            });
          }

          setModelInfoManually(true);
          let populatedvalues = {
            watchModel: "",
            brandId: initialData.brandId,
            modelId: initialData.modelId,
            referenceNumber: initialData.referenceNumber,
            year: getYears().find((year) => year.value === initialData.year),
            serialNumber: initialData.serialNumber || "",
            services: initialData.services,
            servicesOther: initialData.otherServiceNotes || "",
            imageUris: images,
            notes: initialData.notes || "",
          };
          setFormState(populatedvalues);
          if(initialData.contactInfo){
            setShippingAddress(initialData.contactInfo)
          }
          setReqStatus({ ...reqStatus, loading: false, success: true });
        } else {
          if (typeof responseData.data.message === "string") {
            throw responseData.data.message;
          } else {
            throw "Error";
          }
        }
      } catch (error) {
        setReqStatus({ ...reqStatus, loading: false, error: error });
      }
    };

    if (type === "edit") {
      getOrderDetails();
    } else {
      setReqStatus({ ...reqStatus, loading: false, success: true, error: "" });
    }
  }, [type]);

  useEffect(() => {
    const getBrandOptions = async () => {
      try {
        setBrandReqStatus((preState) => ({
          ...preState,
          loading: true,
        }));
        const responseData = await getAllBrands(config);
        if (responseData.data.success) {
          setBrandOptions(responseData.data.data);
          setRequestBrands(true);
          setBrandReqStatus((preState) => ({
            ...preState,
            loading: false,
            success: true,
          }));
        } else {
          throw responseData.data.message;
        }
      } catch (error) {
        setBrandReqStatus((preState) => ({
          ...preState,
          loading: false,
          error: error,
        }));
      }
    };
    if (modelInfoManually && !requestBrands) {
      getBrandOptions();
    }
  }, [requestBrands, modelInfoManually]);

  useEffect(() => {
    const getModelOptions = async () => {
      try {
        setModelReqStatus((preState) => ({
          ...preState,
          loading: true,
        }));

        const responseData = await getModelsByBrand(formState.brandId, config);
        if (responseData.data.success) {
          let options = [];
          let models = responseData.data.data;
          models.forEach((ele) => {
            options.push({
              id: ele.id,
              label: ele.name,
              value: ele.name,
            });
          });
          setModelOptions(options);
          setModelReqStatus((preState) => ({
            ...preState,
            loading: false,
            success: true,
          }));
        } else {
          throw responseData.data.message;
        }
      } catch (error) {
        setModelReqStatus((preState) => ({
          ...preState,
          loading: false,
          error: error,
        }));
      }
    };

    if (modelInfoManually && formState.brandId) {
      getModelOptions();
    }
  }, [formState.brandId, modelInfoManually]);

  const getSelectWatch = (watch) => {
    setFormState({ ...formState, ["watchModel"]: watch });
  };

  const handleManuallyClick = async (value) => {
    setModelInfoManually(value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const type = e.type;

    if (type === "FLOAT") {
      const validated = value.match(/^(\d*\.{0,1}\d{0,2}$)/);
      if (!validated) {
        return;
      }
    }
    if (type === "INT") {
      const validated = value.match(/^(?:\d+)?$/g);
      if (!validated) {
        return;
      }
    }

    setFormState({ ...formState, [name]: value });
  };

  const handleOtherServiceChange = (e) => {
    const { value, name } = e.target;
    const validated = value.match(/^(\d*\.{0,1}\d{0,2}$)/);
    if (!validated) {
      return;
    }
    setOtherServices({ ...otherServices, [name]: value });
  }

  const handlePriceChange = (e) => {
    const { name, value } = e.target;
    const validated = value.match(/^(\d*\.{0,1}\d{0,2}$)/);
    if (!validated) {
      return;
    }

    if (name === "serviceFee") {
      setServiceFee(value);
    }
  };

  const handleServiceChange = (e) => {
    const { name, value } = e.target;
    let copy = { ...formState };
    let serviceCopy = copy.services;

    let existedIndex = serviceCopy.indexOf(name);
    if (existedIndex > -1) {
      if (!value) {
        serviceCopy.splice(existedIndex, 1);
      }
    } else {
      if (value) {
        serviceCopy.push(name);
      }
    }
    setFormState(copy);
  };

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
  const getWatchImages = (files) => {
    setFormState({
      ...formState,
      ["imageUris"]: files,
    });
  };

  const handleSubmit = () => {
    const hasErrors = validate(formState);

    if (Object.keys(hasErrors).length === 0) {
      setFormErrors({});
      let formStateCopy = { ...formState };

      let selectedBrandId = modelInfoManually
        ? formStateCopy.brandId
        : formStateCopy.watchModel.brand_id
        ? formStateCopy.watchModel.brand_id
        : "";
      let selectedModelId = modelInfoManually
        ? formStateCopy.modelId
        : formStateCopy.watchModel.model_id
        ? formStateCopy.watchModel.model_id
        : "";
      let selectedRF = modelInfoManually
        ? formStateCopy.referenceNumber
        : formStateCopy.watchModel.referenceNumber
        ? formStateCopy.watchModel.referenceNumber
        : "";

      let uris = [];
      formStateCopy.imageUris.forEach((ele) => {
        if (ele.uri) {
          uris.push(ele.uri);
        }
      });

      let shippingAddressCopy = { ...shippingAddress };

      if (typeof shippingAddressCopy.country === "object") {
        shippingAddressCopy.countryName = shippingAddressCopy.country.value;
      }


      let quotationValues = {
        brandId: selectedBrandId,
        modelId: selectedModelId,
        referenceNumber: selectedRF,
        year: formStateCopy.year ? formStateCopy.year.value : "",
        services: formStateCopy.services,
        servicesOther: formStateCopy.servicesOther,
        serialNumber: formStateCopy.serialNumber,
        notes: formStateCopy.notes,
        contactInfo: JSON.stringify(shippingAddressCopy),
        imageUris: uris,
        serviceFee: serviceFee,
        addServicesOffer: otherServices,
      };

      onSave(quotationValues);
    } else {
      setFormErrors(hasErrors);
    }
  };

  const validate = (values) => {
    const errors = {};
    if (values.imageUris && values.imageUris.length < 1) {
      errors.watchImages = "Please upload at least one photo of your watch.";
    }

    if (!modelInfoManually && !values.watchModel) {
      errors.watchModel = "Please select your watch model.";
    }

    if (modelInfoManually && !values.brandId) {
      errors.brand = "Please select a brand.";
    }

    if (modelInfoManually && !values.modelId) {
      errors.model = "Please select a model.";
    }

    if (modelInfoManually && !values.referenceNumber) {
      errors.referenceNumber = "Please enter your watch reference number.";
    }

    if (!values.year) {
      errors.year = "Please enter your watch year.";
    }

    return errors;
  };

  ///////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////

  return (
    <div className="w-full pb-20 pt-4">
      {/* {saveStatus.success && (
        <SuccessModal
          closeUri={`/user/consignment/details/${type === "new" ? createdOrderId : orderId}`}
          successText={`Your quote has been ${type === "new" ? "created" : "updated"} successfully.`}
        />
      )} */}

      {reqStatus.loading ? (
        <Loader mess="Request details..." />
      ) : reqStatus.success ? (
        <div className="w-full flex items-start flex-row">
          <div className="w-full lg:w-3/5">
            <div className="w-full mt-6">
              <H3 title={"1. Watch Details"} />
              <FieldLabel label={"Watch Images"} />
              <ImagesUploader
                initialImages={formState.imageUris}
                numLimit={20}
                onWatchUpload={getWatchImages}
                tipsText={
                  "Upload at least 1 photo showing the front face of the watch for accurate suggested listing price."
                }
              />

              {formErrors.watchImages && (
                <p className="w-full mt-2 text-red-500">
                  {formErrors.watchImages}
                </p>
              )}

              {type === "new" && (
                <>
                  <FieldLabel label={"Watch Model*"} />
                  <SearchConsignmentWatch
                    onSelect={getSelectWatch}
                    readOnly={modelInfoManually}
                  />
                  {formErrors.watchModel && (
                    <p className="w-full mt-2 text-red-500">
                      {formErrors.watchModel}
                    </p>
                  )}

                  <RatioField
                    name={"manuallyModelInfo"}
                    label={"Can not find my watch? enter watch model manually"}
                    isOn={modelInfoManually}
                    onChange={(value) => handleManuallyClick(value)}
                  />
                </>
              )}

              {modelInfoManually && (
                <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-x-6">
                  <SelectField
                    label="Brand*"
                    placeHolder="Select brand"
                    selectOptions={brandOptions}
                    value={formState.brandId}
                    extractKey="id"
                    onChange={(brandId) =>
                      handleChange({
                        type: "ID",
                        target: { value: brandId, name: "brandId" },
                      })
                    }
                    error={formErrors.brand}
                  />
                  <SelectField
                    label="Model*"
                    placeHolder="Select model"
                    selectOptions={modelOptions}
                    value={formState.modelId}
                    extractKey="id"
                    onChange={(modelId) =>
                      handleChange({
                        type: "ID",
                        target: { value: modelId, name: "modelId" },
                      })
                    }
                    error={formErrors.model}
                  />

                  <TextField
                    type="text"
                    label="Reference Number*"
                    name="referenceNumber"
                    autofocus={true}
                    value={formState.referenceNumber}
                    onChange={(referenceNumber) =>
                      handleChange({
                        type: "TEXT",
                        target: {
                          value: referenceNumber,
                          name: "referenceNumber",
                        },
                      })
                    }
                    error={formErrors.referenceNumber}
                  />
                </div>
              )}

              <div className="w-full">
                <SelectField
                  label="Year of Product*"
                  placeHolder="Select year"
                  selectOptions={getYears()}
                  value={formState.year}
                  onChange={(year) =>
                    handleChange({
                      type: "OBJECT",
                      target: { value: year, name: "year" },
                    })
                  }
                  error={formErrors.year}
                />
                <TextField
                  type="text"
                  label="Serial No."
                  name="serialNumber"
                  autofocus={true}
                  value={formState.serialNumber}
                  onChange={(serialNumber) =>
                    handleChange({
                      type: "TEXT",
                      target: { value: serialNumber, name: "serialNumber" },
                    })
                  }
                  error={formErrors.serialNumber}
                />

                <div className="w-full ">
                  <FieldLabel
                    label={"Which services are you interested in?*"}
                  />
                  <p className="text-gray text-sm mt-2">
                    Choose as many as you like
                  </p>
                  {K_SERVICE_TYPES.map((item, index) => (
                    <RatioField
                      key={index}
                      name={item.value}
                      label={item.value}
                      isOn={formState.services.includes(item.value)}
                      onChange={(services) =>
                        handleServiceChange({
                          type: "BOOL",
                          target: {
                            value: services,
                            name: item.value,
                          },
                        })
                      }
                    />
                  ))}
                </div>

                {formState.services.includes("Other") && (
                  <div className="mt-2">
                    <PureTextField
                      type="text"
                      placeholder={"other service description"}
                      name="servicesOther"
                      autofocus={true}
                      value={formState.servicesOther}
                      onChange={(servicesOther) =>
                        handleChange({
                          type: "TEXT",
                          target: {
                            value: servicesOther,
                            name: "servicesOther",
                          },
                        })
                      }
                      hasBorder={true}
                      error={formErrors.servicesOther}
                    />
                  </div>
                )}

                {formErrors.services && (
                  <p className="w-full mt-2 text-red-500">
                    {formErrors.services}
                  </p>
                )}

                <TextAreaField
                  type="text"
                  label="Any other details you'd like to share about your watch?"
                  name="notes"
                  value={formState.notes}
                  rows={2}
                  placeholder=""
                  onChange={(notes) =>
                    handleChange({
                      type: "TEXT",
                      target: { value: notes, name: "notes" },
                    })
                  }
                />
              </div>

              <div className="mt-6">
                <H3 title={"2. Contact Details"} />
                <AddressCollector
                  formState={shippingAddress}
                  onChange={(address) => setShippingAddress(address)}
                  errors={shippingAddressErrors}
                  required={false}
                />
              </div>

              {type === "edit" && (
                <div className="mt-6 w-full">
                  <Button name={`Update`} action={handleSubmit} />
                </div>
              )}
            </div>
          </div>
          {type === "new" && (
            <div className="sticky top-32 w-2/5 ml-16 ">
              <H3 title={"Order Summary"} />
              <div className="w-full mt-6 bg-lightGray rounded p-4">
                <div className="w-full bg-lightGray rounded p-4 mt-4">
                  <div className="flex py-3 justify-between items-center">
                    <p className="w-[120px]">Service Fee</p>
                    <PureTextField
                      type="text"
                      name="keyword"
                      placeholder="Service fee (if available)"
                      autofocus={true}
                      value={serviceFee}
                      hasBorder={true}
                      showSearchIcon={false}
                      dollarIcon={true}
                      onChange={(serviceFee) =>
                        handlePriceChange({
                          target: { value: serviceFee, name: "serviceFee" },
                        })
                      }
                    />
                  </div>

                  <div className="mt-4">
                    <p>Other Services (offer price)</p>

                  <div className="flex py-3 justify-between items-center">
                    <p className="w-[120px]">Polishing</p>
                    <PureTextField
                      type="text"
                      name="polishing"
                      placeholder=""
                      autofocus={false}
                      value={otherServices.polishing}
                      hasBorder={true}
                      showSearchIcon={false}
                      dollarIcon={true}
                      onChange={(polishing) =>
                        handleOtherServiceChange({
                          target: { value: polishing, name: "polishing" },
                        })
                      }
                    />
                  </div>

                  <div className="flex py-3 justify-between items-center">
                    <p className="w-[120px]">Replace Strap</p>
                    <PureTextField
                      type="text"
                      name="replaceStrap"
                      placeholder=""
                      autofocus={false}
                      value={otherServices.replaceStrap}
                      hasBorder={true}
                      showSearchIcon={false}
                      dollarIcon={true}
                      onChange={(replaceStrap) =>
                        handleOtherServiceChange({
                          target: { value: replaceStrap, name: "replaceStrap" },
                        })
                      }
                    />
                  </div>

                  <div className="flex py-3 justify-between items-center">
                    <p className="w-[120px]">Reshaping</p>
                    <PureTextField
                      type="text"
                      name="reshaping"
                      placeholder=""
                      autofocus={false}
                      value={otherServices.reshaping}
                      hasBorder={true}
                      showSearchIcon={false}
                      dollarIcon={true}
                      onChange={(reshaping) =>
                        handleOtherServiceChange({
                          target: { value: reshaping, name: "reshaping" },
                        })
                      }
                    />
                  </div>
                  </div>

                  <div className="flex border-t border-solid border-borderGray py-4 font-bold text-xl justify-between items-center">
                    <span>Total</span>
                    <span>${convtPrice(Number(serviceFee) + Number(otherServices.polishing) + Number(otherServices.replaceStrap) + Number(otherServices.reshaping))}</span>
                  </div>
                </div>
              </div>

              <div className="mt-6 w-full">
                <Button
                  name={`${type === "new" ? "Submit" : "Update"}`}
                  action={handleSubmit}
                />
              </div>
            </div>
          )}
        </div>
      ) : reqStatus.error ? (
        <ErrorMessage mess={reqStatus.error} />
      ) : (
        <></>
      )}
    </div>
  );
}
