
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export default function UnreadCount({dialogId}) {
  const [dialogUnreads, setDialogUnreads] = useState({});
  const {
    loading: unreadLoading,
    unreads,
    succss: unreadSuccess,
    error: unreadError,
  } = useSelector((state) => state.unreadMessages);

  useEffect(() => {
    if (unreads && unreads.length) {
      let new_unreads = {};
      unreads.forEach((element) => {
        new_unreads[element.dialog_id] = element.unreads;
      });
      setDialogUnreads(new_unreads);
    }else{
      setDialogUnreads({});
    }
  }, [unreads]);

  return dialogUnreads[dialogId] ? (
    <span className="absolute flex items-center justify-center w-5 h-5 text-center bg-red-500 rounded-full text-white top-2 left-2 text-xs">{dialogUnreads[dialogId]}</span>
  ) : (
    <span className="absolute flex items-center justify-center w-5 h-5 text-center bg-transparent rounded-full text-white -top-1 -left-4 text-xs"></span>
  );
}
