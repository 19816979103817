import Breadcrumbs from "@mui/material/Breadcrumbs";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { addNewCollection } from "../../../api/webContentRequets";
import ScreenTitle from "../../../components/ScreenTitle";
import { SimpleDialog } from "../../../components/dialogs";
import commonStyle from "../../../style/commonStyle.module.css";
import useDocumentTitle from "../../../useDocumentTitle";
import CollectionForm from "./CollectionForm";


export default function NewCollection() {
  useDocumentTitle("New Collection");
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo.data.token}`,
     
    },
  };

  const [saveStatus, setSaveStatus] = useState({
    loading: false,
    success: false,
    error: ""
  })

  const initialvalues = {
    position: "",
    image: "",
    name: "",
    description: "",
    pageTitle: "",
    pageDescription: "",
    metaTitle: "",
    metaDescription: "",
    imageAlt: "",
  };

    //  dialog
    const handleSave = async (values) => {

      try {
        setDialog(true);
        setSaveStatus({...saveStatus, loading: true})
        const responseData = await addNewCollection(values, config, dispatch);
        if (responseData.data.success) {
          setSaveStatus({...saveStatus, loading: false, success: true})
        
        } else {
          throw responseData.data.message;
        }
      } catch (error) {
        setSaveStatus({...saveStatus, loading: false, error: error})
      }
    };
    const [dialog, setDialog] = useState(false);

    const closeDialogWithSuccess = () => {
      setDialog(false);
      navigate("/web-content/collections", { replace: true, from: location });
    };

  return (
    <div className={commonStyle.pageContainer}>
      <SimpleDialog
        title="Add New Collection"
        successMessage="New collection added successfully."
        isDialogOpen={dialog}
        closeDialog={() => setDialog(false)}
        loading={saveStatus.loading}
        loadingMessage="Processing request..."
        success={saveStatus.success}
        error={saveStatus.error}
        confirmAction={closeDialogWithSuccess}
      />

      <div className="w-full p-8">
        <Breadcrumbs aria-label="breadcrumb">
          <NavLink underline="hover" color="inherit" to="/">
            Home
          </NavLink>
          <NavLink underline="hover" color="inherit" to="/web-content/collections">
            Collections
          </NavLink>
          <NavLink
            underline="hover"
            color="text.primary"
            to={`/web-content/collections/new`}
            aria-current="page"
          >
            New Collection
          </NavLink>
        </Breadcrumbs>
        <ScreenTitle title="Add New Collection" />

        <CollectionForm
          type="new"
          onSave={handleSave}
          initialValues={initialvalues}
        />
      </div>
    </div>
  );
}
