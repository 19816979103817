// get package list
import axios from "axios";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export const getAllBrands = async (config) => {
  try {
    const response = await axios.get(
      SERVER_URL + `/watch/brand/options`,
      config
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getModelsByBrand = async (brandId, config) => {
  try {
    const response = await axios.get(
      SERVER_URL + `/watch/model/list_by_brand?brandId=${brandId}`,
      config
    );
    return response;
  } catch (error) {
    throw error;
  }
};