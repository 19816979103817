"use client";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { convtPrice } from "../../api/functions";
import AddressCollector from "../../components/AddressCollector";
import { Button } from "../../components/buttons";
import {
  PureTextField,
  RatioField,
  TextAreaField
} from "../../components/inputs";
import { ProductTypeTag } from "../../components/productComponents";
import {
  H3
} from "../../components/smallComponents";
import { K_COUNTRIES } from "../../constantData/K_COUNTRIES";
import SearchProduct from "./SearchProduct";

const S3_DOMAIN = process.env.REACT_APP_S3_DOMAIN;

export default function OrderForm({ onSave }) {

  const {
    loading: userDetailsLoading,
    details,
    success: userDetailsSuccess,
    error: userDetailsError,
  } = useSelector((state) => state.userDetails);


  const [watchPrice, setWatchPrice] = useState("");
  const [shippingFee, setShippingFee] = useState(0);

  const [productInfo, setProductInfo] = useState("");
  const [productInfoError, setProductInfoError] = useState("");

  const [shippingAddress, setShippingAddress] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    streetLine1: "",
    city: "",
    region: "",
    country: "",
    postalCode: "",
  });
  const [billingAddress, setBillingAddress] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    streetLine1: "",
    city: "",
    region: "",
    country: "",
    postalCode: "",
  });
  const [sameAddress, setSameAddress] = useState(true);
  const [shippingAddressErrors, setShippingAddressErrors] = useState({});
  const [billingAddressErrors, setBillingAddressErrors] = useState({});

  const [adminNotes, setAdminNotes] = useState("");

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (userDetailsSuccess) {
      let contactDetails = {
        firstName: details.firstName,
        lastName: details.lastName,
        phone: details.phone,
        streetLine1: details.streetLine1,
        region: details.region,
        country: K_COUNTRIES.filter((x) => x.value === details.country)[0],
        city: details.city,
        postalCode: details.postalCode,
      };
      setShippingAddress(contactDetails);
    } else {
      setShippingAddress({
        firstName: "",
        lastName: "",
        phone: "",
        streetLine1: "",
        city: "",
        region: "",
        country: "",
        postalCode: "",
      });
    }
  }, [userDetailsSuccess]);


  const getSelectWatch = (watch) => {
    setProductInfo(watch);
    setWatchPrice(watch.price);
  };

  const handlePriceChange = (e) => {
    const { name, value } = e.target;
    const validated = value.match(/^(\d*\.{0,1}\d{0,2}$)/);
    if (!validated) {
      return;
    }

    if (name === "watchPrice") {
      setWatchPrice(value);
    } else if (name === "shippingFee") {
      setShippingFee(value);
    }
  };

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  const handleSubmit = () => {
    if (!productInfo) {
      setProductInfoError("Please add a product to this order.");
      return;
    } else {
      setProductInfoError("");
    }
      let shippingAddressCopy = { ...shippingAddress };
      let billingAddressCopy = sameAddress
        ? shippingAddressCopy
        : { ...billingAddress };

      if (typeof shippingAddressCopy.country === "object") {
        shippingAddressCopy.countryName = shippingAddressCopy.country.value;
      }
      if (typeof billingAddressCopy.country === "object") {
        billingAddressCopy.countryName = billingAddressCopy.country.value;
      }

      let orderValues = {
        productId: productInfo.id,
        watchPrice: watchPrice,
        shippingFee: shippingFee,
        serviceFee: 0,
        shippingAddress: JSON.stringify(shippingAddressCopy),
        billingAddress: JSON.stringify(billingAddressCopy),
        adminNotes: adminNotes
      };

      onSave(orderValues);
  };


  ///////////////////////////////////////////////////////////////
  const ViewItem = ({ label, value }) => {
    return (
      <div className="flex flex-col mr-6 text-left">
        <span className="text-gray">{label}</span>
        <span className="mt-1">{value}</span>
      </div>
    );
  };

  return (
    <div className="w-full pb-20 pt-4">
        <div className="w-full flex items-start flex-row">
          <div className="w-full lg:w-3/5">
            <div className="w-full mt-6">
             
                <div>
                  <H3 title={"Order Product"} />
                  <SearchProduct onSelect={getSelectWatch} readOnly={false} productType={"platformProducts"}/>
                  {productInfoError && <p className="text-red-500 mt-2">{productInfoError}</p>}
                  {productInfo && (
                    <div className="mt-6 p-4 rounded bg-lightGray flex flex-row justify-between">
                      <div className="flex flex-row">
                        <img
                          src={
                            productInfo.imageUris &&
                            productInfo.imageUris.length
                              ? S3_DOMAIN + productInfo.imageUris[0]
                              : "/default-watch.svg"
                          }
                          alt={"watch image " + productInfo.watchImageUri}
                          className="w-24 h-24 mr-4 rounded object-contain"
                          style={{ maxWidth: "100%", maxHeight: "100%" }}
                        ></img>

                        <div className="flex flex-col item-start">
                          <ProductTypeTag type={productInfo.type} />
                          <div className="text-left mt-2">
                            <span className="text-lg font-medium mr-2">
                              {productInfo.brandName}
                            </span>
                            <span>{productInfo.modelName}</span>
                          </div>
                          <p className="text-left mt-1">
                            {productInfo.referenceNumber}
                          </p>
                          <div className="w-full mt-4 flex">
                            {productInfo.dialColorName && (
                              <ViewItem
                                label="Dial Color"
                                value={productInfo.dialColorName}
                              />
                            )}
                            {productInfo.caseDiameterWidth && (
                              <ViewItem
                                label="Case Diameter"
                                value={productInfo.caseDiameterWidth}
                              />
                            )}
                            {productInfo.caseMaterial && (
                              <ViewItem
                                label="Case Material"
                                value={productInfo.caseMaterial}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      <div>
                        <p className="text-xl font-semibold">
                          ${convtPrice(productInfo.price)}
                        </p>
                      </div>
                    </div>
                  )}
                </div>

              <div className="mt-8 w-full">
                <H3 title={"Shipping Address"} />
                <AddressCollector
                  formState={shippingAddress}
                  onChange={(address) => setShippingAddress(address)}
                  errors={shippingAddressErrors}
                />
              </div>

              <div className="mt-8 w-full">
                <H3 title={"Billing Address"} />
                <RatioField
                  name={"same address"}
                  label={"Billing address is the same with shipping address"}
                  isOn={sameAddress}
                  onChange={(selected) => setSameAddress(selected)}
                />

                {!sameAddress && (
                  <AddressCollector
                    formState={billingAddress}
                    onChange={(address) => setBillingAddress(address)}
                    errors={billingAddressErrors}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="w-2/5 sticky top-32 ml-8 ">
          <H3 title={"Order Summary"} />
            <div className="w-full mt-6 bg-lightGray rounded p-4">
 
              <div className="w-full bg-lightGray rounded p-4 mt-4">
                <div className="flex py-3 justify-between items-center">
                  <p className="w-[120px]">Watch Price</p>
                  <PureTextField
                      type="text"
                      name="keyword"
                      placeholder="Price"
                      autofocus={true}
                      value={watchPrice}
                      hasBorder={true}
                      showSearchIcon={false}
                      dollarIcon={true}
                      onChange={(watchPrice) =>
                        handlePriceChange({
                          target: { value: watchPrice, name: "watchPrice" },
                        })
                      }
                    />
                </div>
                <div className="flex py-3 justify-between items-center">
                  <p className="w-[120px]">Shipping Fee</p>
                  <PureTextField
                      type="text"
                      name="keyword"
                      placeholder="Watch Price"
                      autofocus={true}
                      value={shippingFee}
                      hasBorder={true}
                      showSearchIcon={false}
                      dollarIcon={true}
                      onChange={(shippingFee) =>
                        handlePriceChange({
                          target: { value: shippingFee, name: "shippingFee" },
                        })
                      }
                    />
                </div>

                <div className="flex border-t border-solid border-borderGray py-4 font-bold text-xl justify-between items-center">
                  <span>Total</span>
                  <span>
                    ${convtPrice(Number(watchPrice)  + Number(shippingFee))}
                  </span>
                </div>
              </div>
            </div>
            <TextAreaField
              type="text"
              label="Notes (Printed on order PDF)"
              name="adminNotes"
              value={adminNotes}
              rows={6}
              placeholder="order notes..."
              onChange={(adminNotes) =>
                setAdminNotes(adminNotes)
              }
            />

            <div className="mt-6 w-full">
              <Button
                name={"Place Order"}
                action={handleSubmit}
              />
            </div>
          </div>
        </div>
    </div>
  );
}
