import Breadcrumbs from "@mui/material/Breadcrumbs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { convToLocalDate } from "../../../api/functions";
import Pagination from "../../../components/Pagination";
import ScreenTitle from "../../../components/ScreenTitle";
import { LinkButton, TextLinkButton } from "../../../components/buttons";
import { SearchField } from "../../../components/inputs";
import {
  ErrorMessage,
  Loader,
  OrderStatusTag,
  OrderTypeTag,
  ProductName,
} from "../../../components/smallComponents";
import {
  consignmentQuoteListRequest,
  orderListRequest,
} from "../../../redux/actions/orderActions";
import commonStyle from "../../../style/commonStyle.module.css";
import useDocumentTitle from "../../../useDocumentTitle";

export default function ConsignmentOrderList() {
  useDocumentTitle("Consignment");
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.userLogin);

  const { loading, orders, success, error } = useSelector(
    (state) => state.consignmentList
  );

  const pageSize = 20;
  const [pageNum, setPageNum] = useState(0);
  const [keyword, setKeyword] = useState("");

  const onPageChangeRequest = (page) => {
    setPageNum(page.selected);
  };

  useEffect(() => {
    dispatch(consignmentQuoteListRequest(keyword, pageNum, pageSize));
  }, [dispatch, pageNum, keyword]);

  const handleSearchChange = (value) => {
    setKeyword(value);
    setPageNum(0);
  };

  return (
    <div className={commonStyle.pageContainer}>
      <div className="w-full p-8">
        <Breadcrumbs aria-label="breadcrumb">
          <NavLink underline="hover" color="inherit" to="/">
            Home
          </NavLink>
          <NavLink
            underline="hover"
            color="inherit"
            to="/consignment/quote-list"
          >
            Consignment
          </NavLink>
        </Breadcrumbs>
        <ScreenTitle title="Consignment" />
        <div className="flex flex-row justify-between mt-8">
          <div className="w-1/2">
            <SearchField
              placeholder="Type order number to search..."
              initialQuery={keyword}
              onChange={handleSearchChange}
            />
          </div>
          <div>
            <LinkButton
              name={"Add New Consignment Quote"}
              path={`/consignment/quotation/new`}
              width="fit"
            />{" "}
          </div>
        </div>

        {loading ? (
          <Loader mess="Requesting consignment quotes..." />
        ) : success ? (
          <div className={commonStyle.longDataTable}>
            <table className={commonStyle.pageTable}>
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Watch</th>
                  <th>Date</th>
                  <th>Quote Number</th>
                  <th>User Name</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {orders.orders.length > 0 ? (
                  orders.orders.map((item, index) => (
                    <tr key={index}>
                      <td><OrderTypeTag isOnline={item.adminId > 0 ? false : true} /></td>
                      <td>
                        <ProductName
                          image={item.images.length ? item.images[0] : ""}
                          brandName={item.brandName}
                          modelName={item.modelName}
                          referenceNumber={item.referenceNumber}
                        />
                      </td>
                      <td>{convToLocalDate(item.atime)}</td>

                      <td>{item.orderNumber}</td>
                      <td>
                        <p>{item.userName}</p>
                      </td>

                      <td>
                        <OrderStatusTag currState={item.currStateDesc} />
                      </td>
                      <td>
                        <TextLinkButton
                          name="View Details"
                          path={`/consignment/details/${item.id}`}
                        />
                        {item.currState !== "COMPLETED" &&
                        item.adminId === userInfo.data.user_id ? (
                          <div className="w-full mt-4">
                            <TextLinkButton
                              name="Update"
                              path={`/consignment/quotation/edit/${item.id}`}
                              data={{ userId: item.userId }}
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>There are no order available.</td>
                  </tr>
                )}
              </tbody>
            </table>
            {orders.orders.length > 0 ? (
              <Pagination
                totalPageNum={orders.totalPages}
                forcePage={pageNum}
                onPageChange={onPageChangeRequest}
              />
            ) : (
              <></>
            )}
          </div>
        ) : error ? (
          <ErrorMessage mess={error} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
