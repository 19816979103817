import { TailSpin } from "react-loader-spinner";
import { NavLink } from "react-router-dom";
import { convtPrice } from "../api/functions";
import { AiOutlineQuestionCircle, AiOutlineArrowLeft} from "react-icons/ai"

const S3_DOMAIN = process.env.REACT_APP_S3_DOMAIN;

function ErrorMessage({ mess }) {
  return (
    <div className="min-h-12 bg-red-100  text-red-500 flex items-center border border-red-500 rounded px-3 py-3 w-full mt-4 mb-4">
      {mess !== null && typeof mess === "object" ? mess.message : mess}
    </div>
  );
}

function SuccessMessage({ mess }) {
  return (
    <div className="min-h-12 bg-green-100  text-green-600 flex items-center border border-green-400 rounded px-3 py-3 w-full ">
      {mess}
    </div>
  );
}

function Loader({ mess }) {
  return (
    <div className="px-3 py-3 flex items-center">
      <TailSpin color="black" height={20} width={20} />
      <p className="text-black ml-2">{mess}</p>
    </div>
  );
}

function UserAvatar({ name }) {
  const displayName = name ? name.charAt(0).toUpperCase() : "W";
  const a = ["A", "C", "E", "G", "I", "K", "M", "O", "Q", "S", "U", "W", "Y"];
  const b = ["B", "D", "F", "H", "J", "L", "N", "P", "R", "T", "V", "X", "Z"];
  return (
    <div
      className={`rounded-full ${
        a.includes(displayName) ? "bg-brandYellow" : "bg-brandYellowHover"
      } w-12 h-12 flex items-center justify-center`}
    >
      {displayName}
    </div>
  );
}

function OrderStatusTag({ currState }) {

  return (
    <span
      className={`text-sm px-3 rounded text-green ${
        currState === "Order Cancelled"
          ? "text-gray"
          : currState === "Quote Cancelled" 
          ? "gray"
          : currState === "Watch Not Accepted" 
          ? "gray"
          : "text-green-500"
      }`}
    >
      {currState}
    </span>
  );
}

function ProductStatusTag({ currState }) {
  let statusName = "Default";
  let tagColor = "gray";

  if (currState === "Active") {
    tagColor = "green";
  } else if (currState === "Sold") {
    tagColor = "gray";
  } else if (currState === "Suspended") {
    tagColor = "red";
  } else {
    tagColor = "gray";
  }

  return (
    <span
      className={`text-sm px-3 rounded text-green ${
        tagColor === "gray"
          ? "text-gray"
          : tagColor === "green"
          ? "text-green-500"
          : tagColor === "green"
          ? "text-red-500"
          : "text-gray"
      }`}
    >
      {currState}
    </span>
  );
}

function RoundStatusTag({ name, color }) {
  return (
    <span
      className={`w-fit text-sm px-2 py-1 rounded-full ${
        color === "gray"
          ? "bg-gray-50 text-gray"
          : color === "green"
          ? "bg-green-50 text-green-500"
          : color === "red"
          ? "bg-red-50 text-red-500"
          : color === "yellow"
          ? "bg-yellow-50 text-yellow-500"
          : "bg-blue-50 text-blue-500"
      }`}
    >
      {name}
    </span>
  );
}

function ProductName({image, brandName, modelName, referenceNumber, price = "" }) {
  return (
    <div className="flex p-4 min-w-[280px]">
      <div className="w-20 h-20">
        <img
          alt={image ? image : "default-watch"}
          src={image ? S3_DOMAIN + image : "/default-watch.svg"}
          className="w-full h-full object-cover"
          style={{
            maxWidth: "100%",
            maxHeight: "100%",
          }}
        />
      </div>
      <div className="ml-6">
        <p>
          {brandName} {modelName}
        </p>
        <p className="mt-1 text-gray">{referenceNumber}</p>
        {price && <p className="mt-4">${convtPrice(price)}</p>}
      </div>
    </div>
  );
}

function ProductNameLink({productId, image, brandName, modelName, referenceNumber, price = "" }) {
  return (
    <NavLink to={`/products/details/${productId}`} className={"hover:underline"}>
    <div className="flex p-4 min-w-[280px]">
      <div className="w-20 h-20">
        <img
          alt={image ? image : "default-watch"}
          src={image ? S3_DOMAIN + image : "/default-watch.svg"}
          className="w-full h-full object-cover"
          style={{
            maxWidth: "100%",
            maxHeight: "100%",
          }}
        />
      </div>
      <div className="ml-6">
        <p>
          {brandName} {modelName}
        </p>
        <p className="mt-1 text-gray">{referenceNumber}</p>
        {price && <p className="mt-4">${convtPrice(price)}</p>}
      </div>
    </div>
    </NavLink>
  );
}



function NoDataChart({ text }) {
  return (
    <div className="w-full h-full flex items-center justify-center flex-col bg-lightbg py-8 rounded">
      
    <AiOutlineQuestionCircle className="text-gray w-12 h-12"/>
    <p className="mt-4 text-gray">{text}</p>
   

</div>
  );
}

function H1({ title }) {
  return <h1 className="text-2xl lg:text-4xl font-semibold mt-2">{title}</h1>;
}

function H2({ title, subText = "" }) {
  return (<div className="flex items-center">
    <h2 className="text-xl lg:text-3xl font-medium mr-2">{title}</h2>
    <span className="text-gray">{subText}</span>
    </div>);
}
function H3({ title }) {
  return <h3 className="text-lg lg:text-xl font-medium">{title}</h3>;
}

function H4({ title }) {
  return <h4 className="text-base font-semibold">{title}</h4>;
}

function HightlightKeywords({ keywords, text }) {
  // Split text on highlight term, include term itself into parts, ignore case
  let parts = [];
  if (keywords && text) {
    parts = text.split(new RegExp(`(${keywords})`, "gi"));
  }

  return (
    <span>
      {keywords ? (
        <>
          {parts.map((part, index) =>
            part.toLowerCase() === keywords.toLowerCase() ? (
              <span key={index} className="text-brandYellow font-bold">
                {part}
              </span>
            ) : (
              part
            )
          )}
        </>
      ) : (
        text
      )}
    </span>
  );
}

function OrderTypeTag({ isOnline }) {
  let statusName = "Online";
  let classNameText = "text-green-500 bg-green-50 rounded-full";

  if (!isOnline) {
    statusName = "In Store";
    classNameText = "text-brandColor-500 bg-blue-50 rounded-full";
  } 

  return (
    <span
      className={`text-sm px-3 py-1 ${classNameText}`}
    >
      {statusName}
    </span>
  );
}

function Tag({ color, name }) {
  let classNameText = "text-green-500 bg-green-50 rounded-full";

  if (color === "green") {
    classNameText = "text-white bg-green-500 rounded-full";
  } else if (color === "red") {
    classNameText = "text-white bg-red-500 rounded-full";
  } else if (color === "yellow") {
    classNameText = "text-white bg-yellow-500 rounded-full";
  } else if (color === "blue") {
    classNameText = "text-blue-500 bg-blue-50 rounded-full";
  } else if (color === "gray") {
    classNameText = "text-gray-500 bg-gray-50 rounded-full";
  } 

  return (
    <span
      className={`text-sm px-3 py-1 ${classNameText}`}
    >
      {name}
    </span>
  );
}

function AddressLine({
  firstName,
  lastName,
  phone,
  streetLine1,
  city,
  region,
  country,
  postalCode,
  showName = true,
  showPhpne = true,
}) {
  // Split text on highlight term, include term itself into parts, ignore case
  let address = [];
  let addressString = "";
  if (streetLine1) {
    address.push(streetLine1);
  }
  if (city) {
    address.push(city);
  }
  if (region) {
    address.push(region);
  }
  if (country) {
    address.push(country);
  }
  if (postalCode) {
    address.push(postalCode);
  }

  if (address.length) {
    addressString = address.join(", ");
  }

  return (
    <div className="text text-darkGray mt-4">
      {showName && (
        <p className="text text-sm">
          {firstName} {lastName}
        </p>
      )}

      {showPhpne && phone && <p className="text text-sm mt-2">{phone}</p>}
      {addressString && <p className="text text-sm mt-2">{addressString}</p>}
    </div>
  );
}

function TwoSteps({ step1Name, step2Name, step1, step2 }) {
  const Step = ({ num, name, isOn }) => {
    return (
      <div className={`border-brandYellow border-solid ${isOn ? "border-b-2" : ""}`}>
        <span className={`text-2xl mr-2 ${isOn ? "text-brandYellow" : ""}`}>
          {num}
        </span>
        <span className={`text-sm ${isOn ? "text-brandYellow" : ""}`}>
         {name}
        </span>
      </div>
    );
};

  return (
    <div className="w-full flex justify-between border-solid border-b border-borderGray my-4">
      <Step num="1" name={step1Name} isOn={step1 || step2 } />
      <Step num="2" name={step2Name} isOn={step2} />

    </div>
  );
}

function FormattedText({ text }) {
  let formatedText = text.split(/\n/).map((line, index) => {
    line = line.trim();
    return line ? <p key={index}>{line}</p> : <br key={index} />;
  });

  return <>{formatedText}</>;
}



export {
  ErrorMessage,
  SuccessMessage,
  Loader,
  UserAvatar,
  OrderStatusTag,
  ProductName,
  ProductNameLink,
  ProductStatusTag,
  NoDataChart,
  H1,
  H2,
  H3,
  H4,
  HightlightKeywords,
  RoundStatusTag,
  OrderTypeTag,
  AddressLine,
  TwoSteps,
  FormattedText,
  Tag,
};
