import Breadcrumbs from "@mui/material/Breadcrumbs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import ScreenTitle from "../../../components/ScreenTitle";
import { SimpleDialog } from "../../../components/dialogs";
import { watchConstantOptionsRequest } from "../../../redux/actions/watchActions";
import commonStyle from "../../../style/commonStyle.module.css";
import useDocumentTitle from "../../../useDocumentTitle";
import QuotationForm from "./QuotationForm";
import { newUpdateQuotationRequest } from "../../../api/consignmentRequests";
import { SelectField } from "../../../components/inputs";
import { Button, LinkButton } from "../../../components/buttons";
import { userDetailsRequest, userListOptionsRequest } from "../../../redux/actions/userActions";
import { ErrorMessage, Loader } from "../../../components/smallComponents";

export default function NewConsignmentQuotation({}) {
  useDocumentTitle("Add Consignment Quotation");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo ? userInfo.data.token : ""}`,
    },
  };

  const { loading, watchOptions, success, error } = useSelector(
    (state) => state.watchConstantOptions
  );
  const {
    loading: userOptionsLoading,
    userOptions,
    success: userOptionsSuccess,
    error: userOptionsError,
  } = useSelector((state) => state.userOptionsList);

  const [saveStatus, setSaveStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const [userId, setUserId] = useState(null);
  const [userIdError, setUserIdErro] = useState("");
  const [showUserField, setShowUserField] = useState(true);

  const [showForm, setShowForm] = useState(false);

  const [createdOrderId, setCreatedOrderId] = useState(null);
  const [dialog, setDialog] = useState(false);

  useEffect(() => {
    dispatch(watchConstantOptionsRequest());
    dispatch(userListOptionsRequest("allUser"));
  }, []);

  useEffect(() => {
    if (userId) {
      dispatch(userDetailsRequest(userId));
    }
  }, [userId]);

  const confirmUserAction = () => {
    if (!userId) {
      setUserIdErro("Please select a user.");
      return;
    }
    setShowUserField(false);
    setShowForm(true);
  };

  const saveQuotation = async (values) => {
    setDialog(true);
    try {
      setSaveStatus((preState) => ({
        ...preState,
        loading: true,
      }));
      const responseData = await newUpdateQuotationRequest(
        "new",
        "",
        values,
        userId,
        config
      );
      if (responseData.data.success) {
        setCreatedOrderId(responseData.data.data.orderId);
        setSaveStatus((preState) => ({
          ...preState,
          loading: false,
          success: true,
        }));
      } else {
        throw responseData.data.message;
      }
    } catch (error) {
      setSaveStatus((preState) => ({
        ...preState,
        loading: false,
        error: error,
      }));
    }
  };

  const closeDialogWithSuccess = () => {
    setDialog(false);
    navigate(`/consignment/details/${createdOrderId}`);
  };

  return (
    <div className={commonStyle.pageContainer}>
      <SimpleDialog
        title="Add New Consignment Quote"
        successMessage="New consignment quote added successfully."
        isDialogOpen={dialog}
        closeDialog={() => setDialog(false)}
        loading={saveStatus.loading}
        loadingMessage="Processing request..."
        success={saveStatus.success}
        error={saveStatus.error}
        confirmAction={closeDialogWithSuccess}
      />
      <div className="w-full p-8">
        <Breadcrumbs aria-label="breadcrumb">
          <NavLink underline="hover" color="inherit" to="/">
            Home
          </NavLink>
          <NavLink
            underline="hover"
            color="inherit"
            to="/consignment/quote-list"
          >
            Consignment
          </NavLink>
          <NavLink
            underline="hover"
            color="text.primary"
            to={`/consignment/quotation/new`}
            aria-current="page"
          >
            New Quotation
          </NavLink>
        </Breadcrumbs>
        <ScreenTitle title="Add New Consignment Quotation" />

        {showUserField && (
          <div className="w-1/2">
            <div className="w-full flex items-end">
              <div className="w-[80%]">
                <SelectField
                  label="Select a user"
                  placeHolder="Select user"
                  selectOptions={userOptionsSuccess ? userOptions : []}
                  value={userId}
                  extractKey="id"
                  onChange={(id) => setUserId(id)}
                  error={userIdError}
                />
              </div>
              <div className={"w-[calc(20%-8px)]"}>
                <NavLink
                  to={"/users/new"}
                  className={`w-full h-12 bg-brandYellow mb-2 ml-2 text-black flex items-center justify-center rounded px-3 py-1  hover:bg-brandYellowHover`}
                >
                  Add New User
                </NavLink>
              </div>
            </div>
            <div className="mt-4">
              <Button name="Next" action={confirmUserAction} />
            </div>
          </div>
        )}

        {showForm && (
          <div>
            {loading ? (
              <Loader mess={"Requesting watch options..."} />
            ) : success ? (
              <QuotationForm type={"new"} orderId={""} onSave={saveQuotation} />
            ) : error ? (
              <ErrorMessage mess={error} />
            ) : (
              <></>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
