"use client";

import { useEffect, useRef, useState } from "react";
import { ImagesPreviewDialog } from "./dialogs";

const S3_DOMAIN = process.env.REACT_APP_S3_DOMAIN;

function ProductImageListPreview({ images }) {
  const [previewModal, setPreviewModal] = useState(false);
  const [current, setCurrent] = useState(0);


  const openPreviewModal = (index) => {
    setPreviewModal(true);
    setCurrent(index);
  };

  return (
    <div className="w-full h-full">
      {previewModal && (
        <ImagesPreviewDialog
          images={images}
          closeDialog={() => setPreviewModal(false)}
          isDialogOpen={previewModal}
        />
      )}
      <div className="w-full grid grid-cols-6 gap-4">
        {images.map((image, index) => (
          <button
            className="w-full h-full listing-image-size"
            onClick={() => openPreviewModal(index)}
            key={index}
          >
            <div className="relative w-full h-full">
            <img
              className="cursor-pointer object-cover rounded"
              alt={"watch image" + image}
              src={image ? S3_DOMAIN + image : "/default-watch.svg"}
            ></img>

            </div>
          </button>
        ))}
      </div>
    </div>
  );
}

function ProductTypeTag({ type }) {
  let typeText = "Dealer Product";
  let classNametText = "bg-blue-50 text-blue-500";

  if (type === "SELLERPRODUCT") {
    classNametText = "bg-blue-50 text-blue-500";
    typeText = "Dealer Product";
  } else if (type === "PLATFORMPRODUCT") {
    classNametText = "bg-green-50 text-green-500";
    typeText = "Platform Product";
  } else if (type === "CONSIGNEDPRODUCT") {
    classNametText = "bg-yellow-50 text-yellow-600";
    typeText = "Consigned Product";
  } else {
    classNametText = "bg-blue-50 text-blue-500";
    typeText = "Dealer Product";
  }

  return (
    <span className={`w-fit px-2 py-1 text-sm ${classNametText} rounded-full`}>{typeText}</span>
  );
}



export {
  ProductImageListPreview,ProductTypeTag
};
