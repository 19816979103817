
import React from "react";
import { convtPrice } from "../../api/functions";

const S3_DOMAIN = process.env.REACT_APP_S3_DOMAIN;

export default function WatchPreviewImage({
  brandName,
  modelName,
  price,
  referenceNumber,
  image,
}) {
  return (
    <div className="mt-4 w-80 rounded flex flex-col item-center hover:cursor-pointer border border-borderGray rounded p-4">
      <div className="relative w-full bg-lightGray" style={{aspectRatio:"1/ 1"}}>
        <img
          alt={"watch image" + image}
          src={image ? S3_DOMAIN + image : "/default-watch.svg"}
          className="w-full object-cover"
          style={{ width: "100%", height: "100%" }}
        />
      </div>
      <div className="w-full mt-4">
        <p className="font-medium">
          {brandName} {modelName}
        </p>
        <p className="truncate overflow-hidden mt-1">{referenceNumber}</p>
        <p className="text-lg mt-2 font-medium">{convtPrice(price)}</p>
      </div>
    </div>
  );
}
