import React, { useEffect, useState } from "react";
import commonStyle from "../../style/commonStyle.module.css";
import axios from "axios";
import useDocumentTitle from "../../useDocumentTitle";
import ScreenTitle from "../../components/ScreenTitle";
import { NavLink } from "react-router-dom";
import { blogListRequest } from "../../api/blogRequests";
import { useSelector } from "react-redux";
import { convToLocalDate } from "../../api/functions";
import { Breadcrumbs } from "@mui/material";


const S3_DOMAIN = process.env.REACT_APP_S3_DOMAIN;

export default function BlogList() {
  useDocumentTitle("Blog List");

  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  const [requestStatus, setRequestStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const [docList, setDocList] = useState([]);

  useEffect(() => {
    const getDocTitles = async () => {
      try {
        setRequestStatus((preState) => ({
          ...preState,
          loading: true,
        }));

        const responseData = await blogListRequest(config);

        if (responseData.data.success) {
          setRequestStatus((preState) => ({
            ...preState,
            loading: false,
            success: true,
            error: "",
          }));
          setDocList(responseData.data.docs);
        } else {
          throw responseData.data.message;
        }
      } catch (error) {
        setRequestStatus((preState) => ({
          ...preState,
          loading: false,
          error: "error",
        }));
      }
    };
    getDocTitles();
  }, []);

  return (
    <div className={commonStyle.pageContainer}>
      <div className="w-full p-8">
        <div className={commonStyle.breadcrumbsContainer}>
          <Breadcrumbs aria-label="breadcrumb">
            <NavLink underline="hover" color="inherit" to="/">
              Home
            </NavLink>
            <NavLink underline="hover" color="inherit" to="/blogs">
              Blog List
            </NavLink>
          
  
          </Breadcrumbs>
        </div>
        <ScreenTitle title="Blog List" />
        {requestStatus.success ? (
          <div className={commonStyle.shortDataTable}>
            <div className="mt-8">
              <table className={commonStyle.pageTable}>
                <thead>
                  <tr>
                    <th>Blog Image</th>
                    <th>Title</th>
                    <th>Create Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {docList.length > 0 ? (
                    docList.map((item, index) => (
                      <tr key={index}>
                        <td className="p-2">
                          <div className="w-20 h-20">
                            <img
                              alt={
                                item.main_image
                                  ? item.main_image
                                  : "default-watch"
                              }
                              src={
                                item.main_image
                                  ? S3_DOMAIN + item.main_image
                                  : "/default-watch.svg"
                              }
                              className="w-full h-full object-cover"
                              style={{
                                maxWidth: "100%",
                                maxHeight: "100%",
                              }}
                            />
                          </div>
                        </td>
                        <td>{item.title}</td>
                        <td>{convToLocalDate(item.atime)}</td>
                        <td>
                          <NavLink
                            underline="hover"
                            color="text.primary"
                            to={`/blog/preview/${item.id}`}
                            aria-current="page"
                            className="text-sm text-blue-500"
                          >
                            Preview
                          </NavLink>
                          <NavLink
                            underline="hover"
                            color="text.primary"
                            to={`/blog/edit/${item.id}`}
                            aria-current="page"
                            className="text-sm text-blue-500 ml-4"
                          >
                            Edit
                          </NavLink>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td>
                        There are no blog available. Please add a new blog
                        now.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
