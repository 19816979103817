import Breadcrumbs from "@mui/material/Breadcrumbs";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { newUpdateQuotationRequest } from "../../../api/consignmentRequests";
import ScreenTitle from "../../../components/ScreenTitle";
import { SimpleDialog } from "../../../components/dialogs";
import commonStyle from "../../../style/commonStyle.module.css";
import useDocumentTitle from "../../../useDocumentTitle";
import ServiceQuotationForm from "./ServiceQuotationForm";
import { newUpdateServiceQuotationRequest } from "../../../api/serviceRequests";

export default function EditServiceQuotation({}) {
  useDocumentTitle("Edit Service Order");
  const { id: orderId } = useParams();

  const navigate = useNavigate();
  const location = useLocation();

  const data = location.state;


  const userId = data.userId;

  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo ? userInfo.data.token : ""}`,
    },
  };

  const [saveStatus, setSaveStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });


  const [createdOrderId, setCreatedOrderId] = useState(null);
  const [dialog, setDialog] = useState(false);


  const saveQuotation = async (values) => {
    setDialog(true);
    try {
      setSaveStatus((preState) => ({
        ...preState,
        loading: true,
      }));
      const responseData = await newUpdateServiceQuotationRequest(
        "edit",
        orderId,
        values,
        userId,
        config
      );
      if (responseData.data.success) {
        setCreatedOrderId(responseData.data.data.orderId);
        setSaveStatus((preState) => ({
          ...preState,
          loading: false,
          success: true,
        }));
      } else {
        throw responseData.data.message;
      }
    } catch (error) {
      setSaveStatus((preState) => ({
        ...preState,
        loading: false,
        error: error,
      }));
    }
  };

  const closeDialogWithSuccess = () => {
    setDialog(false);
    navigate(`/consignment/details/${createdOrderId}`);
  };

  return (
    <div className={commonStyle.pageContainer}>
      <SimpleDialog
        title="Edit Service Order"
        successMessage="Service order updated successfully."
        isDialogOpen={dialog}
        closeDialog={() => setDialog(false)}
        loading={saveStatus.loading}
        loadingMessage="Processing request..."
        success={saveStatus.success}
        error={saveStatus.error}
        confirmAction={closeDialogWithSuccess}
      />
      <div className="w-full p-8">
        <Breadcrumbs aria-label="breadcrumb">
          <NavLink underline="hover" color="inherit" to="/">
            Home
          </NavLink>
          <NavLink underline="hover" color="inherit" to="/service/quote-list">
            Service
          </NavLink>
          <NavLink
            underline="hover"
            color="text.primary"
            to={`/service/order/edit/${orderId}`}
            aria-current="page"
          >
            Edit Service Order
          </NavLink>
        </Breadcrumbs>
        <ScreenTitle title="Edit Service Order" />

        <div>
        <ServiceQuotationForm type={"edit"} orderId={orderId} onSave={saveQuotation} />
        </div>
      </div>
    </div>
  );
}
