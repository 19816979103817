import Breadcrumbs from "@mui/material/Breadcrumbs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import ScreenTitle from "../../components/ScreenTitle";
import { SimpleDialog } from "../../components/dialogs";
import { ErrorMessage, Loader } from "../../components/smallComponents";
import commonStyle from "../../style/commonStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle";
import {
  editEmailTaskRequest,
  emailRequestDetails,
} from "../../api/marketingEmailTaksRequets";
import EmailTaskForm from "./EmailTaskForm";
import { K_EMAIL_PRODUCTS_TYPES } from "../../constantData/K_EMAIL_PRODUCTS_TYPES";

export default function EditEmailTask() {
  useDocumentTitle("Edit Email Task");
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { id: requestId } = useParams();

  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };
  const [reqStatus, setReqStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const [taskInfo, setTaskInfo] = useState({});

  const [saveStatus, setSaveStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const [dialog, setDialog] = useState(false);

  useEffect(() => {
    const getTaskDetails = async () => {
      try {
        setReqStatus((preState) => ({
          ...preState,
          loading: true,
        }));

        const responseData = await emailRequestDetails(
          requestId,
          config,
          dispatch
        );
        if (responseData.data.success) {
          setReqStatus((preState) => ({
            ...preState,
            loading: false,
            success: true,
          }));

          let task_info = {
            subject: responseData.data.data.subject,
            startTime: responseData.data.data.startTime
              ? new Date(responseData.data.data.startTime * 1000)
              : "",
            content: responseData.data.data.content,
            image: responseData.data.data.image,
            productsType: K_EMAIL_PRODUCTS_TYPES.find(x=> x.value === responseData.data.data.productsType)
          };
          setTaskInfo(task_info);
        } else {
          throw responseData.data.message;
        }
      } catch (error) {
        setReqStatus((preState) => ({
          ...preState,
          loading: false,
          error: error,
        }));
      }
    };

    getTaskDetails();
  }, [dispatch, requestId]);

  //  dialog
  const handleSave = async (values) => {
    try {
      setDialog(true);
      setSaveStatus({ ...saveStatus, loading: true });
      const responseData = await editEmailTaskRequest(
        requestId,
        values,
        config,
        dispatch
      );
      if (responseData.data.success) {
        setSaveStatus({ ...saveStatus, loading: false, success: true });
      } else {
        throw responseData.data.message;
      }
    } catch (error) {
      setSaveStatus({ ...saveStatus, loading: false, error: error });
    }
  };

  const closeDialogWithSuccess = () => {
    setDialog(false);
    navigate("/marketing-email/tasks", { replace: true, from: location });
  };

  return (
    <div className={commonStyle.pageContainer}>
      <SimpleDialog
        title="Edit Email Task"
        successMessage="Email task updated successfully."
        isDialogOpen={dialog}
        closeDialog={() => setDialog(false)}
        loading={saveStatus.loading}
        loadingMessage="Processing request..."
        success={saveStatus.success}
        error={saveStatus.error}
        confirmAction={closeDialogWithSuccess}
      />

      <div className="w-full p-8">
        <Breadcrumbs aria-label="breadcrumb">
          <NavLink underline="hover" color="inherit" to="/">
            Home
          </NavLink>
          <NavLink
            underline="hover"
            color="inherit"
            to="/marketing-email/tasks"
          >
            Email Tasks
          </NavLink>
          <NavLink
            underline="hover"
            color="text.primary"
            to={`/marketing-email/tasks/edit/${requestId}`}
            aria-current="page"
          >
            Edit Task
          </NavLink>
        </Breadcrumbs>
        <ScreenTitle title="Edit Email Task" />
        {reqStatus.loading ? (
          <Loader mess="Requesting..." />
        ) : reqStatus.success ? (
          <EmailTaskForm
            type="edit"
            onSave={handleSave}
            initialValues={taskInfo}
          />
        ) : reqStatus.error ? (
          <ErrorMessage mess={reqStatus.error} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
