import React, { useEffect, useState } from "react";
import SingleImageUploader from "../../components/SingleImageUploader";
import { Button } from "../../components/buttons";
import {
  DateTimePicker,
  FieldLabel,
  SelectField,
  TextAreaField,
  TextField,
} from "../../components/inputs";
import { K_EMAIL_PRODUCTS_TYPES } from "../../constantData/K_EMAIL_PRODUCTS_TYPES";

export default function EmailTaskForm({ type, initialValues, onSave }) {

  const [formState, setFormState] = useState(initialValues);
  const getUploadedImages = (file) => {
    if (file.uri) {
      setFormState({ ...formState, ["image"]: file.uri });
    }
  };

  const [formErrors, setFormErrors] = useState({});
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };

  // // // // // //
  useEffect(() => {
    if (type === "edit") {
      setFormState({
        image: initialValues.image,
        subject: initialValues.subject,
        content: initialValues.content,
        startTime: initialValues.startTime,
        productsType: initialValues.productsType,
      });
    } else {
      setFormState(initialValues);
    }
  }, [type, initialValues]);

  // // // // // //

  const handleSubmit = () => {
    const hasErrors = validate(formState);

    if (Object.keys(hasErrors).length === 0) {
      setFormErrors({});
      let submittedValues = {
        image: formState.image,
        subject: formState.subject,
        content: formState.content,
        startTime: formState.startTime
          ? Math.floor(formState.startTime.getTime() / 1000)
          : "",
        productsType: formState.productsType ? formState.productsType.value : "",
      };
      onSave(submittedValues);
    } else {
      setFormErrors(hasErrors);
    }
  };

  const validate = (values) => {

    const errors = {};

    if (!values.image) {
      errors.image = "Email image is required.";
    }
    if (!values.subject) {
      errors.subject = "Email subject is required.";
    }
    if (!values.content) {
      errors.content = "Email content is required.";
    }
    if (!values.productsType) {
      errors.productsType = "Email products type is required.";
    }
    return errors;
  };
  
  return (
    <div>
      <div className="w-full">
        <div className="w-1/2">
          <div className="w-full">
            <FieldLabel label="Email Background Image" />
            <div className="w-full">
              <div className="w-full">
                <SingleImageUploader
                  onUpload={getUploadedImages}
                  imageError={formErrors.image}
                  uri={formState.image}
                  folderName="marketingEmailImages"
                  compress={false}
                />
              </div>
            </div>
          </div>

          <TextField
            type="text"
            label="Subject"
            name="subject"
            placeholder={"The email subject"}
            value={formState.subject}
            onChange={(subject) =>
              handleChange({
                target: { value: subject, name: "subject" },
              })
            }
            error={formErrors.subject}
          />

          <TextAreaField
            type="text"
            label="Email Content "
            name="content"
            value={formState.content}
            rows={12}
            placeholder="The text above the product list..."
            onChange={(content) =>
              handleChange({
                target: { value: content, name: "content" },
              })
            }
            error={formErrors.content}
          />
          <SelectField
            id="products_type"
            label={"Products Type"}
            placeHolder="Select products type"
            selectOptions={K_EMAIL_PRODUCTS_TYPES}
            value={formState.productsType}
            onChange={(productsType) =>
              handleChange({
                target: { value: productsType, name: "productsType" },
              })
            }
            error={formErrors.productsType}
          />
          <DateTimePicker
            label={"Send Time"}
            value={formState.startTime}
            onChange={(date) =>
              handleChange({
                target: { value: date, name: "startTime" },
              })
            }
          />

          <div className="mt-8 w-full bg-red-200">
            <Button
              name={type === "new" ? "Save" : "Update"}
              action={handleSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
