import Breadcrumbs from "@mui/material/Breadcrumbs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { newUpdateQuotationRequest } from "../../../api/consignmentRequests";
import ScreenTitle from "../../../components/ScreenTitle";
import { SimpleDialog } from "../../../components/dialogs";
import { ErrorMessage, Loader } from "../../../components/smallComponents";
import { watchConstantOptionsRequest } from "../../../redux/actions/watchActions";
import useDocumentTitle from "../../../useDocumentTitle";
import commonStyle from "../../../style/commonStyle.module.css";
import QuotationForm from "./QuotationForm";

export default function EditConsignmentQuotation({}) {
  useDocumentTitle("Edit Consignment Quotation");
  const { id: consignId } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const data = location.state;


  const userId = data.userId;

  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo ? userInfo.data.token : ""}`,
    },
  };

  const { loading, watchOptions, success, error } = useSelector(
    (state) => state.watchConstantOptions
  );
  const [saveStatus, setSaveStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });


  const [createdOrderId, setCreatedOrderId] = useState(null);
  const [dialog, setDialog] = useState(false);

  useEffect(() => {
    dispatch(watchConstantOptionsRequest());
  }, []);

  const saveQuotation = async (values) => {
    setDialog(true);
    try {
      setSaveStatus((preState) => ({
        ...preState,
        loading: true,
      }));
      const responseData = await newUpdateQuotationRequest(
        "edit",
        consignId,
        values,
        userId,
        config
      );
      if (responseData.data.success) {
        setCreatedOrderId(responseData.data.data.orderId);
        setSaveStatus((preState) => ({
          ...preState,
          loading: false,
          success: true,
        }));
      } else {
        throw responseData.data.message;
      }
    } catch (error) {
      setSaveStatus((preState) => ({
        ...preState,
        loading: false,
        error: error,
      }));
    }
  };

  const closeDialogWithSuccess = () => {
    setDialog(false);
    navigate(`/consignment/details/${createdOrderId}`);
  };

  return (
    <div className={commonStyle.pageContainer}>
      <SimpleDialog
        title="Add New Consignment Quote"
        successMessage="New consignment quote added successfully."
        isDialogOpen={dialog}
        closeDialog={() => setDialog(false)}
        loading={saveStatus.loading}
        loadingMessage="Processing request..."
        success={saveStatus.success}
        error={saveStatus.error}
        confirmAction={closeDialogWithSuccess}
      />
      <div className="w-full p-8">
        <Breadcrumbs aria-label="breadcrumb">
          <NavLink underline="hover" color="inherit" to="/">
            Home
          </NavLink>
          <NavLink underline="hover" color="inherit" to="/consignment/quote-list">
            Consignment
          </NavLink>
          <NavLink
            underline="hover"
            color="text.primary"
            to={`/products/edit/${consignId}`}
            aria-current="page"
          >
            Edit Quotation
          </NavLink>
        </Breadcrumbs>
        <ScreenTitle title="Add New Consignment Quotation" />

        <div>
          {loading ? (
            <Loader mess={"Requesting watch options..."} />
          ) : success ? (
            <QuotationForm type={"edit"} orderId={consignId} onSave={saveQuotation} />
          ) : error ? (
            <ErrorMessage mess={error} />
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}
