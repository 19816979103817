import React, { useRef, useState } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
// import required modules
import { Pagination } from "swiper/modules";

const S3_DOMAIN = process.env.REACT_APP_S3_DOMAIN;

export default function ProductImageGallery({ images }) {
  const swiperRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const prevImage = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const nextImage = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const changeImage = (index) => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideTo(index);
    }
  };

  return (
    <div className="w-full overflow-hidden relative">
      <div className="w-full relative ">
        <Swiper
          ref={swiperRef}
          slidesPerView={1}
          style={{
            "--swiper-navigation-color": "#fff",
            "--swiper-pagination-color": "#CBA975",
          }}
          spaceBetween={30}
          loop={true}
          pagination={true}
          onActiveIndexChange={(index) => setCurrentIndex(index.realIndex)}
          modules={[Pagination]}
          className="mySwiper"
        >
          {images.map((image, index) => (
            <SwiperSlide key={index}>
              <div className="relative w-full h-0 pt-[100%]">
                <img
                  className="absolute top-0 w-full h-full object-cover "
                  alt={`watch image ${image}`}
                  src={image ? S3_DOMAIN + image : "/default-watch.svg"}
                />

                <button
                  onClick={prevImage}
                  className="block absolute left-0 top-1/2 transform -translate-y-1/2 p-2 bg-whiteOverlay rounded-full text-black hover:bg-white bg-red-200"
                >
                  <IoIosArrowBack className="w-8 h-8 text-brandColor" />
                </button>
                <button
                  onClick={nextImage}
                  className="block absolute right-0 top-1/2 transform -translate-y-1/2 p-2 bg-whiteOverlay rounded-full text-black hover:bg-white"
                >
                  <IoIosArrowForward className="w-8 h-8 text-brandColor" />
                </button>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="flex mt-4">
        {images.map((image, index) => (
          <div
            className={` relative`}
            key={index}
          >
            <img
              className="w-16 h-16 mr-2 cursor-pointer object-cover"
              alt={"watch image" + image}
              src={image ? S3_DOMAIN + image : "/default-watch.svg"}
            ></img>
            <div
              className={`absolute top-0 left-0 w-full h-full bg-white cursor-pointer ${
                index === currentIndex ? "bg-opacity-0" : "bg-opacity-70"
              }` }
              onClick={() => changeImage(index)}
            ></div>
          </div>
        ))}
      </div>
    </div>
  );
}
