import { Button } from "../../components/buttons";
import { ErrorMessage, HightlightKeywords, Loader } from "../../components/smallComponents";
import { PureTextField } from "../../components/inputs";
import { useEffect, useState } from "react";
import { searchWatchRequest } from "../../api/productsRequests";
import { NavLink } from "react-router-dom";

const S3_DOMAIN = process.env.REACT_APP_S3_DOMAIN;

export default function SearchWatch({onSelect, keywordPreset = ""}) {
  const config = {
    headers: {
      "Content-type": "application/json",
    },
  };

  const [reqStatus, setReqStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const [keyword, setKeyword] = useState(keywordPreset ? keywordPreset : "");
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [watches, setWatches] = useState([]);

  const handleKeywordChange = (value) => {
    setKeyword(value);
  };

  const handleSelect = (id) => {
    setShowSearchResults(false);
    onSelect(id);
    setKeyword("")
  }

  useEffect(() => {
    const searchWatch = async () => {
      try {
        setReqStatus((preState) => ({
          ...preState,
          loading: true,
        }));
        const responseData = await searchWatchRequest(keyword, config);
        if (responseData.data.success) {
          setReqStatus((preState) => ({
            ...preState,
            loading: false,
            success: true,
          }));
          setWatches(responseData.data.data);
        } else {
          throw responseData.data.message;
        }
      } catch (error) {
        setReqStatus((preState) => ({
          ...preState,
          loading: false,
          error: error,
        }));
      }
    };
    if (keyword.length > 2) {
      searchWatch();
      setShowSearchResults(true);
    } else {
      setShowSearchResults(false);
    }
  }, [keyword]);


  const ViewItem = ({ label, value }) => {
    return (
      <div className="flex flex-col mr-6 text-left">
        <span className="text-gray">{label}</span>
        <span className="mt-1">{value}</span>
      </div>
    );
  };

  return (
    <div className="relative w-full">
      <div className="flex w-full">
        <div className="w-full">
          <PureTextField
            type="text"
            name="keyword"
            placeholder="type brand,model, reference number to search..."
            autofocus={true}
            value={keyword}
            hasBorder={true}
            showSearchIcon={true}
            onChange={(query) => handleKeywordChange(query)}
          />
        </div>
      </div>

      {showSearchResults ? (
        <div className="absolute top-12 flex w-full justify-between text-black z-10">
          <div className="flex w-full lg:w-3/4 max-h-476 justify-start rounded bg-white shadow-md p-2 overflow-auto">
            {reqStatus.loading ? (
              <Loader mess="Requesting watches..." />
            ) : reqStatus.success ? (
              watches.length > 0 ? (
                <ul className="w-full">
                  {watches.map((item, index) => (
                    <li key={index} className="w-full hover:bg-blue-50">
                      <button
                        onClick={()=>handleSelect(item.id)}
                        className={`w-full flex py-4 border-b border-borderGray cursor-pointer`}
                      >
                        <div className="relative w-20 h-20 mr-4">
                          <img
                            src={
                              item.watchImageUri
                                ? S3_DOMAIN + item.watchImageUri
                                : "/default-watch.svg"
                            }
                            alt={"watch image " + item.watchImageUri}
                            className="w-full h-full object-contain"
                            style={{ maxWidth: "100%", maxHeight: "100%" }}
                          ></img>
                        </div>

                        <div className="w-full">
                          <div className="w-full text-left">
                            <span className="text-lg font-medium mr-2">
                              <HightlightKeywords keywords={keyword} text={item.brandName} />
                             
                            </span>
                            <span> <HightlightKeywords keywords={keyword} text={item.modelName} /></span>
                          </div>
                          <p className="text-left mt-1"><HightlightKeywords keywords={keyword} text={item.referenceNumber} /></p>
                          <div className="w-full mt-4 flex">
                            {item.dialColor && (
                              <ViewItem
                                label="Dial Color"
                                value={item.dialColor}
                              />
                            )}
                            {item.caseDiameterWidth && (
                              <ViewItem
                                label="Case Diameter"
                                value={item.caseDiameterWidth}
                              />
                            )}
                            {item.caseMaterial && (
                              <ViewItem
                                label="Case Material"
                                value={item.caseMaterial}
                              />
                            )}
                          </div>
                        </div>
                      </button>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>There is no results</p>
              )
            ) : reqStatus.error ? (
              <ErrorMessage mess={reqStatus.error} />
            ) : (
              <></>
            )}
          </div>
          <div className="hidden lg:w-1/4 lg:ml-2"></div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
