import Breadcrumbs from "@mui/material/Breadcrumbs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  collectionDetails,
  editCollection
} from "../../../api/webContentRequets";
import ScreenTitle from "../../../components/ScreenTitle";
import { SimpleDialog } from "../../../components/dialogs";
import { ErrorMessage, Loader } from "../../../components/smallComponents";
import commonStyle from "../../../style/commonStyle.module.css";
import useDocumentTitle from "../../../useDocumentTitle";
import CollectionForm from "./CollectionForm";


export default function EditCollection() {
  useDocumentTitle("Edit Collection");
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { id:collectionId } = useParams();

  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };
  const [reqStatus, setReqStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const [CollectionInfo, setCollectionInfo] = useState({});

  const [saveStatus, setSaveStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  useEffect(() => {
    const getCollectionDetails = async () => {
      try {
        setReqStatus((preState) => ({
          ...preState,
          loading: true,
        }));

        const responseData = await collectionDetails(collectionId, config, dispatch);
        if (responseData.data.success) {
          setReqStatus((preState) => ({
            ...preState,
            loading: false,
            success: true,
          }));
          setCollectionInfo(responseData.data.data);
        } else {
          throw responseData.data.message;
        }
      } catch (error) {
        setReqStatus((preState) => ({
          ...preState,
          loading: false,
          error: error,
        }));
      }
    };

    getCollectionDetails();
  }, [dispatch]);

  //  dialog
  const handleSave = async (values) => {

    try {
      setDialog(true);
      setSaveStatus({ ...saveStatus, loading: true });
      const responseData = await editCollection(collectionId, values, config, dispatch);
      if (responseData.data.success) {
        setSaveStatus({ ...saveStatus, loading: false, success: true });
      } else {
        throw responseData.data.message;
      }
    } catch (error) {
      setSaveStatus({ ...saveStatus, loading: false, error: error });
    }
  };
  const [dialog, setDialog] = useState(false);

  const closeDialogWithSuccess = () => {
    setDialog(false);
    navigate("/web-content/collections", { replace: true, from: location });
  };

  return (
    <div className={commonStyle.pageContainer}>
      <SimpleDialog
        title="Edit Collection"
        successMessage="Collection updated successfully."
        isDialogOpen={dialog}
        closeDialog={() => setDialog(false)}
        loading={saveStatus.loading}
        loadingMessage="Processing request..."
        success={saveStatus.success}
        error={saveStatus.error}
        confirmAction={closeDialogWithSuccess}
      />

      <div className="w-full p-8">
        <Breadcrumbs aria-label="breadcrumb">
          <NavLink underline="hover" color="inherit" to="/">
            Home
          </NavLink>
          <NavLink underline="hover" color="inherit" to="/web-content/collections">
            Collections
          </NavLink>
          <NavLink
            underline="hover"
            color="text.primary"
            to={`/web-content/collections/edit/${collectionId}`}
            aria-current="page"
          >
            Edit Collection
          </NavLink>
        </Breadcrumbs>
        <ScreenTitle title="Edit Collection" />
        {reqStatus.loading ? (
          <Loader mess="Requesting..." />
        ) : reqStatus.success ? (
          <CollectionForm
            type="edit"
            onSave={handleSave}
            initialValues={CollectionInfo}
          />
        ) : reqStatus.error ? (
          <ErrorMessage mess={reqStatus.error} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
