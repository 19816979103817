// get package list
import axios from "axios";


const SERVER_URL = process.env.REACT_APP_SERVER_URL;




export const newUpdateQuotationRequest = async (type, orderId, values, userId, config) => {
  let url = `/admin/consignment/quotation/new?userId=${userId}`;
  if(type === "new"){
    url = `/admin/consignment/quotation/new?userId=${userId}`;
  }else if (type === "edit"){
    url = `/admin/consignment/quotation/update/${orderId}?userId=${userId}`;
  }
  try {
    const response = await axios.post(
      SERVER_URL + url,
      values,
      config
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const consignmentQuoteDetailsApiRequest = async (orderId, config) => {
  try {
    const response = await axios.get(
      SERVER_URL + `/admin/consignment/details/${orderId}`,
      config
    );
    return response;
  } catch (error) {
    throw error;
  }
};









