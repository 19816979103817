const K_SERVICE_TYPES = [
  { id: 1, label: "5-Position Timing Results", value: "5-Position Timing Results" },
  { id: 2, label: "Demagnetizing", value: "Demagnetizing" },
  { id: 3, label: "Water Resistance Testing", value: "Water Resistance Testing" },
  { id: 4, label: "Deep Cleaning", value: "Deep Cleaning" },
  { id: 5, label: "Case/bracelet Refurbish", value: "Case/bracelet Refurbish" },
  { id: 6, label: "Movement Service", value: "Movement Service" },
  { id: 6, label: "Other", value: "Other" },
];

export {K_SERVICE_TYPES}