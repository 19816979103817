
import axios from "axios";
import { authMiddleware } from "./authMiddleware";
import {
  CONSIGNMENT_QUOTE_DETAILS_FAIL,
  CONSIGNMENT_QUOTE_DETAILS_REQUEST,
  CONSIGNMENT_QUOTE_DETAILS_SUCCESS,
  CONSIGNMENT_QUOTE_LIST_FAIL,
  CONSIGNMENT_QUOTE_LIST_REQUEST,
  CONSIGNMENT_QUOTE_LIST_SUCCESS,
  ORDER_DETAILS_FAIL,
  ORDER_DETAILS_REQUEST,
  ORDER_DETAILS_SUCCESS,
  ORDER_LIST_FAIL,
  ORDER_LIST_REQUEST,
  ORDER_LIST_SUCCESS,
  SERVICE_QUOTE_DETAILS_FAIL,
  SERVICE_QUOTE_DETAILS_REQUEST,
  SERVICE_QUOTE_DETAILS_SUCCESS,
  SERVICE_QUOTE_LIST_FAIL,
  SERVICE_QUOTE_LIST_REQUEST,
  SERVICE_QUOTE_LIST_SUCCESS,
  UPDATE_ORDER_PRICE_FAIL,
  UPDATE_ORDER_PRICE_REQUEST,
  UPDATE_ORDER_PRICE_SUCCESS,
  UPDATE_ORDER_SHIPPING_FEE_FAIL,
  UPDATE_ORDER_SHIPPING_FEE_REQUEST,
  UPDATE_ORDER_SHIPPING_FEE_SUCCESS,
  WATCH_REQUEST_DETAILS_FAIL,
  WATCH_REQUEST_DETAILS_REQUEST,
  WATCH_REQUEST_DETAILS_SUCCESS,
  WATCH_REQUEST_LIST_FAIL,
  WATCH_REQUEST_LIST_REQUEST,
  WATCH_REQUEST_LIST_SUCCESS,
} from "../constants/orderConstants";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export const orderListRequest =
  (keyword, pageNum, pageSize) => async (dispatch, getState) => {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };
    try {
      dispatch({ type: ORDER_LIST_REQUEST });

      axios
        .get(
          SERVER_URL +
            `/admin/order/list?page_num=${pageNum}&page_size=${pageSize}&keyword=${keyword}`,
          config
        )
        .then(async (response) => authMiddleware(response, dispatch))
        .then(async (response) => {
          if (response.data.success) {
            dispatch({ type: ORDER_LIST_SUCCESS, payload: response.data.data });
          } else {
            throw response.data.message;
          }
        })
        .catch((err) => {
          dispatch({
            type: ORDER_LIST_FAIL,
            payload: err,
          });
        });
    } catch (error) {
      dispatch({
        type: ORDER_LIST_FAIL,
        payload: "Connection error, please try again later",
      });
    }
  };

export const orderDetailsRequest = (id) => async (dispatch, getState) => {
  const {
    userLogin: { userInfo },
  } = getState();

  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };
  try {
    dispatch({ type: ORDER_DETAILS_REQUEST });

    axios
      .get(SERVER_URL + `/admin/order/details/${id}`, config)
      .then(async (response) => authMiddleware(response, dispatch))
      .then(async (response) => {
        if (response.data.success) {
          dispatch({
            type: ORDER_DETAILS_SUCCESS,
            payload: response.data.data,
          });
        } else {
          throw response.data.message;
        }
      })
      .catch((err) => {
        dispatch({
          type: ORDER_DETAILS_FAIL,
          payload: err,
        });
      });
  } catch (error) {
    dispatch({
      type: ORDER_DETAILS_FAIL,
      payload: "Connection error, please try again later",
    });
  }
};

export const updateOrderPriceRequest =
  (orderId, values) => async (dispatch, getState) => {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };
    try {
      dispatch({ type: UPDATE_ORDER_PRICE_REQUEST });

      axios
        .post(
          SERVER_URL + `/admin/order/update/price/${orderId}`,
          values,
          config
        )
        .then(async (response) => {
          if (response.data.success) {
            dispatch({ type: UPDATE_ORDER_PRICE_SUCCESS });
          } else {
            throw response.data.message;
          }
        })
        .catch((err) => {
          dispatch({
            type: UPDATE_ORDER_PRICE_FAIL,
            payload: err,
          });
        });
    } catch (error) {
      dispatch({
        type: UPDATE_ORDER_PRICE_FAIL,
        payload: "Connection error, please try again later",
      });
    }
  };

export const updateOrderShippingFeeRequest =
  (orderId, values) => async (dispatch, getState) => {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };
    try {
      dispatch({ type: UPDATE_ORDER_SHIPPING_FEE_REQUEST });

      axios
        .post(
          SERVER_URL + `/admin/order/update/shipping-fee/${orderId}`,
          values,
          config
        )
        .then(async (response) => {
          if (response.data.success) {
            dispatch({ type: UPDATE_ORDER_SHIPPING_FEE_SUCCESS });
          } else {
            throw response.data.message;
          }
        })
        .catch((err) => {
          dispatch({
            type: UPDATE_ORDER_SHIPPING_FEE_FAIL,
            payload: err,
          });
        });
    } catch (error) {
      dispatch({
        type: UPDATE_ORDER_SHIPPING_FEE_FAIL,
        payload: "Connection error, please try again later",
      });
    }
  };

export const consignmentQuoteListRequest =
  (keyword, pageNum, pageSize) => async (dispatch, getState) => {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };
    try {
      dispatch({ type: CONSIGNMENT_QUOTE_LIST_REQUEST });

      axios
        .get(
          SERVER_URL +
            `/admin/consignment/list?page_num=${pageNum}&page_size=${pageSize}&keyword=${keyword}`,
          config
        )
        .then(async (response) => authMiddleware(response, dispatch))
        .then(async (response) => {
          if (response.data.success) {
            dispatch({
              type: CONSIGNMENT_QUOTE_LIST_SUCCESS,
              payload: response.data.data,
            });
          } else {
            throw response.data.message;
          }
        })
        .catch((err) => {
          dispatch({
            type: CONSIGNMENT_QUOTE_LIST_FAIL,
            payload: err,
          });
        });
    } catch (error) {
      dispatch({
        type: CONSIGNMENT_QUOTE_LIST_FAIL,
        payload: "Connection error, please try again later",
      });
    }
  };

export const consignmentQuoteDetailsRequest =
  (id) => async (dispatch, getState) => {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };
    try {
      dispatch({ type: CONSIGNMENT_QUOTE_DETAILS_REQUEST });

      axios
        .get(SERVER_URL + `/admin/consignment/details/${id}`, config)
        .then(async (response) => authMiddleware(response, dispatch))
        .then(async (response) => {
          if (response.data.success) {
            dispatch({
              type: CONSIGNMENT_QUOTE_DETAILS_SUCCESS,
              payload: response.data.data,
            });
          } else {
            throw response.data.message;
          }
        })
        .catch((err) => {
          dispatch({
            type: CONSIGNMENT_QUOTE_DETAILS_FAIL,
            payload: err,
          });
        });
    } catch (error) {
      dispatch({
        type: CONSIGNMENT_QUOTE_DETAILS_FAIL,
        payload: "Connection error, please try again later",
      });
    }
  };

  export const serviceQuoteListRequest =
  (keyword, pageNum, pageSize) => async (dispatch, getState) => {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };
    try {
      dispatch({ type: SERVICE_QUOTE_LIST_REQUEST });

      axios
        .get(
          SERVER_URL +
            `/admin/service/list?page_num=${pageNum}&page_size=${pageSize}&keyword=${keyword}`,
          config
        )
        .then(async (response) => authMiddleware(response, dispatch))
        .then(async (response) => {
          if (response.data.success) {
            dispatch({
              type: SERVICE_QUOTE_LIST_SUCCESS,
              payload: response.data.data,
            });
          } else {
            throw response.data.message;
          }
        })
        .catch((err) => {
          dispatch({
            type: SERVICE_QUOTE_LIST_FAIL,
            payload: err,
          });
        });
    } catch (error) {
      dispatch({
        type: SERVICE_QUOTE_LIST_FAIL,
        payload: "Connection error, please try again later",
      });
    }
  };

export const serviceQuoteDetailsRequest =
  (id) => async (dispatch, getState) => {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };
    try {
      dispatch({ type: SERVICE_QUOTE_DETAILS_REQUEST });

      axios
        .get(SERVER_URL + `/admin/service/order/details/${id}`, config)
        .then(async (response) => authMiddleware(response, dispatch))
        .then(async (response) => {
          if (response.data.success) {
            dispatch({
              type: SERVICE_QUOTE_DETAILS_SUCCESS,
              payload: response.data.data,
            });
          } else {
            throw response.data.message;
          }
        })
        .catch((err) => {
          dispatch({
            type: SERVICE_QUOTE_DETAILS_FAIL,
            payload: err,
          });
        });
    } catch (error) {
      dispatch({
        type: SERVICE_QUOTE_DETAILS_FAIL,
        payload: "Connection error, please try again later",
      });
    }
  };

  export const watchRequestListRequest =
  (keyword, pageNum, pageSize) => async (dispatch, getState) => {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };
    try {
      dispatch({ type: WATCH_REQUEST_LIST_REQUEST });

      axios
        .get(
          SERVER_URL +
            `/admin/watch-request/list?page_num=${pageNum}&page_size=${pageSize}&keyword=${keyword}`,
          config
        )
        .then(async (response) => authMiddleware(response, dispatch))
        .then(async (response) => {
          if (response.data.success) {
            dispatch({
              type: WATCH_REQUEST_LIST_SUCCESS,
              payload: response.data.data,
            });
          } else {
            throw response.data.message;
          }
        })
        .catch((err) => {
          dispatch({
            type: WATCH_REQUEST_LIST_FAIL,
            payload: err,
          });
        });
    } catch (error) {
      dispatch({
        type: WATCH_REQUEST_LIST_FAIL,
        payload: "Connection error, please try again later",
      });
    }
  };

export const watchRequestDetailsRequest =
  (id) => async (dispatch, getState) => {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };
    try {
      dispatch({ type: WATCH_REQUEST_DETAILS_REQUEST });

      axios
        .get(SERVER_URL + `/admin/watch-request/details/${id}`, config)
        .then(async (response) => authMiddleware(response, dispatch))
        .then(async (response) => {
          if (response.data.success) {
            dispatch({
              type: WATCH_REQUEST_DETAILS_SUCCESS,
              payload: response.data.data,
            });
          } else {
            throw response.data.message;
          }
        })
        .catch((err) => {
          dispatch({
            type: WATCH_REQUEST_DETAILS_FAIL,
            payload: err,
          });
        });
    } catch (error) {
      dispatch({
        type: WATCH_REQUEST_DETAILS_FAIL,
        payload: "Connection error, please try again later",
      });
    }
  };