import { Button } from "../../../components/buttons";
import { ErrorMessage, HightlightKeywords, Loader } from "../../../components/smallComponents";
import { PureTextField } from "../../../components/inputs";
import { useEffect, useState, useRef } from "react";
import { searchWatchRequest } from "../../../api/productsRequests";
import { MdOutlineSearch} from "react-icons/md"
import { NavLink } from "react-router-dom";

const S3_DOMAIN = process.env.REACT_APP_S3_DOMAIN;

export default function SearchConsignmentWatch({onSelect, readOnly}) {

  const searchRef = useRef(null);
  const config = {
    headers: {
      "Content-type": "application/json",
    },
  };

  const [reqStatus, setReqStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const [keyword, setKeyword] = useState("");
  const [shownText, setShownText] = useState("");
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [watches, setWatches] = useState([]);

  const handleKeywordChange = (e) => {
    e.preventDefault();
    setKeyword(e.target.value);
    setShownText(e.target.value);
  };

  useEffect(() => {
    const searchWatch = async () => {
      try {
        setReqStatus((preState) => ({
          ...preState,
          loading: true,
        }));
        const responseData = await searchWatchRequest(keyword, config);
        if (responseData.data.success) {
          setReqStatus((preState) => ({
            ...preState,
            loading: false,
            success: true,
          }));
          setWatches(responseData.data.data);
        } else {
          throw responseData.data.message;
        }
      } catch (error) {
        setReqStatus((preState) => ({
          ...preState,
          loading: false,
          error: error,
        }));
      }
    };
    if (keyword.length > 2) {
      searchWatch();
      setShowSearchResults(true);
    } else {
      setShowSearchResults(false);
    }
  }, [keyword]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setShowSearchResults(false); // Close the search results
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSelect = (watch) => {
    const brandName = watch.brandName ? watch.brandName : "";
    const modelName = watch.modelName ? watch.modelName : "";

    const referenceNumber = watch.referenceNumber ? watch.referenceNumber : "";
    const dialColor = watch.dialColor ? watch.dialColor : "";
    const caseMaterial = watch.caseMaterial ? watch.caseMaterial : "";
    const watchMovement = watch.watchMovement ? watch.watchMovement : "";

    const combinedName = `${brandName} ${modelName} ${referenceNumber} ${dialColor} ${caseMaterial} ${watchMovement}`;
    setShownText(combinedName);
    setShowSearchResults(false);
    onSelect(watch);
  };

  const ViewItem = ({ label, value }) => {
    return (
      <div className="flex flex-col mr-6 text-left">
        <span className="text-gray">{label}</span>
        <span className="mt-1">{value}</span>
      </div>
    );
  };

  return (
    <div className="relative w-full mt-2" ref={searchRef}>
      <div className="flex w-full">
        <div className="w-full">
          <div className="relative flex items-center w-full text-black">
            <input
              autoFocus={true}
              type="text"
              name="keyword"
              value={!readOnly ? shownText : ""}
              readOnly={readOnly}
              onChange={handleKeywordChange}
              placeholder="Brand,model, reference number"
              className={`w-full h-12 pl-4 py-2 border-2 rounded focus:outline-black focus:border-black active:outline-black 
                ${ readOnly
                ? "border-lightGray bg-lightGray text-gray-500  cursor-not-allowed"
                : "border-borderGray hover:border-black hover:border-black focus:outline-black focus:border-black active:outline-black"
            }`}
              onFocus={() => {
                if (keyword.length > 2) {
                  setShowSearchResults(true);
                }
              }} // Show search results again on focus
            ></input>
            <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
              <MdOutlineSearch className="h-5 w-5 text-gray" />
            </div>
          </div>
        </div>
      </div>


      {showSearchResults ? (
        <div className="absolute top-12 flex w-full justify-between text-black z-10">
          <div className="flex w-full max-h-476 justify-start rounded bg-white shadow-md p-2 overflow-auto">
            {reqStatus.loading ? (
              <Loader mess="Requesting watches..." />
            ) : reqStatus.success ? (
              watches.length > 0 ? (
                <ul className="w-full">
                  {watches.map((item, index) => (
                    <li key={index} className="w-full hover:bg-blue-50">
                      <button
                        onClick={()=>handleSelect(item)}
                        className={`w-full flex py-4 border-b border-borderGray cursor-pointer`}
                      >
                        <div className="relative w-20 h-20 mr-4">
                          <img
                            src={
                              item.watchImageUri
                                ? S3_DOMAIN + item.watchImageUri
                                : "/default-watch.svg"
                            }
                            alt={"watch image " + item.watchImageUri}
                            className="w-full h-full object-contain"
                            style={{ maxWidth: "100%", maxHeight: "100%" }}
                          ></img>
                        </div>

                        <div className="w-full">
                          <div className="w-full text-left">
                            <span className="text-lg font-medium mr-2">
                              <HightlightKeywords keywords={keyword} text={item.brandName} />
                             
                            </span>
                            <span> <HightlightKeywords keywords={keyword} text={item.modelName} /></span>
                          </div>
                          <p className="text-left mt-1"><HightlightKeywords keywords={keyword} text={item.referenceNumber} /></p>
                          <div className="w-full mt-4 flex">
                            {item.dialColor && (
                              <ViewItem
                                label="Dial Color"
                                value={item.dialColor}
                              />
                            )}
                            {item.caseDiameterWidth && (
                              <ViewItem
                                label="Case Diameter"
                                value={item.caseDiameterWidth}
                              />
                            )}
                            {item.caseMaterial && (
                              <ViewItem
                                label="Case Material"
                                value={item.caseMaterial}
                              />
                            )}
                          </div>
                        </div>
                      </button>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>There is no results</p>
              )
            ) : reqStatus.error ? (
              <ErrorMessage mess={reqStatus.error} />
            ) : (
              <></>
            )}
          </div>
          <div className="hidden lg:w-1/4 lg:ml-2"></div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
