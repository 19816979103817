
import axios from "axios";
import { authMiddleware } from "../redux/actions/authMiddleware";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;


export const emailRequestList = async (page_num, page_size, config, dispatch) => {
  try {
    const response = await axios.get(SERVER_URL + `/admin/marketing-email/task/list?page_num=${page_num}&page_size=${page_size}`, config);
    authMiddleware(response, dispatch);
    return response;
  } catch (error) {
    throw error;
  }
};

export const addNewEmailTask = async (values, config, dispatch) => {

  try {
    const response = await axios.post(SERVER_URL + `/admin/marketing-email/task/new`, values, config);
    authMiddleware(response, dispatch);
    return response;
  } catch (error) {
    throw error;
  }
};
export const emailRequestDetails = async (requestId, config, dispatch) => {
  try {
    const response = await axios.get(SERVER_URL + `/admin/marketing-email/task/details/${requestId}`, config);
    authMiddleware(response, dispatch);
    return response;
  } catch (error) {
    throw error;
  }
};

export const editEmailTaskRequest = async (id, values, config, dispatch) => {

  try {
    const response = await axios.post(SERVER_URL + `/admin/marketing-email/task/edit/${id}`, values, config);
    authMiddleware(response, dispatch);
    return response;
  } catch (error) {
    throw error;
  }

};

export const deleteEmailTaskById = async (id, config, dispatch) => {
  try {
    const response = await axios.post(SERVER_URL + `/admin/marketing-email/task/delete/${id}`, {}, config);
    authMiddleware(response, dispatch);
    return response;
  } catch (error) {
    throw error;
  }
};
export const emailTaskProductList = async (requestId, config, dispatch) => {
  try {
    const response = await axios.get(SERVER_URL + `/admin/marketing-email/task/product-list/${requestId}`, config);
    authMiddleware(response, dispatch);
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteProductFromTaskRequest = async (requestId, values, config, dispatch) => {
  try {
    const response = await axios.post(SERVER_URL + `/admin/marketing-email/task/products/delete/${requestId}`, values, config);
    authMiddleware(response, dispatch);
    return response;
  } catch (error) {
    throw error;
  }
};

export const addProductToTaskRequest = async (requestId, values, config, dispatch) => {
  try {
    const response = await axios.post(SERVER_URL + `/admin/marketing-email/task/add-product/${requestId}`, values, config);
    authMiddleware(response, dispatch);
    return response;
  } catch (error) {
    throw error;
  }
};

export const testEmailRequest = async (requestId, values, config, dispatch) => {
  try {
    const response = await axios.post(SERVER_URL + `/admin/marketing-email/task/test-email/${requestId}`, values, config);
    authMiddleware(response, dispatch);
    return response;
  } catch (error) {
    throw error;
  }
};






