import Breadcrumbs from "@mui/material/Breadcrumbs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import {
  collectionProductList,
  deleteProductFromCollectionRequest
} from "../../../api/webContentRequets";
import Pagination from "../../../components/Pagination";
import ScreenTitle from "../../../components/ScreenTitle";
import { DeleteButton, TextLinkButton } from "../../../components/buttons";
import { ProductTypeTag } from "../../../components/productComponents";
import { ErrorMessage, Loader, ProductName, ProductNameLink, ProductStatusTag } from "../../../components/smallComponents";
import commonStyle from "../../../style/commonStyle.module.css";
import useDocumentTitle from "../../../useDocumentTitle";


export default function ManageCollectionProducts() {
  useDocumentTitle("Manage Collection Products");
  const dispatch = useDispatch();

  const { id: collectionId } = useParams();

  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  const pageSize = 20;
  const [pageNum, setPageNum] = useState(0);
  const [reqStatus, setReqStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const [deleteStatus, setDeleteStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const [deleteCount, setDeleteCount] = useState(1);

  const [products, setProducts] = useState([]);

  useEffect(() => {
    const getCollectionProductList = async () => {
      try {
        setReqStatus((preState) => ({
          ...preState,
          loading: true,
        }));

        const responseData = await collectionProductList(
          collectionId,
          pageNum,
          pageSize,
          config,
          dispatch
        );
        if (responseData.data.success) {
          setReqStatus((preState) => ({
            ...preState,
            loading: false,
            success: true,
          }));
          setProducts(responseData.data.data);
        } else {
          throw responseData.data.message;
        }
      } catch (error) {
        setReqStatus((preState) => ({
          ...preState,
          loading: false,
          error: error,
        }));
      }
    };

    getCollectionProductList();
  }, [dispatch, pageNum, deleteCount]);


  const onPageChangeRequest = (page) => {
    setPageNum(page.selected);
  };

  const deleteProduct = async (productId) => {
    try {
      setDeleteStatus((preState) => ({
        ...preState,
        loading: true,
      }));
      let delete_values = {
        productId: productId
      }

      const responseData = await deleteProductFromCollectionRequest(
        collectionId,
        delete_values,
        config,
        dispatch
      );
      if (responseData.data.success) {
        setDeleteCount(deleteCount + 1);
        setDeleteStatus((preState) => ({
          ...preState,
          loading: false,
          success: true,
        }));
      } else {
        throw responseData.data.message;
      }
    } catch (error) {
      setDeleteStatus((preState) => ({
        ...preState,
        loading: false,
        error: error,
      }));
    }
  };

  return (
    <div className={commonStyle.pageContainer}>
      <div className="w-full p-8">
        <Breadcrumbs aria-label="breadcrumb">
          <NavLink underline="hover" color="inherit" to="/">
            Home
          </NavLink>
          <NavLink
            underline="hover"
            color="inherit"
            to="/web-content/collections"
          >
            Collections
          </NavLink>
          <NavLink
            underline="hover"
            color="text.primary"
            to={`/web-content/collections/manage-products/${collectionId}`}
            aria-current="page"
          >
            Manage Collection Products
          </NavLink>
        </Breadcrumbs>
        <ScreenTitle title="Manage Collection Products" />

        {reqStatus.loading ? (
          <Loader mess="Requesting collection products..." />
        ) : reqStatus.success ? (
          <div className="mt-6">
          <div className={commonStyle.longDataTable}>
            <table className={commonStyle.pageTable}>
              <thead>
                <tr>
                  <th>Collection</th>
                  <th>Product Type</th>
                  <th>Product</th>
                  <th>Seller</th>
                  <th>Status</th>
                  <th>View Details</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {products.products.length > 0 ? (
                  products.products.map((item, index) => (
                    <tr key={index}>
                        <td><div className="w-fit text-sm bg-brandYellow rounded-full text-white px-2 py-1">{item.collectionName}</div></td>
                      <td className="w-[160px]">
                        {" "}
                        <ProductTypeTag type={item.type} />
                      </td>
                      <td>
                        <ProductNameLink
                          productId={item.id}
                          image={item.imageUris.length ? item.imageUris[0] : ""}
                          brandName={item.brandName}
                          modelName={item.modelName}
                          referenceNumber={item.referenceNumber}
                          price={item.price}
                        />
                      </td>

                      <td>
                        <div>
                          <p>
                            {item.type === "SELLERPRODUCT"
                              ? item.sellerName
                              : "WatchSpace"}
                          </p>
                          {item.type === "SELLERPRODUCT" &&
                          item.create_by_admin_id > 0 ? (
                            <p className="text-xs mt-2 w-fit text-white bg-brandColor rounded-full px-3 py-1">
                              WatchSpace Added
                            </p>
                          ) : (
                            <></>
                          )}
                        </div>
                      </td>

                      <td>
                        <ProductStatusTag currState={item.status} />
                      </td>
                      <td>
                        <TextLinkButton
                          name="View Details"
                          path={`/products/details/${item.id}`}
                        />

                    

                      </td>
                      <td>
                        <div className="flex flex-row items-center">
                          <DeleteButton action={() => deleteProduct(item.id)} />
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>
                      There are no product in this collection. Please add product to collection from the product list page.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {products.products.length > 0 ? (
              <Pagination
                totalPageNum={products.totalPages}
                forcePage={pageNum}
                onPageChange={onPageChangeRequest}
              />
            ) : (
              <></>
            )}
          </div>
          </div>
        ) : reqStatus.error ? (
          <ErrorMessage mess={reqStatus.error} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
