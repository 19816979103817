import Breadcrumbs from "@mui/material/Breadcrumbs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { convToLocalTime } from "../../api/functions";
import {
  deleteEmailTaskById,
  emailRequestList,
} from "../../api/marketingEmailTaksRequets";
import Pagination from "../../components/Pagination";
import ScreenTitle from "../../components/ScreenTitle";
import {
  DeleteButton,
  EditLinkButton,
  LinkButton,
} from "../../components/buttons";
import { DeleteDialog, TestEmailDialog } from "../../components/dialogs";
import { ErrorMessage, Loader, Tag } from "../../components/smallComponents";
import commonStyle from "../../style/commonStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle";

export default function TaskList() {
  useDocumentTitle("Tasks");
  const dispatch = useDispatch();

  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  const pageSize = 20;
  const [pageNum, setPageNum] = useState(0);
  const [reqStatus, setReqStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const [deleteStatus, setDeleteStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const [tasks, setTasks] = useState({});
  const [updateCount, setUpdateCount] = useState(1);
  // delete
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteId, setDeleteId] = useState();
  // test email
  const [testDialog, setTestDialog] = useState(false);
  const [testId, setTestId] = useState(0);

  const onPageChangeRequest = (page) => {
    setPageNum(page.selected);
  };

  useEffect(() => {
    const getTaskList = async () => {
      try {
        setReqStatus((preState) => ({
          ...preState,
          loading: true,
        }));

        const responseData = await emailRequestList(
          pageNum,
          pageSize,
          config,
          dispatch
        );
        if (responseData.data.success) {
          setReqStatus((preState) => ({
            ...preState,
            loading: false,
            success: true,
          }));
          setTasks(responseData.data.data);
        } else {
          throw responseData.data.message;
        }
      } catch (error) {
        setReqStatus((preState) => ({
          ...preState,
          loading: false,
          error: error,
        }));
      }
    };

    getTaskList();
  }, [dispatch, pageNum, updateCount]);

  const deleteTaskAction = (taskId) => {
    setDeleteId(taskId);
    setDeleteDialog(true);
  };

  const sendDeleteRequest = async () => {
    //dispatch(deleteCustomerRequest(deleteId));
    try {
      setDeleteStatus((preState) => ({
        ...preState,
        loading: true,
      }));

      const responseData = await deleteEmailTaskById(
        deleteId,
        config,
        dispatch
      );
      if (responseData.data.success) {
        setUpdateCount(updateCount + 1);
        setDeleteStatus((preState) => ({
          ...preState,
          loading: false,
          success: true,
        }));
      } else {
        throw responseData.data.message;
      }
    } catch (error) {
      setDeleteStatus((preState) => ({
        ...preState,
        loading: false,
        error: error,
      }));
    }
  };

  const openTestModal = (request_id) => {
    setTestId(request_id);
    setTestDialog(true);
  };
  const successActionDone = () => {
    setUpdateCount(updateCount + 1);
    setTestDialog(false);
  };

  return (
    <div className={commonStyle.pageContainer}>
      <DeleteDialog
        title="Delete Email Task"
        preDeleteMess="Deleting this email task can not be undone. Are you sure you want to proceed?"
        successMessage="Email task deleted successfully."
        isDialogOpen={deleteDialog}
        closeDialog={() => setDeleteDialog(false)}
        closeDialogWithSuccess={() => setDeleteDialog(false)}
        loading={deleteStatus.loading}
        success={deleteStatus.success}
        error={deleteStatus.error}
        loadingMessage="Processing request..."
        deleteAction={sendDeleteRequest}
      />
      {testDialog && (
        <TestEmailDialog
          modalOpen={testDialog}
          modalTitle={"Send Test Email"}
          modalButtonText={"Send"}
          successActionText={"Test email sent successfully"}
          successActionDone={successActionDone}
          onClose={() => setTestDialog(false)}
          requestId={testId}
        />
      )}

      <div className="w-full p-8">
        <Breadcrumbs aria-label="breadcrumb">
          <NavLink underline="hover" color="inherit" to="/">
            Home
          </NavLink>
          <NavLink
            underline="hover"
            color="inherit"
            to="/marketing-email/tasks"
          >
            Email Tasks
          </NavLink>
        </Breadcrumbs>
        <ScreenTitle title="Email Tasks" />

        {reqStatus.loading ? (
          <Loader mess="Requesting collections..." />
        ) : reqStatus.success ? (
          <div className={commonStyle.longDataTable}>
            <div className="w-full flex justify-end">
              <LinkButton
                path="/marketing-email/tasks/new"
                name="Add New Email Task"
                width="fit"
              />
            </div>
            <table className={commonStyle.pageTable}>
              <thead>
                <tr>
                  <th>Subject</th>
                  <th>Scheduled Time</th>
                  <th>Status</th>
                  <th>Products Type</th>
                  <th>Test</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {tasks.tasks.length > 0 ? (
                  tasks.tasks.map((item, index) => (
                    <tr key={index}>
                      <td>{item.subject}</td>
                     
                      <td>{convToLocalTime(item.startTime)}</td>
                      <td>
                        {item.status === "Scheduled" ? (
                          <Tag color={"green"} name={item.status} />
                        ) : item.status === "Sent" ? (
                          <Tag color={"blue"} name={item.status} />
                        ) : (
                          <Tag color={"gray"} name={item.status} />
                        )}
                      </td>
                      <td className="py-4">
                        <div className="flex flex-col items-start">
                          <p className="bg-brandColor border text-xs px-2 py-1 rounded-full text-white mb-1">
                            {item.productsType}
                          </p>
                          <NavLink
                            to={`/marketing-email/tasks/manage-products/${item.id}`}
                            className={` text-blue-500 hover:cursor-pointer hover:underline`}
                          >
                            Manage Products
                          </NavLink>
                        </div>
                      </td>
                      <td>
                        <button
                          className={` text-blue-500 hover:cursor-pointer hover:underline`}
                          onClick={() => openTestModal(item.id)}
                        >
                          Send Test Email
                        </button>
                      </td>

                      <td>
                        {item.status === "Scheduled" && (
                          <div className="flex flex-row items-center">
                            <EditLinkButton
                              path={`/marketing-email/tasks/edit/${item.id}`}
                            />
                            <DeleteButton
                              action={() => deleteTaskAction(item.id)}
                            />
                          </div>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>
                      There are no task available. Please add a new one now.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {tasks.tasks.length > 0 ? (
              <Pagination
                totalPageNum={tasks.totalPages}
                forcePage={pageNum}
                onPageChange={onPageChangeRequest}
              />
            ) : (
              <></>
            )}
          </div>
        ) : reqStatus.error ? (
          <ErrorMessage mess={reqStatus.error} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
