import React, { useEffect, useRef, useState } from "react";
import { FaRegFileAlt } from "react-icons/fa";
import { GoCheck } from "react-icons/go";
import ReactModal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { sendMessageRequest } from "../api/messageRequests";
import {
  adminCancelOrderRequest,
  adminUpdateOrderPriceRequest,
  orderActionRequest,
  updateAdminNotesRequest,
} from "../api/orderRequests";
import { K_COURIER_COMPANYS } from "../constantData/K_COURIER_COMPANYS";
import commonStyle from "../style/commonStyle.module.css";
import FilesUploader from "./FilesUploader";
import {
  Button,
  CloseButton,
  LinkButton,
  LoadingButton,
  SubmitButton,
} from "./buttons";
import { FieldLabel, SelectField, TextAreaField, TextField } from "./inputs";
import { ErrorMessage, H2, Loader } from "./smallComponents";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { addProductToCollection } from "../api/webContentRequets";
import { testEmailRequest } from "../api/marketingEmailTaksRequets";
import { MdRadioButtonChecked, MdRadioButtonUnchecked } from "react-icons/md";
import { convtPrice } from "../api/functions";

const S3_DOMAIN = process.env.REACT_APP_S3_DOMAIN;

function SimpleDialog({
  title,
  successMessage,
  isDialogOpen,
  closeDialog,
  loading,
  success,
  error,
  loadingMessage,
  btnValue,
  confirmAction,
}) {
  return (
    <div>
      <ReactModal
        isOpen={isDialogOpen}
        appElement={document.getElementById("root")}
        className={commonStyle.inputModal}
        overlayClassName={commonStyle.overlay}
        shouldFocusAfterRender={false}
        onRequestClose={success ? confirmAction : closeDialog}
        shouldCloseOnOverlayClick={true}
      >
        <div className={commonStyle.dialogContentContainer}>
          <div className={commonStyle.dialogTitle}>
            <h3>{title}</h3>
          </div>
          {loading ? (
            <Loader mess={loadingMessage} />
          ) : success ? (
            <>
              <p className={commonStyle.dialogSuccessMessage}>
                {successMessage}
              </p>
              <div
                className={commonStyle.confirmBtnCon}
                onClick={confirmAction}
              >
                <div className={commonStyle.confirmBtn}>OK</div>
              </div>
            </>
          ) : (
            <div>
              <p style={{ color: "red" }}>{error}</p>
              <div className={commonStyle.confirmBtnCon} onClick={closeDialog}>
                <div className={commonStyle.confirmBtn}>OK</div>
              </div>
            </div>
          )}
        </div>
      </ReactModal>
    </div>
  );
}

function OrderActionDialog({
  orderType,
  modalOpen,
  actionName,
  modalTitle,
  modalText,
  successActionText,
  modalButtonText,
  orderId,
  onClose,
  successActionDone,
}) {
  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo ? userInfo.data.token : ""}`,
    },
  };

  const [reqStatus, setReqStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const modalAction = async () => {
    try {
      setReqStatus({ ...reqStatus, loading: true });

      const responseData = await orderActionRequest(
        orderType,
        orderId,
        "platform",
        actionName,
        "",
        config
      );

      if (responseData.data.success) {
        setReqStatus({ ...reqStatus, loading: false, success: true });
      } else {
        throw responseData.data.message;
      }
    } catch (error) {
      setReqStatus({ ...reqStatus, loading: false, error: error });
    }
  };

  const closeModal = () => {
    if (reqStatus.success) {
      successActionDone();
    }
    onClose();
  };

  return (
    <div>
      <ReactModal
        isOpen={modalOpen}
        appElement={document.getElementById("root")}
        className={commonStyle.inputModal}
        overlayClassName={commonStyle.overlay}
        shouldFocusAfterRender={false}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={true}
      >
        <div className={commonStyle.dialogContentContainer}>
          <div className="w-full">
            <div className="flex justify-end">
              <CloseButton action={closeModal} />
            </div>
            {reqStatus.success ? (
              <div>
                <div className="w-full mt-10 lg:mt-8 flex items-center flex-col">
                  <h2>{modalTitle}</h2>

                  <div className="w-full flex items-center justify-center">
                    <div className="w-20 h-20 rounded-full border border-solid border-brandYellow flex items-center justify-center mt-4">
                      <GoCheck className="w-10 h-10 text-brandYellow" />
                    </div>
                  </div>
                  <p className="my-6">{successActionText}</p>
                  <Button name="OK" action={closeModal} />
                </div>
              </div>
            ) : (
              <div>
                <div className="w-full mt-10 lg:mt-8 flex items-center flex-col">
                  <h2>{modalTitle}</h2>

                  <p className="my-6">{modalText}</p>
                  {reqStatus.error && <ErrorMessage mess={reqStatus.error} />}
                  <div className="mt-8">
                    {reqStatus.loading ? (
                      <LoadingButton name="Proceeding..." />
                    ) : (
                      <Button name={modalButtonText} action={modalAction} />
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </ReactModal>
    </div>
  );
}

function OrderActionWithFilesDialog({
  orderType,
  modalOpen,
  modalTitle,
  modalText,
  successActionText,
  orderId,
  userType,
  actionName,
  onClose,
  modalButtonText,
  successActionDone,
}) {
  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo ? userInfo.data.token : ""}`,
    },
  };

  const [reqStatus, setReqStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const [formState, setFormState] = useState({ notes: "", files: [] });
  const [formError, setFormError] = useState("");

  const getUploadedImages = (uploadedFiles) => {
    setFormState({ ...formState, ["files"]: uploadedFiles });
  };
  //////////////////////////////////////////////////////////////////////////

  const handleChange = (value) => {
    setFormState({ ...formState, ["notes"]: value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    let fileUris = [];

    if (formState.files.length) {
      formState.files.forEach((elem) => {
        if (elem.isLoading) {
          setFormError("File still uploading, please wait a moment.");
        }
        if (elem.uri) {
          fileUris.push({ fileName: elem.fileName, uri: elem.uri });
        }
      });

      if (fileUris.length) {
        let actionInfo = { files: fileUris, notes: formState.notes };
        let stringValues = JSON.stringify(actionInfo);
        proceedAction(stringValues);
      }
    } else {
      setFormError("Please upload a file");
    }
  };

  const proceedAction = async (values) => {
    try {
      setReqStatus({ ...reqStatus, loading: true });

      const responseData = await orderActionRequest(
        orderType,
        orderId,
        userType,
        actionName,
        values,
        config
      );

      if (responseData.data.success) {
        setReqStatus({ ...reqStatus, loading: false, success: true });
      } else {
        throw responseData.data.message;
      }
    } catch (error) {
      setReqStatus({ ...reqStatus, loading: false, error: error });
    }
  };

  const closeModal = () => {
    if (reqStatus.success) {
      successActionDone();
    }
    onClose();
  };

  return (
    <div>
      <ReactModal
        isOpen={modalOpen}
        appElement={document.getElementById("root")}
        className={commonStyle.inputModal}
        overlayClassName={commonStyle.overlay}
        shouldFocusAfterRender={false}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={true}
      >
        <div className={commonStyle.dialogContentContainer}>
          <div className="w-full">
            <div className="flex justify-end">
              <CloseButton action={closeModal} />
            </div>
            {reqStatus.success ? (
              <div>
                <div className="w-full mt-10 lg:mt-8 flex items-center flex-col">
                  <h2>{modalTitle}</h2>

                  <div className="w-full flex items-center justify-center">
                    <div className="w-20 h-20 rounded-full border border-solid border-brandYellow flex items-center justify-center mt-4">
                      <GoCheck className="w-10 h-10 text-brandYellow" />
                    </div>
                  </div>
                  <p className="mt-6 text-base font-semibold mb-6">
                    {successActionText}
                  </p>

                  <Button name="OK" action={closeModal} />
                </div>
              </div>
            ) : (
              <div>
                <div className="w-full mt-10 lg:mt-8 flex items-center flex-col">
                  <h2>{modalTitle}</h2>
                  {formError && (
                    <div className="my-4">
                      <ErrorMessage mess={formError} />{" "}
                    </div>
                  )}

                  <form
                    className="w-full mt-10 lg:mt-8"
                    onSubmit={handleSubmit}
                  >
                    <FilesUploader
                      label={modalText}
                      initialFiles={[]}
                      onUpload={getUploadedImages}
                    />
                    <TextAreaField
                      type="text"
                      label="Notes"
                      name="notes"
                      value={formState.notes}
                      rows={2}
                      placeholder="Your notes..."
                      onChange={(notes) => handleChange(notes)}
                    />

                    <div className="w-fit mt-6">
                      {reqStatus.error && (
                        <ErrorMessage mess={reqStatus.error} />
                      )}
                      {reqStatus.loading ? (
                        <LoadingButton name={"Proceeding..."} />
                      ) : (
                        <SubmitButton name={modalButtonText} />
                      )}
                    </div>
                  </form>
                </div>
              </div>
            )}
          </div>
        </div>
      </ReactModal>
    </div>
  );
}

function SendMessageDialog({
  modalOpen,
  recipientId,
  productId,
  refType,
  onClose,
}) {
  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo ? userInfo.data.token : ""}`,
    },
  };

  const [reqStatus, setReqStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const [newDialogId, setNewDialogId] = useState("");
  const [message, setMessage] = useState("");

  //////////////////////////////////////////////////////////////////////////

  const sendMessageAction = (e) => {
    e.preventDefault();

    let submitedValues = {
      recipientId: recipientId,
      productId: productId,
      message: message,
      refType: refType,
    };
    sendMessage(submitedValues);
  };

  const sendMessage = async (values) => {
    try {
      setReqStatus({ ...reqStatus, loading: true });

      const responseData = await sendMessageRequest(values, config);

      if (responseData.data.success) {
        setReqStatus({ ...reqStatus, loading: false, success: true });
        setNewDialogId(responseData.data.data);
      } else {
        throw responseData.data.message;
      }
    } catch (error) {
      setReqStatus({ ...reqStatus, loading: false, error: error });
    }
  };

  const closeModal = () => {
    onClose();
  };

  return (
    <div>
      <ReactModal
        isOpen={modalOpen}
        appElement={document.getElementById("root")}
        className={commonStyle.inputModal}
        overlayClassName={commonStyle.overlay}
        shouldFocusAfterRender={false}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={true}
      >
        <div className={commonStyle.dialogContentContainer}>
          <div className="w-full">
            <div className="flex justify-end">
              <CloseButton action={closeModal} />
            </div>
            {reqStatus.success ? (
              <div>
                <div className="w-full mt-10 lg:mt-8 flex items-center flex-col">
                  <h2>Send Message</h2>

                  <div className="w-full flex items-center justify-center">
                    <div className="w-20 h-20 rounded-full border border-solid border-brandYellow flex items-center justify-center mt-4">
                      <GoCheck className="w-10 h-10 text-brandYellow" />
                    </div>
                  </div>
                  <p className="mt-6 text-base font-semibold mb-6">
                    Message sent successfully.
                  </p>

                  <LinkButton
                    path={`/messages/details/${newDialogId}`}
                    name="View My Message"
                  />
                </div>
              </div>
            ) : (
              <div>
                <div className="w-full mt-10 lg:mt-8 flex items-center flex-col">
                  <h2>Send Message</h2>

                  <form
                    className="w-full mt-10 lg:mt-8"
                    onSubmit={sendMessageAction}
                  >
                    <TextAreaField
                      type="text"
                      label="Message"
                      name="message"
                      value={message}
                      rows={6}
                      placeholder="Your message..."
                      onChange={(message) => setMessage(message)}
                    />
                    <div className="w-fit mt-6">
                      {reqStatus.error && (
                        <ErrorMessage mess={reqStatus.error} />
                      )}
                      {reqStatus.loading ? (
                        <LoadingButton name="Sending Message" />
                      ) : (
                        <SubmitButton name={"Send Message"} />
                      )}
                    </div>
                  </form>
                </div>
              </div>
            )}
          </div>
        </div>
      </ReactModal>
    </div>
  );
}

function ShipOrderDialog({
  orderType,
  modalOpen,
  modalTitle,
  successActionText,
  orderId,
  userType,
  actionName,
  onClose,
  modalText,
  successActionDone,
  modalButtonText,
}) {
  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo ? userInfo.data.token : ""}`,
    },
  };

  const [reqStatus, setReqStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const [formState, setFormState] = useState({
    courier: "",
    trackingNumber: "",
    courierName: "",
  });
  const [formErrors, setFormErrors] = useState({});

  const handleChange = (e) => {
    const { value, name } = e.target;
    setFormState({ ...formState, [name]: value });
  };

  const validate = (values) => {
    let errors = {};
    if (!values.courier) {
      errors.courier = "Courier is required.";
    }

    if (!values.trackingNumber) {
      errors.trackingNumber = "Tracking number is required.";
    }

    if (values.courier && values.courier.label === "Other") {
      if (!values.courierName) {
        errors.courierName = "Courier name is required.";
      }
    }

    return errors;
  };

  const modalAction = async (e) => {
    e.preventDefault();

    const hasErrors = validate(formState);

    if (Object.keys(hasErrors).length === 0) {
      setFormErrors({});
      updateShippingDetails();
    } else {
      setFormErrors(hasErrors);
    }
  };

  const updateShippingDetails = async () => {
    let actionInfo = JSON.stringify(formState);

    try {
      setReqStatus({ ...reqStatus, loading: true });

      const responseData = await orderActionRequest(
        orderType,
        orderId,
        userType,
        actionName,
        actionInfo,
        config
      );

      if (responseData.data.success) {
        setReqStatus({ ...reqStatus, loading: false, success: true });
      } else {
        throw responseData.data.message;
      }
    } catch (error) {
      setReqStatus({ ...reqStatus, loading: false, error: error });
    }
  };

  const closeModal = () => {
    if (reqStatus.success) {
      successActionDone();
    }
    onClose();
  };

  return (
    <div>
      <ReactModal
        isOpen={modalOpen}
        appElement={document.getElementById("root")}
        className={commonStyle.inputModal}
        overlayClassName={commonStyle.overlay}
        shouldFocusAfterRender={false}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={true}
      >
        <div className={commonStyle.dialogContentContainer}>
          <div className="w-full">
            <div className="flex justify-end">
              <CloseButton action={closeModal} />
            </div>
            {reqStatus.success ? (
              <div>
                <div className="w-full mt-10 lg:mt-8 flex items-center flex-col">
                  <h2>{modalTitle}</h2>
                  <div className="w-full flex items-center justify-center">
                    <div className="w-20 h-20 rounded-full border-solid border border-brandYellow flex items-center justify-center mt-4">
                      <GoCheck className="w-10 h-10 text-brandYellow" />
                    </div>
                  </div>
                  <p className="my-6">{successActionText}</p>
                  <Button name="OK" action={closeModal} />
                </div>
              </div>
            ) : (
              <div>
                <div className="w-full mt-10 lg:mt-8 flex items-center flex-col">
                  <h2>{modalTitle}</h2>
                  <form onSubmit={modalAction} className="mt-8 w-full">
                    <p>{modalText}</p>
                    <SelectField
                      label="Courier"
                      placeHolder=""
                      selectOptions={K_COURIER_COMPANYS}
                      value={formState.courier}
                      onChange={(courier) =>
                        handleChange({
                          target: {
                            value: courier,
                            name: "courier",
                          },
                        })
                      }
                      error={formErrors.courier}
                    />
                    {formState.courier &&
                      formState.courier.label === "Other" && (
                        <TextField
                          type="text"
                          label="Courier Company Name"
                          name="courierName"
                          value={formState.courierName}
                          onChange={(courierName) =>
                            handleChange({
                              target: {
                                value: courierName,
                                name: "courierName",
                              },
                            })
                          }
                          error={formErrors.courierName}
                        />
                      )}
                    <TextField
                      type="text"
                      label="Tracking Number"
                      name="tracking number"
                      value={formState.trackingNumber}
                      onChange={(trackingNumber) =>
                        handleChange({
                          target: {
                            value: trackingNumber,
                            name: "trackingNumber",
                          },
                        })
                      }
                      error={formErrors.trackingNumber}
                    />

                    {reqStatus.error && <ErrorMessage mess={reqStatus.error} />}
                    <div className="mt-8">
                      {reqStatus.loading ? (
                        <LoadingButton name="Proceeding..." />
                      ) : (
                        <SubmitButton name={modalButtonText} />
                      )}
                    </div>
                  </form>
                </div>
              </div>
            )}
          </div>
        </div>
      </ReactModal>
    </div>
  );
}

function ImageViewDialog({ imageUri, isDialogOpen, closeDialog }) {
  return (
    <div>
      <ReactModal
        isOpen={isDialogOpen}
        appElement={document.getElementById("root")}
        className={commonStyle.inputModal}
        overlayClassName={commonStyle.overlay}
        shouldFocusAfterRender={false}
        onRequestClose={closeDialog}
        shouldCloseOnOverlayClick={true}
      >
        <div className={commonStyle.dialogContentContainer}>
          <div className="flex justify-end">
            <CloseButton action={closeDialog} />
          </div>

          <div className={commonStyle.dialogTitle}>
            <div className="w-full mt-8">
              <img
                alt={"image preview" + imageUri}
                src={imageUri ? S3_DOMAIN + imageUri : "/default-watch.svg"}
                className="w-full h-full object-contain"
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                }}
              />
            </div>
          </div>
        </div>
      </ReactModal>
    </div>
  );
}

function ImagesPreviewDialog({ images, isDialogOpen, closeDialog }) {
  const containerRef = useRef(null);
  const swiperRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const prevImage = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const nextImage = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const changeImage = (index) => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideTo(index);
    }
    setCurrentIndex(index);
    const selectedItem = document.getElementById(`item-${index}`);
    selectedItem?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "center",
    });
  };

  return (
    <div>
      <ReactModal
        isOpen={isDialogOpen}
        appElement={document.getElementById("root")}
        className={commonStyle.fullScreenModal}
        overlayClassName={commonStyle.overlay}
        shouldFocusAfterRender={false}
        onRequestClose={closeDialog}
        shouldCloseOnOverlayClick={true}
      >
        <div className={commonStyle.dialogContentContainer}>
          <div className="flex justify-end">
            <CloseButton action={closeDialog} />
          </div>

          <div className="w-full lg:h-full mt-4 flex flex-col items-center justify-center">
            <div className="block w-full  max-w-[820px] lg:h-[80%] relative bg-lightGray">
              <Swiper
                ref={swiperRef}
                slidesPerView={1}
                style={{
                  "--swiper-navigation-color": "#fff",
                  "--swiper-pagination-color": "#CBA975",
                  aspectRatio: 1 / 1,
                  height: "100%",
                }}
                spaceBetween={30}
                loop={true}
                pagination={true}
                onActiveIndexChange={(index) =>
                  setCurrentIndex(index.realIndex)
                }
                modules={[Pagination]}
              >
                {images.map((image, index) => (
                  <SwiperSlide key={index}>
                    <img
                      alt={"image preview" + image}
                      src={image ? S3_DOMAIN + image : "/default-watch.svg"}
                      className="w-full h-full object-cover"
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                      }}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
              <button
                onClick={prevImage}
                className="hidden lg:block absolute z-10 left-0 top-1/2 transform -translate-y-1/2 p-2 bg-white rounded-full"
              >
                <IoIosArrowBack className="w-8 h-8 text-gray hover:text-black" />
              </button>
              <button
                onClick={nextImage}
                className="hidden lg:block absolute z-10 right-0 top-1/2 transform -translate-y-1/2 p-2 bg-white rounded-full"
              >
                <IoIosArrowForward className="w-8 h-8 text-gray hover:text-black" />
              </button>
            </div>

            <div className="flex mt-4 h-[20%] overflow-x-auto scrollbar-hide">
              <div ref={containerRef} className="flex space-x-2">
                {images.map((image, index) => (
                  <div
                    className={` relative w-16 h-16 cursor-pointer`}
                    key={index}
                    id={`item-${index}`}
                  >
                    <img
                      alt={"image preview" + image}
                      src={image ? S3_DOMAIN + image : "/default-watch.svg"}
                      className="w-full h-full object-cover"
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                      }}
                    />

                    <div
                      className={`absolute top-0 left-0 w-full h-full bg-white cursor-pointer ${
                        index === currentIndex
                          ? "bg-opacity-0"
                          : "bg-opacity-70"
                      }`}
                      onClick={() => changeImage(index)}
                    ></div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </ReactModal>
    </div>
  );
}

function MakeOfferDialog({
  orderType,
  modalOpen,
  modalTitle,
  successActionText,
  orderId,
  userType = "platform",
  actionName,
  onClose,
  modalText,
  successActionDone,
  modalButtonText,
  needOfferPrice = true,
  needCommision = true,
  needService = false,
  needBuyoutOffer = false,
  oriOfferPrice,
  oriCommision,
  oriServiceFee,
  oriBuyoutOffer,
}) {
  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo ? userInfo.data.token : ""}`,
    },
  };

  const [reqStatus, setReqStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const [formState, setFormState] = useState({
    offerPrice: oriOfferPrice,
    commision: oriCommision,
    serviceFee: oriServiceFee,
    buyoutOffer: oriBuyoutOffer,
    notes: "",
  });
  const [formErrors, setFormErrors] = useState({});

  const handleChange = (e) => {
    const { value, name } = e.target;
    const type = e.type;

    if (type === "FLOAT") {
      const validated = value.match(/^(\d*\.{0,1}\d{0,2}$)/);
      if (!validated) {
        return;
      }
    }

    if (name === "offerPrice") {
      let commisionFee = 0;
      if (needCommision) {
        commisionFee = (Number(value) * 0.07).toFixed(2);
      }

      setFormState({
        ...formState,
        ["commision"]: commisionFee,
        [name]: value,
      });
    } else {
      setFormState({ ...formState, [name]: value });
    }
  };

  const validate = (values) => {
    let errors = {};
    if (needOfferPrice && !values.offerPrice) {
      errors.offerPrice = "Listing price is required.";
    }
    if (needCommision && !values.commision) {
      errors.commision = "Commision amount is required.";
    }
    if (needService && !values.serviceFee) {
      errors.serviceFee = "Service fee is required.";
    }
    if (needBuyoutOffer && !values.buyoutOffer) {
      errors.buyoutOffer = "Buyout price is required.";
    }

    return errors;
  };

  const modalAction = async (e) => {
    e.preventDefault();

    const hasErrors = validate(formState);

    if (Object.keys(hasErrors).length === 0) {
      setFormErrors({});
      updateOffer();
    } else {
      setFormErrors(hasErrors);
    }
  };

  const updateOffer = async () => {
    let actionInfo = JSON.stringify(formState);

    try {
      setReqStatus({ ...reqStatus, loading: true });

      const responseData = await orderActionRequest(
        orderType,
        orderId,
        userType,
        actionName,
        actionInfo,
        config
      );

      if (responseData.data.success) {
        setReqStatus({ ...reqStatus, loading: false, success: true });
      } else {
        throw responseData.data.message;
      }
    } catch (error) {
      setReqStatus({ ...reqStatus, loading: false, error: error });
    }
  };

  const closeModal = () => {
    if (reqStatus.success) {
      successActionDone();
    }
    onClose();
  };

  return (
    <div>
      <ReactModal
        isOpen={modalOpen}
        appElement={document.getElementById("root")}
        className={commonStyle.inputModal}
        overlayClassName={commonStyle.overlay}
        shouldFocusAfterRender={false}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={true}
      >
        <div className={commonStyle.dialogContentContainer}>
          <div className="w-full">
            <div className="flex justify-end">
              <CloseButton action={closeModal} />
            </div>
            {reqStatus.success ? (
              <div>
                <div className="w-full mt-10 lg:mt-8 flex items-center flex-col">
                  <h2>{modalTitle}</h2>
                  <div className="w-full flex items-center justify-center">
                    <div className="w-20 h-20 rounded-full border-solid border border-brandYellow flex items-center justify-center mt-4">
                      <GoCheck className="w-10 h-10 text-brandYellow" />
                    </div>
                  </div>
                  <p className="my-6">{successActionText}</p>
                  <Button name="OK" action={closeModal} />
                </div>
              </div>
            ) : (
              <div>
                <div className="w-full mt-10 lg:mt-8 flex items-center flex-col">
                  <h2>{modalTitle}</h2>
                  <form onSubmit={modalAction} className="mt-8 w-full">
                    <p>{modalText}</p>

                    {needOfferPrice && (
                      <TextField
                        type="text"
                        label="Listing Price*"
                        name="offerPrice"
                        value={formState.offerPrice}
                        onChange={(offerPrice) =>
                          handleChange({
                            type: "FLOAT",
                            target: {
                              value: offerPrice,
                              name: "offerPrice",
                            },
                          })
                        }
                        error={formErrors.offerPrice}
                      />
                    )}
                    {needCommision && (
                      <>
                        <TextField
                          type="text"
                          label="Commision(7%)*"
                          name="commision"
                          value={formState.commision}
                          onChange={(commision) =>
                            handleChange({
                              type: "FLOAT",
                              target: {
                                value: commision,
                                name: "commision",
                              },
                            })
                          }
                          error={formErrors.commision}
                        />
                      </>
                    )}

                    {needService && (
                      <TextField
                        type="text"
                        label="Service Fee*"
                        name="serviceFee"
                        value={formState.serviceFee}
                        onChange={(serviceFee) =>
                          handleChange({
                            type: "FLOAT",
                            target: {
                              value: serviceFee,
                              name: "serviceFee",
                            },
                          })
                        }
                        error={formErrors.serviceFee}
                      />
                    )}
                    {orderType === "consignment" && (
                      <p className="mt-4 text-green-500">
                        Customer Earnings: $
                        {Number(formState.offerPrice) -
                          Number(formState.commision) -
                          Number(formState.serviceFee)}
                      </p>
                    )}

                    {needBuyoutOffer && (
                      <TextField
                        type="text"
                        label="Buyout Price*"
                        name="buyoutPrice"
                        value={formState.buyoutOffer}
                        onChange={(buyoutOffer) =>
                          handleChange({
                            type: "FLOAT",
                            target: {
                              value: buyoutOffer,
                              name: "buyoutOffer",
                            },
                          })
                        }
                        error={formErrors.buyoutOffer}
                      />
                    )}

                    <TextField
                      type="text"
                      label="Notes"
                      name="notes"
                      value={formState.notes}
                      onChange={(notes) =>
                        handleChange({
                          type: "TEXT",
                          target: {
                            value: notes,
                            name: "notes",
                          },
                        })
                      }
                      error={formErrors.notes}
                    />

                    {reqStatus.error && <ErrorMessage mess={reqStatus.error} />}
                    <div className="mt-8">
                      {reqStatus.loading ? (
                        <LoadingButton name="Proceeding..." />
                      ) : (
                        <SubmitButton name={modalButtonText} />
                      )}
                    </div>
                  </form>
                </div>
              </div>
            )}
          </div>
        </div>
      </ReactModal>
    </div>
  );
}

function OrderActionWithNotesDialog({
  orderType,
  modalOpen,
  modalTitle,
  successActionText,
  orderId,
  actionName,
  onClose,
  modalText,
  successActionDone,
  modalButtonText,
}) {
  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo ? userInfo.data.token : ""}`,
    },
  };

  const [reqStatus, setReqStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const [formState, setFormState] = useState({
    notes: "",
  });
  const [formErrors, setFormErrors] = useState({});

  const handleChange = (e) => {
    const { value, name } = e.target;
    setFormState({ ...formState, [name]: value });
  };

  const modalAction = async (e) => {
    e.preventDefault();
    updateAction();
  };

  const updateAction = async () => {
    let actionInfo = JSON.stringify(formState);

    try {
      setReqStatus({ ...reqStatus, loading: true });

      const responseData = await orderActionRequest(
        orderType,
        orderId,
        "platform",
        actionName,
        actionInfo,
        config
      );

      if (responseData.data.success) {
        setReqStatus({ ...reqStatus, loading: false, success: true });
      } else {
        throw responseData.data.message;
      }
    } catch (error) {
      setReqStatus({ ...reqStatus, loading: false, error: error });
    }
  };

  const closeModal = () => {
    if (reqStatus.success) {
      successActionDone();
    }
    onClose();
  };

  return (
    <div>
      <ReactModal
        isOpen={modalOpen}
        appElement={document.getElementById("root")}
        className={commonStyle.inputModal}
        overlayClassName={commonStyle.overlay}
        shouldFocusAfterRender={false}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={true}
      >
        <div className={commonStyle.dialogContentContainer}>
          <div className="w-full">
            <div className="flex justify-end">
              <CloseButton action={closeModal} />
            </div>
            {reqStatus.success ? (
              <div>
                <div className="w-full mt-10 lg:mt-8 flex items-center flex-col">
                  <h2>{modalTitle}</h2>
                  <div className="w-full flex items-center justify-center">
                    <div className="w-20 h-20 rounded-full border border-solid border-brandYellow flex items-center justify-center mt-4">
                      <GoCheck className="w-10 h-10 text-brandYellow" />
                    </div>
                  </div>
                  <p className="my-6">{successActionText}</p>
                  <Button name="OK" action={closeModal} />
                </div>
              </div>
            ) : (
              <div>
                <div className="w-full mt-10 lg:mt-8 flex items-center flex-col">
                  <h2>{modalTitle}</h2>
                  <form onSubmit={modalAction} className="mt-8 w-full">
                    <p>{modalText}</p>

                    <TextField
                      type="text"
                      label="Notes"
                      name="notes"
                      value={formState.notes}
                      onChange={(notes) =>
                        handleChange({
                          target: {
                            value: notes,
                            name: "notes",
                          },
                        })
                      }
                      error={formErrors.notes}
                    />

                    {reqStatus.error && <ErrorMessage mess={reqStatus.error} />}
                    <div className="mt-8">
                      {reqStatus.loading ? (
                        <LoadingButton name="Proceeding..." />
                      ) : (
                        <SubmitButton name={modalButtonText} />
                      )}
                    </div>
                  </form>
                </div>
              </div>
            )}
          </div>
        </div>
      </ReactModal>
    </div>
  );
}

function ViewFilesDialog({ file, isDialogOpen, closeDialog, modalTitle }) {
  return (
    <div>
      <ReactModal
        isOpen={isDialogOpen}
        appElement={document.getElementById("root")}
        className={commonStyle.inputModal}
        overlayClassName={commonStyle.overlay}
        shouldFocusAfterRender={false}
        onRequestClose={closeDialog}
        shouldCloseOnOverlayClick={true}
      >
        <div className={commonStyle.dialogContentContainer}>
          <div className="flex justify-end">
            <CloseButton action={closeDialog} />
          </div>

          <H2 title={modalTitle} />

          {file.notes && (
            <p className="bg-lightGray rounded p-4 text-brandYellow mt-4">
              {file.notes}
            </p>
          )}

          {file.attachments.length && (
            <div className="mt-4">
              <p>Attachments</p>
              {file.attachments.map((item, index) => (
                <div key={index} className="bg-lightGray rounded px-4 mt-4">
                  <div className="block flex flex-row items-center py-4">
                    <FaRegFileAlt className="mr-2" />
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={S3_DOMAIN + item.uri}
                      className="hover:underline"
                    >
                      {item.fileName}
                    </a>
                  </div>
                  {/* <a href={item} download>
                      Download PDF
                    </a> */}
                </div>
              ))}
            </div>
          )}
        </div>
      </ReactModal>
    </div>
  );
}

function AdditionaServicesDialog({
  orderType,
  modalOpen,
  modalTitle,
  successActionText,
  orderId,
  userType,
  actionName,
  onClose,
  modalText,
  successActionDone,
  modalButtonText,
  oriServiceFee,
  addServices,
}) {
  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo ? userInfo.data.token : ""}`,
    },
  };

  const [reqStatus, setReqStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const [formState, setFormState] = useState({
    serviceFee: oriServiceFee,
    notes: "",
  });
  const [otherServices, setOtherServices] = useState({
    polishing: "",
    replaceStrap: "",
    reshaping: "",
  });
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    if (addServices) {
      setOtherServices(addServices);
    } else {
      setOtherServices({
        polishing: "",
        replaceStrap: "",
        reshaping: "",
      });
    }
  }, [addServices]);

  const handleChange = (e) => {
    const { value, name } = e.target;
    const type = e.type;

    if (type === "FLOAT") {
      const validated = value.match(/^(\d*\.{0,1}\d{0,2}$)/);
      if (!validated) {
        return;
      }
    }
    setFormState({ ...formState, [name]: value });
  };

  const handleOtherServiceChange = (e) => {
    const { value, name } = e.target;
    const validated = value.match(/^(\d*\.{0,1}\d{0,2}$)/);
    if (!validated) {
      return;
    }
    setOtherServices({ ...otherServices, [name]: value });
  };

  const validate = (values) => {
    let errors = {};
    if (!values.serviceFee) {
      errors.serviceFee = "Service fee is required.";
    }

    return errors;
  };

  const modalAction = async (e) => {
    e.preventDefault();

    const hasErrors = validate(formState);

    if (Object.keys(hasErrors).length === 0) {
      setFormErrors({});
      updateOffer();
    } else {
      setFormErrors(hasErrors);
    }
  };

  const updateOffer = async () => {
    let copy = { ...formState };
    copy.otherServices = otherServices;
    let actionInfo = JSON.stringify(copy);

    try {
      setReqStatus({ ...reqStatus, loading: true });

      const responseData = await orderActionRequest(
        orderType,
        orderId,
        userType,
        actionName,
        actionInfo,
        config
      );

      if (responseData.data.success) {
        setReqStatus({ ...reqStatus, loading: false, success: true });
      } else {
        throw responseData.data.message;
      }
    } catch (error) {
      setReqStatus({ ...reqStatus, loading: false, error: error });
    }
  };

  const closeModal = () => {
    if (reqStatus.success) {
      successActionDone();
    }
    onClose();
  };

  return (
    <div>
      <ReactModal
        isOpen={modalOpen}
        appElement={document.getElementById("root")}
        className={commonStyle.inputModal}
        overlayClassName={commonStyle.overlay}
        shouldFocusAfterRender={false}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={true}
      >
        <div className={commonStyle.dialogContentContainer}>
          <div className="w-full">
            <div className="flex justify-end">
              <CloseButton action={closeModal} />
            </div>
            {reqStatus.success ? (
              <div>
                <div className="w-full mt-10 lg:mt-8 flex items-center flex-col">
                  <h2>{modalTitle}</h2>
                  <div className="w-full flex items-center justify-center">
                    <div className="w-20 h-20 rounded-full border-solid border border-brandYellow flex items-center justify-center mt-4">
                      <GoCheck className="w-10 h-10 text-brandYellow" />
                    </div>
                  </div>
                  <p className="my-6">{successActionText}</p>
                  <Button name="OK" action={closeModal} />
                </div>
              </div>
            ) : (
              <div>
                <div className="w-full mt-10 lg:mt-8 flex items-center flex-col">
                  <h2>{modalTitle}</h2>
                  <form onSubmit={modalAction} className="mt-8 w-full">
                    <p>{modalText}</p>

                    <TextField
                      type="text"
                      label="Service Fee*"
                      name="serviceFee"
                      value={formState.serviceFee}
                      onChange={(serviceFee) =>
                        handleChange({
                          type: "FLOAT",
                          target: {
                            value: serviceFee,
                            name: "serviceFee",
                          },
                        })
                      }
                      error={formErrors.serviceFee}
                    />
                    <FieldLabel label={"Additional Services Price"} />
                    <div className="grid grid-cols-3 gap-4 bg-lightGray rounded p-4 mt-2">
                      <TextField
                        type="text"
                        label="Polishing"
                        name="polishing"
                        value={otherServices.polishing}
                        onChange={(polishing) =>
                          handleOtherServiceChange({
                            target: {
                              value: polishing,
                              name: "polishing",
                            },
                          })
                        }
                        error={""}
                      />
                      <TextField
                        type="text"
                        label="Replace Strap"
                        name="replaceStrap"
                        value={otherServices.replaceStrap}
                        onChange={(replaceStrap) =>
                          handleOtherServiceChange({
                            target: {
                              value: replaceStrap,
                              name: "replaceStrap",
                            },
                          })
                        }
                        error={""}
                      />
                      <TextField
                        type="text"
                        label="Reshaping"
                        name="reshaping"
                        value={otherServices.reshaping}
                        onChange={(reshaping) =>
                          handleOtherServiceChange({
                            target: {
                              value: reshaping,
                              name: "reshaping",
                            },
                          })
                        }
                        error={""}
                      />
                    </div>
                    <TextField
                      type="text"
                      label="Notes"
                      name="notes"
                      value={formState.notes}
                      onChange={(notes) =>
                        handleChange({
                          type: "TEXT",
                          target: {
                            value: notes,
                            name: "notes",
                          },
                        })
                      }
                      error={formErrors.notes}
                    />

                    {reqStatus.error && <ErrorMessage mess={reqStatus.error} />}
                    <div className="mt-8">
                      {reqStatus.loading ? (
                        <LoadingButton name="Proceeding..." />
                      ) : (
                        <SubmitButton name={modalButtonText} />
                      )}
                    </div>
                  </form>
                </div>
              </div>
            )}
          </div>
        </div>
      </ReactModal>
    </div>
  );
}

function SelectProductCollectionDialog({
  productId,
  modalTitle,
  successActionText,
  modalText,
  modalButtonText,
  modalOpen,
  collections,
  selectedCollections,
  onSelect,
  successActionDone,
  onClose,
}) {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo ? userInfo.data.token : ""}`,
    },
  };

  const [reqStatus, setReqStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const [newCollection, setNewCollection] = useState();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (collections.length) {
      if (selectedCollections.length) {
        let filteredCollections = collections.filter(
          (collection) =>
            !selectedCollections.some(
              (selected) => selected.id === collection.id
            )
        );
        setOptions(filteredCollections);
      } else {
        setOptions(collections);
      }
    }
  }, [collections, selectedCollections]);

  const saveAction = async () => {
    try {
      setReqStatus({ ...reqStatus, loading: true });
      if (!newCollection && !newCollection.id) {
        setReqStatus({
          ...reqStatus,
          loading: false,
          success: false,
          error: "No collection selected",
        });
        return;
      }

      let save_values = {
        productId: productId,
      };
      const responseData = await addProductToCollection(
        newCollection.id,
        save_values,
        config,
        dispatch
      );

      if (responseData.data.success) {
        setReqStatus({ ...reqStatus, loading: false, success: true });
      } else {
        throw responseData.data.message;
      }
    } catch (error) {
      setReqStatus({ ...reqStatus, loading: false, error: error });
    }
  };

  const closeModal = () => {
    if (reqStatus.success) {
      successActionDone();
    }
    onClose();
  };

  return (
    <div>
      <ReactModal
        isOpen={modalOpen}
        appElement={document.getElementById("root")}
        className={commonStyle.inputModal}
        overlayClassName={commonStyle.overlay}
        shouldFocusAfterRender={false}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={true}
      >
        <div className={commonStyle.dialogContentContainer}>
          <div className="w-full">
            <div className="flex justify-end">
              <CloseButton action={closeModal} />
            </div>
            {reqStatus.success ? (
              <div>
                <div className="w-full mt-10 lg:mt-8 flex items-center flex-col">
                  <h2>{modalTitle}</h2>

                  <div className="w-full flex items-center justify-center">
                    <div className="w-20 h-20 rounded-full border border-solid border-brandYellow flex items-center justify-center mt-4">
                      <GoCheck className="w-10 h-10 text-brandYellow" />
                    </div>
                  </div>
                  <p className="my-6">{successActionText}</p>
                  <Button name="OK" action={closeModal} />
                </div>
              </div>
            ) : (
              <div className="w-full min-h-[480px]">
                <div className="w-full mt-10 lg:mt-8 flex items-center flex-col">
                  <h2>{modalTitle}</h2>

                  <div className="w-1/2 mt-4">
                    <SelectField
                      label="Select Collection"
                      placeHolder="Select collection"
                      selectOptions={options}
                      value={newCollection}
                      onChange={(newCollection) =>
                        setNewCollection(newCollection)
                      }
                    />
                    {reqStatus.error && <ErrorMessage mess={reqStatus.error} />}
                    <div className="mt-4">
                      {reqStatus.loading ? (
                        <LoadingButton name="Proceeding..." />
                      ) : (
                        <Button name={modalButtonText} action={saveAction} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </ReactModal>
    </div>
  );
}

function AdminCancelOrderActionDialog({
  orderType,
  modalOpen,
  actionName,
  modalTitle,
  modalText,
  successActionText,
  modalButtonText,
  orderId,
  onClose,
  successActionDone,
}) {
  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo ? userInfo.data.token : ""}`,
    },
  };

  const [reqStatus, setReqStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const modalAction = async () => {
    try {
      setReqStatus({ ...reqStatus, loading: true });

      const responseData = await adminCancelOrderRequest(orderId, config);

      if (responseData.data.success) {
        setReqStatus({ ...reqStatus, loading: false, success: true });
      } else {
        throw responseData.data.message;
      }
    } catch (error) {
      setReqStatus({ ...reqStatus, loading: false, error: error });
    }
  };

  const closeModal = () => {
    if (reqStatus.success) {
      successActionDone();
    }
    onClose();
  };

  return (
    <div>
      <ReactModal
        isOpen={modalOpen}
        appElement={document.getElementById("root")}
        className={commonStyle.inputModal}
        overlayClassName={commonStyle.overlay}
        shouldFocusAfterRender={false}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={true}
      >
        <div className={commonStyle.dialogContentContainer}>
          <div className="w-full">
            <div className="flex justify-end">
              <CloseButton action={closeModal} />
            </div>
            {reqStatus.success ? (
              <div>
                <div className="w-full mt-10 lg:mt-8 flex items-center flex-col">
                  <h2>{modalTitle}</h2>

                  <div className="w-full flex items-center justify-center">
                    <div className="w-20 h-20 rounded-full border border-solid border-brandYellow flex items-center justify-center mt-4">
                      <GoCheck className="w-10 h-10 text-brandYellow" />
                    </div>
                  </div>
                  <p className="my-6">{successActionText}</p>
                  <Button name="OK" action={closeModal} />
                </div>
              </div>
            ) : (
              <div>
                <div className="w-full mt-10 lg:mt-8 flex items-center flex-col">
                  <h2>{modalTitle}</h2>

                  <p className="my-6">{modalText}</p>
                  {reqStatus.error && <ErrorMessage mess={reqStatus.error} />}
                  <div className="mt-8">
                    {reqStatus.loading ? (
                      <LoadingButton name="Proceeding..." />
                    ) : (
                      <Button name={modalButtonText} action={modalAction} />
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </ReactModal>
    </div>
  );
}

function AdminUpdatePriceDialog({
  orderType,
  modalOpen,
  modalTitle,
  modalText,
  successActionText,
  modalButtonText,
  orderId,
  onClose,
  successActionDone,
  oriPrice,
}) {
  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo ? userInfo.data.token : ""}`,
    },
  };

  const [reqStatus, setReqStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const [formState, setFormState] = useState({
    newPrice: oriPrice,
  });
  const [formErrors, setFormErrors] = useState({});

  const handleChange = (e) => {
    const { value, name } = e.target;
    const type = e.type;

    if (type === "FLOAT") {
      const validated = value.match(/^(\d*\.{0,1}\d{0,2}$)/);
      if (!validated) {
        return;
      }
    }
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const validate = (values) => {
    let errors = {};
    if (!values.newPrice) {
      errors.newPrice = "Price is required.";
    }

    return errors;
  };

  const modalAction = async (e) => {
    e.preventDefault();

    const hasErrors = validate(formState);

    if (Object.keys(hasErrors).length === 0) {
      setFormErrors({});
      updatePrice();
    } else {
      setFormErrors(hasErrors);
    }
  };

  const updatePrice = async () => {
    let actionInfo = JSON.stringify(formState);

    try {
      setReqStatus({ ...reqStatus, loading: true });

      const responseData = await adminUpdateOrderPriceRequest(
        orderId,
        formState,
        config
      );

      if (responseData.data.success) {
        setReqStatus({ ...reqStatus, loading: false, success: true });
      } else {
        throw responseData.data.message;
      }
    } catch (error) {
      setReqStatus({ ...reqStatus, loading: false, error: error });
    }
  };

  const closeModal = () => {
    if (reqStatus.success) {
      successActionDone();
    }
    onClose();
  };

  return (
    <div>
      <ReactModal
        isOpen={modalOpen}
        appElement={document.getElementById("root")}
        className={commonStyle.inputModal}
        overlayClassName={commonStyle.overlay}
        shouldFocusAfterRender={false}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={true}
      >
        <div className={commonStyle.dialogContentContainer}>
          <div className="w-full">
            <div className="flex justify-end">
              <CloseButton action={closeModal} />
            </div>
            {reqStatus.success ? (
              <div>
                <div className="w-full mt-10 lg:mt-8 flex items-center flex-col">
                  <h2>{modalTitle}</h2>
                  <div className="w-full flex items-center justify-center">
                    <div className="w-20 h-20 rounded-full border-solid border border-brandYellow flex items-center justify-center mt-4">
                      <GoCheck className="w-10 h-10 text-brandYellow" />
                    </div>
                  </div>
                  <p className="my-6">{successActionText}</p>
                  <Button name="OK" action={closeModal} />
                </div>
              </div>
            ) : (
              <div>
                <div className="w-full mt-10 lg:mt-8 flex items-center flex-col">
                  <h2>{modalTitle}</h2>
                  <form onSubmit={modalAction} className="mt-8 w-full">
                    <p>{modalText}</p>

                    <TextField
                      type="text"
                      label="New Price*"
                      name="newPrice"
                      value={formState.newPrice}
                      onChange={(newPrice) =>
                        handleChange({
                          type: "FLOAT",
                          target: {
                            value: newPrice,
                            name: "newPrice",
                          },
                        })
                      }
                      error={formErrors.newPrice}
                    />

                    {reqStatus.error && <ErrorMessage mess={reqStatus.error} />}
                    <div className="mt-8">
                      {reqStatus.loading ? (
                        <LoadingButton name="Proceeding..." />
                      ) : (
                        <SubmitButton name={modalButtonText} />
                      )}
                    </div>
                  </form>
                </div>
              </div>
            )}
          </div>
        </div>
      </ReactModal>
    </div>
  );
}

function AdminNotesDialog({
  modalOpen,
  modalTitle,
  successActionText,
  orderId,
  onClose,
  modalText,
  successActionDone,
  modalButtonText,
  oriAdminNotes,
}) {
  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo ? userInfo.data.token : ""}`,
    },
  };

  const [reqStatus, setReqStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const [formState, setFormState] = useState({
    adminNotes: oriAdminNotes,
  });
  const [formErrors, setFormErrors] = useState({});

  const handleChange = (e) => {
    const { value, name } = e.target;
    setFormState({ ...formState, [name]: value });
  };

  const validate = (values) => {
    let errors = {};
    if (!values.adminNotes) {
      errors.adminNotes = "Notes is required.";
    }

    return errors;
  };

  const modalAction = async (e) => {
    e.preventDefault();

    const hasErrors = validate(formState);

    if (Object.keys(hasErrors).length === 0) {
      setFormErrors({});
      updateAdminNotes();
    } else {
      setFormErrors(hasErrors);
    }
  };

  const updateAdminNotes = async () => {
    try {
      setReqStatus({ ...reqStatus, loading: true });

      const responseData = await updateAdminNotesRequest(
        orderId,
        formState,
        config
      );

      if (responseData.data.success) {
        setReqStatus({ ...reqStatus, loading: false, success: true });
      } else {
        throw responseData.data.message;
      }
    } catch (error) {
      setReqStatus({ ...reqStatus, loading: false, error: error });
    }
  };

  const closeModal = () => {
    if (reqStatus.success) {
      successActionDone();
    }
    onClose();
  };

  return (
    <div>
      <ReactModal
        isOpen={modalOpen}
        appElement={document.getElementById("root")}
        className={commonStyle.inputModal}
        overlayClassName={commonStyle.overlay}
        shouldFocusAfterRender={false}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={true}
      >
        <div className={commonStyle.dialogContentContainer}>
          <div className="w-full">
            <div className="flex justify-end">
              <CloseButton action={closeModal} />
            </div>
            {reqStatus.success ? (
              <div>
                <div className="w-full mt-10 lg:mt-8 flex items-center flex-col">
                  <h2>{modalTitle}</h2>
                  <div className="w-full flex items-center justify-center">
                    <div className="w-20 h-20 rounded-full border-solid border border-brandYellow flex items-center justify-center mt-4">
                      <GoCheck className="w-10 h-10 text-brandYellow" />
                    </div>
                  </div>
                  <p className="my-6">{successActionText}</p>
                  <Button name="OK" action={closeModal} />
                </div>
              </div>
            ) : (
              <div>
                <div className="w-full mt-10 lg:mt-8 flex items-center flex-col">
                  <h2>{modalTitle}</h2>
                  <form onSubmit={modalAction} className="mt-8 w-full">
                    <p>{modalText}</p>
                    <TextAreaField
                      type="text"
                      label="Notes"
                      name="adminNotes"
                      value={formState.adminNotes}
                      rows={12}
                      placeholder="order notes..."
                      onChange={(adminNotes) =>
                        handleChange({
                          target: {
                            value: adminNotes,
                            name: "adminNotes",
                          },
                        })
                      }
                      error={formErrors.adminNotes}
                    />
                    {reqStatus.error && <ErrorMessage mess={reqStatus.error} />}
                    <div className="mt-8">
                      {reqStatus.loading ? (
                        <LoadingButton name="Proceeding..." />
                      ) : (
                        <SubmitButton name={modalButtonText} />
                      )}
                    </div>
                  </form>
                </div>
              </div>
            )}
          </div>
        </div>
      </ReactModal>
    </div>
  );
}

function DeleteDialog({
  title,
  preDeleteMess,
  successMessage,
  isDialogOpen,
  closeDialog,
  loading,
  success,
  error,
  loadingMessage,
  deleteAction,
  closeDialogWithSuccess,
}) {
  const [beforeDelete, setBeforeDel] = useState(true);

  const deleteItem = () => {
    setBeforeDel(false);
    deleteAction();
  };

  const closeDialogAction = () => {
    setBeforeDel(true);
    closeDialog();
  };

  const closeDialogSuccess = () => {
    setBeforeDel(true);
    closeDialogWithSuccess();
  };

  return (
    <div>
      <ReactModal
        isOpen={isDialogOpen}
        appElement={document.getElementById("root")}
        className={commonStyle.inputModal}
        overlayClassName={commonStyle.overlay}
        shouldFocusAfterRender={false}
        onRequestClose={closeDialog}
        shouldCloseOnOverlayClick={true}
      >
        <div className={commonStyle.dialogContentContainer}>
          <div className={commonStyle.dialogTitle}>
            <h3>{title}</h3>
          </div>

          {beforeDelete ? (
            <>
              <p className={commonStyle.preDelMess}>{preDeleteMess}</p>
              <div className="flex mt-4">
                <button
                  className="border border-solid rounded text-sm px-3 py-2 border-red-500 text-red-500 hover:bg-red-500 hover:text-white mr-4"
                  onClick={deleteItem}
                >
                  Yes, Proceed
                </button>
                <button
                  className="border border-solid rounded text-sm px-3 py-2 border-black text-black hover:bg-black hover:text-white mr-4"
                  onClick={closeDialogAction}
                >
                  Cancel
                </button>
              </div>
            </>
          ) : (
            <>
              {loading ? (
                <Loader mess={loadingMessage} />
              ) : success ? (
                <>
                  <p className={commonStyle.dialogSuccessMessage}>
                    {successMessage}
                  </p>
                  <div
                    className={commonStyle.confirmBtnCon}
                    onClick={closeDialogSuccess}
                  >
                    <div className={commonStyle.confirmBtn}>OK</div>
                  </div>
                </>
              ) : (
                <>
                  <ErrorMessage mess={error} />
                  <div
                    className={commonStyle.confirmBtnCon}
                    onClick={closeDialogAction}
                  >
                    <div className={commonStyle.confirmBtn}>OK</div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </ReactModal>
    </div>
  );
}

function TestEmailDialog({
  modalOpen,
  modalTitle,
  modalButtonText,
  successActionText,
  successActionDone,
  onClose,
  requestId,
}) {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo ? userInfo.data.token : ""}`,
    },
  };

  const [email, setEmail] = useState("jay@watchspace.com");
  const [emailError, setEmailError] = useState("");

  const [reqStatus, setReqStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const saveAction = async () => {
    if (!email) {
      setEmailError("Please enter an email address.");
    }

    try {
      setReqStatus({ ...reqStatus, loading: true });

      let save_values = {
        email: email,
      };
      const responseData = await testEmailRequest(
        requestId,
        save_values,
        config,
        dispatch
      );

      if (responseData.data.success) {
        setReqStatus({ ...reqStatus, loading: false, success: true });
      } else {
        throw responseData.data.message;
      }
    } catch (error) {
      setReqStatus({ ...reqStatus, loading: false, error: error });
    }
  };

  const closeModal = () => {
    if (reqStatus.success) {
      successActionDone();
    }
    onClose();
  };

  return (
    <div>
      <ReactModal
        isOpen={modalOpen}
        appElement={document.getElementById("root")}
        className={commonStyle.inputModal}
        overlayClassName={commonStyle.overlay}
        shouldFocusAfterRender={false}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={true}
      >
        <div className={commonStyle.dialogContentContainer}>
          <div className="w-full">
            <div className="flex justify-end">
              <CloseButton action={closeModal} />
            </div>
            {reqStatus.success ? (
              <div>
                <div className="w-full mt-10 lg:mt-8 flex items-center flex-col">
                  <h2>{modalTitle}</h2>

                  <div className="w-full flex items-center justify-center">
                    <div className="w-20 h-20 rounded-full border border-solid border-brandYellow flex items-center justify-center mt-4">
                      <GoCheck className="w-10 h-10 text-brandYellow" />
                    </div>
                  </div>
                  <p className="my-6">{successActionText}</p>
                  <Button name="OK" action={closeModal} />
                </div>
              </div>
            ) : (
              <div className="w-full min-h-[480px]">
                <div className="w-full mt-10 lg:mt-8 flex items-center flex-col">
                  <h2>{modalTitle}</h2>

                  <div className="w-1/2 mt-4">
                    <TextField
                      type="text"
                      label="Enter an Email address to send the test email"
                      name="subject"
                      placeholder={"The email subject"}
                      value={email}
                      onChange={(email) => setEmail(email)}
                      error={emailError}
                    />

                    {reqStatus.error && <ErrorMessage mess={reqStatus.error} />}
                    <div className="mt-4">
                      {reqStatus.loading ? (
                        <LoadingButton name="Proceeding..." />
                      ) : (
                        <Button name={modalButtonText} action={saveAction} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </ReactModal>
    </div>
  );
}

function AdminConfirmAgreementDialog({
  orderType,
  modalOpen,
  modalTitle,
  successActionText,
  orderId,
  userType = "platform",
  actionName,
  onClose,
  modalText,
  successActionDone,
  modalButtonText,
  oriOfferPrice,
  oriCommision,
  oriServiceFee,
  oriOrderTotal,
  oriBuyoutOffer,
}) {
  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo ? userInfo.data.token : ""}`,
    },
  };
  const [buyoutSelected, setBuyoutSelected] = useState(false);
  const [reqStatus, setReqStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const proceedAction = async () => {
    let action_info = {
      selectedConsignWay: buyoutSelected ? "BUYOUT" : "DEFAULT",
    };
    let actionInfo = JSON.stringify(action_info);

    try {
      setReqStatus({ ...reqStatus, loading: true });
      const responseData = await orderActionRequest(
        orderType,
        orderId,
        userType,
        actionName,
        actionInfo,
        config
      );

      if (responseData.data.success) {
        setReqStatus({ ...reqStatus, loading: false, success: true });
      } else {
        throw responseData.data.message;
      }
    } catch (error) {
      setReqStatus({ ...reqStatus, loading: false, error: error });
    }
  };

  const closeModal = () => {
    if (reqStatus.success) {
      successActionDone();
    }
    onClose();
  };

  return (
    <div>
      <ReactModal
        isOpen={modalOpen}
        appElement={document.getElementById("root")}
        className={commonStyle.inputModal}
        overlayClassName={commonStyle.overlay}
        shouldFocusAfterRender={false}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={true}
      >
        <div className={commonStyle.dialogContentContainer}>
          <div className="w-full">
            <div className="flex justify-end">
              <CloseButton action={closeModal} />
            </div>
            {reqStatus.success ? (
              <div>
                <div className="w-full mt-10 lg:mt-8 flex items-center flex-col">
                  <h2>{modalTitle}</h2>
                  <div className="w-full flex items-center justify-center">
                    <div className="w-20 h-20 rounded-full border-solid border border-brandYellow flex items-center justify-center mt-4">
                      <GoCheck className="w-10 h-10 text-brandYellow" />
                    </div>
                  </div>
                  <p className="my-6">{successActionText}</p>
                  <Button name="OK" action={closeModal} />
                </div>
              </div>
            ) : (
              <div>
                <div className="w-full mt-10 lg:mt-8 flex items-center flex-col">
                  <h2>{modalTitle}</h2>
                  <div className="mt-8 w-full">
                    <p>{modalText}</p>
                    <div className="mt-4">

                    <button
                      className={`w-full border-solid ${
                        !buyoutSelected
                          ? "border-brandYellow border-2"
                          : "border border-black"
                      } rounded p-4 flex flex-col justify-between hover:cursor-pointer text-left`}
                      onClick={() => setBuyoutSelected(false)}
                    >
                      <div>
                        <div className="flex flex-row items-center">
                          {!buyoutSelected ? (
                            <MdRadioButtonChecked className="w-6 h-6 mr-1 text-brandYellow" />
                          ) : (
                            <MdRadioButtonUnchecked className="w-6 h-6 mr-1" />
                          )}

                          <p
                            className={`text-xl font-medium ${
                              !buyoutSelected
                                ? "text-brandYellow"
                                : "text-black"
                            }`}
                          >
                            Consignment
                          </p>
                        </div>
                        <p className="mt-2 text-xs text-gray">
                          Pay the customer after the watch is sold
                        </p>
                      </div>
                      <div className={`flex flex-col justify-between mt-4`}>
                        <p>{"Listing Price"}</p>
                        <p className="mt-1">
                          {Number(oriOfferPrice) > 0
                            ? convtPrice(oriOfferPrice)
                            : "Not Offered"}
                        </p>
                        <p className="mt-2">{"WatchSpace Fee"}</p>
                        <p className="mt-1">
                          {Number(oriOfferPrice) > 0
                            ? convtPrice(oriCommision)
                            : "Not Offered"}
                        </p>
                        {Number(oriServiceFee) > 0 ? (
                          <>
                            <p className="mt-2">{"Service Fee"}</p>
                            <p className="mt-1">
                              {Number(oriOfferPrice) > 0
                                ? convtPrice(oriServiceFee)
                                : "Not Offered"}
                            </p>
                          </>
                        ) : (
                          <></>
                        )}
                        <p className="mt-6">{"Customer Earnnings"}</p>
                        <p className="mt-1 text-xl font-medium">
                          {Number(oriOrderTotal) > 0
                            ? convtPrice(oriOrderTotal)
                            : "Not Offered"}
                        </p>
                      </div>
                    </button>

                    <button
                      className={`w-full mt-6 border-solid ${
                        buyoutSelected
                          ? "border-2 border-brandYellow"
                          : "border border-black"
                      } rounded p-4 flex flex-col justify-between hover:cursor-pointer text-left`}
                      onClick={() => setBuyoutSelected(true)}
                    >
                      <div>
                        <div className="flex flex-row items-center">
                          {buyoutSelected ? (
                            <MdRadioButtonChecked className="w-6 h-6 mr-1 text-brandYellow" />
                          ) : (
                            <MdRadioButtonUnchecked className="w-6 h-6 mr-1" />
                          )}

                          <p
                            className={`text-xl font-medium ${
                              buyoutSelected ? "text-brandYellow" : "text-black"
                            }`}
                          >
                            Buyout
                          </p>
                        </div>
                        <p className="mt-2 text-xs text-gray">
                          Pay the customer after watch is received and inspected.
                        </p>
                      </div>
                      <div className={`flex flex-col justify-between mt-4`}>
                        <p>{"Buyout Price"}</p>
                        <p className="mt-1 text-xl font-medium">
                          {Number(oriBuyoutOffer) > 0
                            ? convtPrice(oriBuyoutOffer)
                            : "Not Offered"}
                        </p>
                      </div>
                    </button>
                    </div>
                    {reqStatus.error && <ErrorMessage mess={reqStatus.error} />}
                    <div className="mt-8">
                      {reqStatus.loading ? (
                        <LoadingButton name="Proceeding..." />
                      ) : (
                        <Button name={modalButtonText} action={proceedAction} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </ReactModal>
    </div>
  );
}

export {
  OrderActionWithNotesDialog,
  ImageViewDialog,
  ImagesPreviewDialog,
  MakeOfferDialog,
  OrderActionDialog,
  OrderActionWithFilesDialog,
  SendMessageDialog,
  ShipOrderDialog,
  SimpleDialog,
  ViewFilesDialog,
  AdditionaServicesDialog,
  SelectProductCollectionDialog,
  AdminCancelOrderActionDialog,
  AdminUpdatePriceDialog,
  AdminNotesDialog,
  DeleteDialog,
  TestEmailDialog,
  AdminConfirmAgreementDialog,
};
