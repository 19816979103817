import Breadcrumbs from "@mui/material/Breadcrumbs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { convToLocalDate } from "../../api/functions";
import Pagination from "../../components/Pagination";
import ScreenTitle from "../../components/ScreenTitle";
import { LinkButton, TextLinkButton } from "../../components/buttons";
import { SearchField } from "../../components/inputs";
import {
  ErrorMessage,
  Loader,
  OrderStatusTag,
  OrderTypeTag,
  ProductName,
  ProductNameLink,
} from "../../components/smallComponents";
import { orderListRequest } from "../../redux/actions/orderActions";
import commonStyle from "../../style/commonStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle";

export default function OrderList() {
  useDocumentTitle("Order List");
  const dispatch = useDispatch();

  const { loading, orders, success, error } = useSelector(
    (state) => state.orderList
  );


  const pageSize = 20;
  const [pageNum, setPageNum] = useState(0);
  const [keyword, setKeyword] = useState("");

  const onPageChangeRequest = (page) => {
    setPageNum(page.selected);
  };

  useEffect(() => {
    dispatch(orderListRequest(keyword, pageNum, pageSize));
  }, [dispatch, pageNum, keyword]);

  const handleSearchChange = (value) => {
    setKeyword(value);
    setPageNum(0);
  };

  return (
    <div className={commonStyle.pageContainer}>
      <div className="w-full p-8">
        <Breadcrumbs aria-label="breadcrumb">
          <NavLink underline="hover" color="inherit" to="/">
            Home
          </NavLink>
          <NavLink underline="hover" color="inherit" to="/orders">
            Orders
          </NavLink>
        </Breadcrumbs>
        <ScreenTitle title="Order List" />

        <div className="flex flex-row justify-between mt-8">
          <div className="w-1/2">
          <SearchField
            placeholder="Type order number to search..."
            initialQuery={keyword}
            onChange={handleSearchChange}
          />
          </div>
          <div>
            <LinkButton
              name={"Add New Order"}
              path={`/orders/new`}
              width="fit"
            />{" "}
          </div>
        </div>

        

        {loading ? (
          <Loader mess="Requesting orders..." />
        ) : success ? (
          <div className={commonStyle.longDataTable}>
            <table className={commonStyle.pageTable}>
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Product</th>
                  <th>Order Date</th>
                  <th>OrderNumber</th>
                  <th>Type</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {orders.orders.length > 0 ? (
                  orders.orders.map((item, index) => (
                    <tr key={index}>
                      <td>
                        { item.type === "PREORDER" && <span
                          className={`text-sm px-3 py-1 text-white bg-green-500 rounded-full mr-2`}
                        >
                          Pre-order
                        </span>
                        }
                        <OrderTypeTag isOnline={item.adminId > 0 ? false : true} />
                        
                      </td>
                      <td>
                        <ProductNameLink
                          productId={item.productId}
                          image={item.imageUris.length ? item.imageUris[0] : ""}
                          brandName={item.brandName}
                          modelName={item.modelName}
                          referenceNumber={item.referenceNumber}
                          price={item.price}
                        />
                      </td>
                      <td>{convToLocalDate(item.atime)}</td>

                      <td>{item.orderNumber}</td>
                      <td>{item.type === "DEFAULT" ? "MiddleMan(Direct)" :  item.type === "PLATFORMDIRECT" ? "PlatformDirect" : item.type === "PREORDER" ? "Pre-order" : "MiddleMan((Authentification))" }</td>

                      <td>
                        <OrderStatusTag currState={item.currStateDesc} />
                      </td>
                      <td>
                        <TextLinkButton
                          name="View Details"
                          path={`/orders/details/${item.id}`}
                          
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>There are no order available.</td>
                  </tr>
                )}
              </tbody>
            </table>
            {orders.orders.length > 0 ? (
              <Pagination
                totalPageNum={orders.totalPages}
                forcePage={pageNum}
                onPageChange={onPageChangeRequest}
              />
            ) : (
              <></>
            )}
          </div>
        ) : error ? (
          <ErrorMessage mess={error} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
