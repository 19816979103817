import Breadcrumbs from "@mui/material/Breadcrumbs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import { convToLocalDate, convtPrice } from "../../../api/functions";
import ScreenTitle from "../../../components/ScreenTitle";
import { LinkButton, TextLinkButton } from "../../../components/buttons";
import { ProductImageListPreview } from "../../../components/productComponents";
import {
  AddressLine,
  ConsignmentOrderStatusTag,
  ErrorMessage,
  H3,
  Loader,
  OrderStatusTag,
  UserAvatar,
} from "../../../components/smallComponents";
import {
  consignmentQuoteDetailsRequest,
  watchRequestDetailsRequest,
} from "../../../redux/actions/orderActions";
import commonStyle from "../../../style/commonStyle.module.css";
import useDocumentTitle from "../../../useDocumentTitle";
import {
  ActionWithFileButton,
  ContactButton,
  MakeAnOfferButton,
  OrderActionButton,
} from "../orderActionButtons";

const S3_DOMAIN = process.env.REACT_APP_S3_DOMAIN;

export default function WatchRequestOrderDetails() {
  const { id } = useParams();
  useDocumentTitle("Watch Request Details");
  const dispatch = useDispatch();

  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  const { loading, details, success, error } = useSelector(
    (state) => state.watchRequestDetails
  );

  const [saveStatus, setSaveStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const [courierInfo, setCourierInfo] = useState("");

  useEffect(() => {
    dispatch(watchRequestDetailsRequest(id));
    setSaveStatus({
      loading: false,
      success: false,
      error: "",
    });
  }, [dispatch, saveStatus.success, id]);

  const ViewItem = ({ name, value }) => {
    return (
      <div className="mt-6">
        <p className="text-sm text-gray">{name}</p>
        <p className="text-sm mt-2">{value}</p>
      </div>
    );
  };

  const BoolViewItem = ({ name, value, additionText = "" }) => {
    return (
      <div className="mt-6">
        <p className="text-sm text-gray">{name}</p>
        <p className="text-sm mt-2">
          {value === 1 ? "Yes" : value === 0 ? "No" : ""}
        </p>
        {additionText && <p className="text-sm mt-2">{additionText}</p>}
      </div>
    );
  };

  const OrderViewItem = ({ name, value }) => {
    return (
      <div className={`flex flex-row justify-between mt-4`}>
        <p>{name}</p>
        <p>{value}</p>
      </div>
    );
  };

  return (
    <div className={commonStyle.pageContainer}>
      <div className="w-full p-8">
        <Breadcrumbs aria-label="breadcrumb">
          <NavLink underline="hover" color="inherit" to="/">
            Home
          </NavLink>
          <NavLink underline="hover" color="inherit" to="/watch-request/list">
            Watch Request
          </NavLink>
          <NavLink underline="hover" color="inherit">
            Details
          </NavLink>
        </Breadcrumbs>
        <ScreenTitle title="Watch Request Details" />

        {loading ? (
          <Loader mess="Requesting details..." />
        ) : success ? (
          <div className="w-full mt-6">
            <div className="w-full flex  flex-row">
              <div className="w-fit flex flex-row">
                <span>Order Number:</span>
                <span>{details.orderNumber}</span>
              </div>
              {/* <OrderStatusTag currState={details.currStateDesc} /> */}
            </div>

            {/* order actions */}

            <div className="mt-2 border-b border-solid border-borderGray py-4">
              <H3 title={"Required Watch Details"} />
              <div className="w-full mt-2 bg-lightGray rounded p-4">
                <div className="w-fully mt-2">
                  <div className="w-full grid grid-cols-2">
                    <ViewItem name={"Brand"} value={details.brandName} />

                    <ViewItem name={"Model"} value={details.modelName} />
                    <ViewItem
                      name={"Reference Number"}
                      value={details.referenceNumber}
                    />
                    <ViewItem name={"Movement"} value={details.movementName} />

                    <ViewItem
                      name={"Case Material"}
                      value={details.caseMaterialName}
                    />
                    <ViewItem
                      name={"Dial Color"}
                      value={details.dialColorName}
                    />

                    <ViewItem
                      name={"Bracelet Material"}
                      value={details.braceletMaterialName}
                    />
                    <ViewItem
                      name={"Bracelet Color"}
                      value={details.braceletColorName}
                    />

                    <ViewItem name={"Preferred Year"} value={details.year} />

                    <ViewItem
                      name={"Maximum Budget"}
                      value={"$" + convtPrice(details.budget)}
                    />
                  </div>

                  <ViewItem
                    name={"Any further information?"}
                    value={details.notes}
                  />
                </div>
              </div>
            </div>

            {/* client info */}
            <div className="w-full h-full border-b border-solid border-borderGray mt-6 py-4">
              <H3 title={"Contact User"} />

              <div className="flex justify-between flex-col lg:flex-row">
                <div className="flex items-center mt-4">
                  <UserAvatar name={details.userName} />{" "}
                  <span className="ml-4">{details.userName}</span>
                </div>

                <div className="w-fit text-sm text-darkGray mt-4">
                  <ContactButton
                    recipientId={details.userId}
                    productId={details.id}
                    refType={"WATCHREQUEST"}
                    buttonName={"Contact User"}
                  />
                </div>
              </div>
            </div>

            {/* buyer info */}
            <div className="w-full h-full border-b border-solid border-borderGray mt-6  py-4">
              <H3 title={"Contact Info"} />
              <div className="text-sm text-darkGray mt-4">
                <p>Email: {details.email ? details.email : ""}</p>
                <p className="mt-2">Phone: {details.phone ? details.phone : ""}</p>
             
              </div>
            </div>

            <div className="w-full h-full border-b border-solid border-borderGray mt-4 lg:justify-between py-4">
              <H3 title={"Quote Logs"} />
              <div className="text-sm text-darkGray mt-4">
                <div>
                  <span className="text-sm">
                    {convToLocalDate(details.atime)}
                  </span>
                  <span className="text-sm ml-4">Quote created</span>
                </div>
              </div>
            </div>
          </div>
        ) : error ? (
          <ErrorMessage mess={error} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
