import Breadcrumbs from "@mui/material/Breadcrumbs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import ScreenTitle from "../../components/ScreenTitle";
import { SimpleDialog } from "../../components/dialogs";
import { watchConstantOptionsRequest } from "../../redux/actions/watchActions";
import commonStyle from "../../style/commonStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle";
import OrderForm from "./OrderForm";
import { newUpdateQuotationRequest } from "../../api/consignmentRequests";
import { SelectField } from "../../components/inputs";
import { Button } from "../../components/buttons";
import {
  userListOptionsRequest,
  userDetailsRequest,
} from "../../redux/actions/userActions";
import { ErrorMessage, Loader } from "../../components/smallComponents";
import { createNewOrderActionRequest, createNewPreOrderActionRequest } from "../../api/orderRequests";
import PreOrderForm from "./PreOrderForm";

export default function NewOder({}) {
  useDocumentTitle("Add Order");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo ? userInfo.data.token : ""}`,
    },
  };

  const { loading, watchOptions, success, error } = useSelector(
    (state) => state.watchConstantOptions
  );
  const {
    loading: userOptionsLoading,
    userOptions,
    success: userOptionsSuccess,
    error: userOptionsError,
  } = useSelector((state) => state.userOptionsList);

  const {
    loading: userDetailsLoading,
    details,
    success: userDetailsSuccess,
    error: userDetailsError,
  } = useSelector((state) => state.userDetails);

  const [saveStatus, setSaveStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const [userId, setUserId] = useState(null);
  const [userIdError, setUserIdErro] = useState("");
  const [showUserField, setShowUserField] = useState(true);

  const [showForm, setShowForm] = useState(false);

  const [isPreOrder, setIsPreOrder] = useState(false);
  const [productType, setProductType] = useState({
    id: 1,
    label: "In Store Product",
    value: "instore",
  });
  const [productTypeError, setProductTypeError] = useState("");

  const [createdOrderId, setCreatedOrderId] = useState(null);
  const [dialog, setDialog] = useState(false);

  useEffect(() => {
    dispatch(watchConstantOptionsRequest());
    dispatch(userListOptionsRequest("allUser"));
  }, []);
  
  useEffect(() => {
    if (userId) {
      dispatch(userDetailsRequest(userId));
    }
  }, [userId]);

  const confirmUserAction = () => {
    if (!userId) {
      setUserIdErro("Please select a user.");
      return;
    }
    if (!productType) {
      setProductTypeError("Please select a product type.");
      return;
    }

    if (productType && productType.value === "preorder") {
      setIsPreOrder(true);
    }

    setShowUserField(false);
    setShowForm(true);
  };

  const saveOrder = async (values) => {
    let newOrderValues = { ...values };
    newOrderValues.buyerId = userId;
    newOrderValues.orderType = "PLATFORMDIRECT";

    setDialog(true);
    try {
      setSaveStatus((preState) => ({
        ...preState,
        loading: true,
      }));
      const responseData = await createNewOrderActionRequest(
        newOrderValues,
        config
      );
      if (responseData.data.success) {
        setCreatedOrderId(responseData.data.data.orderId);
        setSaveStatus((preState) => ({
          ...preState,
          loading: false,
          success: true,
        }));
      } else {
        throw responseData.data.message;
      }
    } catch (error) {
      setSaveStatus((preState) => ({
        ...preState,
        loading: false,
        error: error,
      }));
    }
  };

  const savePreOrder = async (values) => {
    let newOrderValues = { ...values };
    newOrderValues.buyerId = userId;

    setDialog(true);
    try {
      setSaveStatus((preState) => ({
        ...preState,
        loading: true,
      }));
      const responseData = await createNewPreOrderActionRequest(
        newOrderValues,
        config
      );
      if (responseData.data.success) {
        setCreatedOrderId(responseData.data.data.orderId);
        setSaveStatus((preState) => ({
          ...preState,
          loading: false,
          success: true,
        }));
      } else {
        throw responseData.data.message;
      }
    } catch (error) {
      setSaveStatus((preState) => ({
        ...preState,
        loading: false,
        error: error,
      }));
    }
  };

  const closeDialogWithSuccess = () => {
    setDialog(false);
    navigate(`/orders/details/${createdOrderId}`);
  };

  return (
    <div className={commonStyle.pageContainer}>
      <SimpleDialog
        title="Add New Order"
        successMessage="New order added successfully."
        isDialogOpen={dialog}
        closeDialog={() => setDialog(false)}
        loading={saveStatus.loading}
        loadingMessage="Processing request..."
        success={saveStatus.success}
        error={saveStatus.error}
        confirmAction={closeDialogWithSuccess}
      />
      <div className="w-full p-8">
        <Breadcrumbs aria-label="breadcrumb">
          <NavLink underline="hover" color="inherit" to="/">
            Home
          </NavLink>
          <NavLink underline="hover" color="inherit" to="/orders">
            Orders
          </NavLink>
          <NavLink
            underline="hover"
            color="text.primary"
            to={`/orders/new/`}
            aria-current="page"
          >
            New Order
          </NavLink>
        </Breadcrumbs>
        <ScreenTitle title="Add New Order" />

        {showUserField && (
          <div className="w-1/2">
            <div className="w-full flex items-end">
              <div className="w-[80%]">
                <SelectField
                  label="User"
                  placeHolder="Select user"
                  selectOptions={userOptionsSuccess ? userOptions : []}
                  value={userId}
                  extractKey="id"
                  onChange={(id) => setUserId(id)}
                  error={userIdError}
                  clearable={false}
                />
              </div>

              <div className={"w-[calc(20%-8px)]"}>
                <NavLink
                  to={"/users/new"}
                  className={`w-full h-12 bg-brandYellow mb-2 ml-2 text-black flex items-center justify-center rounded px-3 py-1  hover:bg-brandYellowHover`}
                >
                  Add New User
                </NavLink>
              </div>
            </div>
            <SelectField
              label="Product type"
              placeHolder="Select product type"
              selectOptions={[
                { id: 1, label: "In Store Product", value: "instore" },
                { id: 2, label: "Pre-order Product", value: "preorder" },
              ]}
              value={productType}
              onChange={(productType) => setProductType(productType)}
              error={productTypeError}
              clearable={false}
            />
            <div className="mt-6">
              <Button name="Next" action={confirmUserAction} />
            </div>
          </div>
        )}

        {showForm && (
          <div>
            {loading ? (
              <Loader mess={"Requesting watch options..."} />
            ) : success ? (
              isPreOrder ? (
                <PreOrderForm type={"new"} onSave={savePreOrder} />
              ) : (
                <OrderForm onSave={saveOrder} />
              )
            ) : error ? (
              <ErrorMessage mess={error} />
            ) : (
              <></>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
