"use client";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { convtPrice } from "../../../api/functions";
import { consignmentQuoteDetailsApiRequest } from "../../../api/consignmentRequests";
import { getAllBrands, getModelsByBrand } from "../../../api/watchRequests";
import AddressCollector from "../../../components/AddressCollector";
import { BackButton, Button } from "../../../components/buttons";
import {
  FieldLabel,
  PureTextField,
  RatioField,
  SelectField,
  TextAreaField,
  TextField,
} from "../../../components/inputs";
import {
  ErrorMessage,
  H3,
  Loader,
  TwoSteps,
} from "../../../components/smallComponents";
import { K_BUY_METHODS } from "../../../constantData/K_BUY_METHODS";
import { K_COUNTRIES } from "../../../constantData/K_COUNTRIES";
import { K_MONTHS } from "../../../constantData/K_MONTHS";
import { getYears } from "../../../constantData/K_YEARS";
import ImagesUploader from "../../product/ImagesUploader";
import SearchConsignmentWatch from "./SearchConsignmentWatch";

export default function QuotationForm({ type, orderId, onSave }) {
  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo ? userInfo.data.token : ""}`,
    },
  };

  const {
    loading: userDetailsLoading,
    details,
    success: userDetailsSuccess,
    error: userDetailsError,
  } = useSelector((state) => state.userDetails);

  const {
    loading,
    watchOptions,
    success: optionSuccess,
    error,
  } = useSelector((state) => state.watchConstantOptions);

  const [reqStatus, setReqStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const [brandReqStatus, setBrandReqStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const [modelReqStatus, setModelReqStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const [modelInfoManually, setModelInfoManually] = useState(false);
  const [requestBrands, setRequestBrands] = useState(false);
  const [brandOptions, setBrandOptions] = useState([]);
  const [modelOptions, setModelOptions] = useState([]);

  const [watchPrice, setWatchPrice] = useState("");
  const [comission, setComission] = useState("");
  const [serviceFee, setServiceFee] = useState("");
  const [buyoutPrice, setBuyoutPrice] = useState("");

  const [formState, setFormState] = useState({
    watchModel: "",
    brandId: "",
    modelId: "",
    referenceNumber: "",
    year: null,
    serialNumber: "",
    conditionId: "",
    functioning: null,
    notFunctionParts: "",

    hasReceipt: null,
    hasBox: null,
    hasOrigPaper: null,
    origPaperYear: null,
    origPaperMonth: null,

    hasWarranty: null,
    warrantyYear: null,
    warrantyMonth: null,

    hasOrigClasp: null,
    hasOrigStrap: null,
    hasExtract: null,

    hasServicePaper: null,
    serviceYear: null,
    serviceMonth: null,

    buyMethod: null,
    notes: "",

    imageUris: [],
  });

  const [formErrors, setFormErrors] = useState({});

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
  const [shippingAddress, setShippingAddress] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    streetLine1: "",
    city: "",
    region: "",
    country: "",
    postalCode: "",
  });
  const [shippingAddressErrors, setShippingAddressErrors] = useState({});

  useEffect(() => {
    if (userDetailsSuccess && type === "new") {
      let contactDetails = {
        firstName: details.firstName,
        lastName: details.lastName,
        phone: details.phone,
        streetLine1: details.streetLine1,
        region: details.region,
        country: K_COUNTRIES.filter((x) => x.value === details.country)[0],
        city: details.city,
        postalCode: details.postalCode,
      };
      setShippingAddress(contactDetails);
    } 
  }, [userDetailsSuccess, type]);
  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  useEffect(() => {
    const getOrderDetails = async () => {
      setReqStatus({ ...reqStatus, loading: true });
      try {
        const responseData = await consignmentQuoteDetailsApiRequest(
          orderId,
          config
        );
        if (responseData.data.success) {
          let initialData = responseData.data.data;
          let images = [];

          if (initialData.images.length) {
            initialData.images.forEach((x) => {
              const uri = x;
              const fileNameParts = x.split("/");
              const fileName = fileNameParts[fileNameParts.length - 1];

              images.push({
                fileName: fileName,
                isLoading: false,
                error: "",
                uri: uri,
              });
            });
          }

          setModelInfoManually(true);

          let populatedvalues = {
            watchModel: "",
            brandId: initialData.brandId,
            modelId: initialData.modelId,
            referenceNumber: initialData.referenceNumber,
            year: getYears().find((year) => year.value === initialData.year),
            serialNumber: initialData.serialNumber || "",
            conditionId: initialData.conditionId,
            functioning:
              initialData.functioning === 1
                ? true
                : initialData.functioning === 0
                ? false
                : null,
            notFunctionParts: initialData.notFunctionParts || "",

            hasReceipt:
              initialData.hasReceipt === 1
                ? true
                : initialData.hasReceipt === 0
                ? false
                : null,
            hasBox:
              initialData.hasBox === 1
                ? true
                : initialData.hasBox === 0
                ? false
                : null,
            hasOrigPaper:
              initialData.hasPaper === 1
                ? true
                : initialData.hasPaper === 0
                ? false
                : null,
            origPaperYear: getYears().find(
              (year) => year.value === initialData.paperYear
            ),
            origPaperMonth: K_MONTHS.find(
              (month) => month.value === initialData.paperMonth
            ),
            hasWarranty:
              initialData.hasWarranty === 1
                ? true
                : initialData.hasWarranty === 0
                ? false
                : null,
            warrantyYear: getYears().find(
              (year) => year.value === initialData.warrantyYear
            ),
            warrantyMonth: K_MONTHS.find(
              (month) => month.value === initialData.warrantyMonth
            ),

            hasOrigClasp:
              initialData.hasClasp === 1
                ? true
                : initialData.hasClasp === 0
                ? false
                : null,
            hasOrigStrap:
              initialData.hasStrap === 1
                ? true
                : initialData.hasStrap === 0
                ? false
                : null,
            hasExtract:
              initialData.hasExtract === 1
                ? true
                : initialData.hasExtract === 0
                ? false
                : null,
            hasServicePaper:
              initialData.hasService === 1
                ? true
                : initialData.hasService === 0
                ? false
                : null,
            serviceYear: getYears().find(
              (year) => year.value === initialData.serviceYear
            ),
            serviceMonth: K_MONTHS.find(
              (month) => month.value === initialData.serviceMonth
            ),
            buyMethod: K_BUY_METHODS.find(
              (method) => method.value === initialData.buyMethod
            ),
            notes: initialData.notes || "",
            imageUris: images,
          };
          setFormState(populatedvalues);
          if(initialData.contactInfo){
            setShippingAddress(initialData.contactInfo)
          }
          setReqStatus({ ...reqStatus, loading: false, success: true });
        } else {
          if (typeof responseData.data.message === "string") {
            throw responseData.data.message;
          } else {
            throw "Error";
          }
        }
      } catch (error) {
        setReqStatus({ ...reqStatus, loading: false, error: error });
      }
    };

    if (type === "edit") {
      getOrderDetails();
    } else {
      setReqStatus({ ...reqStatus, loading: false, success: true, error: "" });
    }
  }, [type]);

  useEffect(() => {
    const getBrandOptions = async () => {
      try {
        setBrandReqStatus((preState) => ({
          ...preState,
          loading: true,
        }));
        const responseData = await getAllBrands(config);
        if (responseData.data.success) {
          setBrandOptions(responseData.data.data);
          setRequestBrands(true);
          setBrandReqStatus((preState) => ({
            ...preState,
            loading: false,
            success: true,
          }));
        } else {
          throw responseData.data.message;
        }
      } catch (error) {
        setBrandReqStatus((preState) => ({
          ...preState,
          loading: false,
          error: error,
        }));
      }
    };
    if (modelInfoManually && !requestBrands) {
      getBrandOptions();
    }
  }, [requestBrands, modelInfoManually]);

  useEffect(() => {
    const getModelOptions = async () => {
      try {
        setModelReqStatus((preState) => ({
          ...preState,
          loading: true,
        }));

        const responseData = await getModelsByBrand(formState.brandId, config);
        if (responseData.data.success) {
          let options = [];
          let models = responseData.data.data;
          models.forEach((ele) => {
            options.push({
              id: ele.id,
              label: ele.name,
              value: ele.name,
            });
          });
          setModelOptions(options);
          setModelReqStatus((preState) => ({
            ...preState,
            loading: false,
            success: true,
          }));
        } else {
          throw responseData.data.message;
        }
      } catch (error) {
        setModelReqStatus((preState) => ({
          ...preState,
          loading: false,
          error: error,
        }));
      }
    };

    if (modelInfoManually && formState.brandId) {
      getModelOptions();
    }
  }, [formState.brandId, modelInfoManually]);

  const getSelectWatch = (watch) => {
    setFormState({ ...formState, ["watchModel"]: watch });
  };

  const handleManuallyClick = async (value) => {
    setModelInfoManually(value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const type = e.type;

    if (type === "FLOAT") {
      const validated = value.match(/^(\d*\.{0,1}\d{0,2}$)/);
      if (!validated) {
        return;
      }
    }
    if (type === "INT") {
      const validated = value.match(/^(?:\d+)?$/g);
      if (!validated) {
        return;
      }
    }

    setFormState({ ...formState, [name]: value });
  };

  const handlePriceChange = (e) => {
    const { name, value } = e.target;
    const validated = value.match(/^(\d*\.{0,1}\d{0,2}$)/);
    if (!validated) {
      return;
    }

    if (name === "watchPrice") {
      setWatchPrice(value);
      let auto_commision = Number(value) * 0.07;
      auto_commision = auto_commision.toFixed(2);
      auto_commision = Number(auto_commision);
      setComission(auto_commision);
    } else if (name === "comission") {
      setComission(value);
    } else if (name === "serviceFee") {
      setServiceFee(value);
    } else if (name === "buyoutPrice") {
      setBuyoutPrice(value);
    }
  };

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
  const getWatchImages = (files) => {
    setFormState({
      ...formState,
      ["imageUris"]: files,
    });
  };

  const handleSubmit = () => {
    const hasErrors = validate(formState);
    if (Object.keys(hasErrors).length === 0) {
      setFormErrors({});

      let formStateCopy = { ...formState };

      let selectedBrandId = modelInfoManually
        ? formStateCopy.brandId
        : formStateCopy.watchModel.brand_id
        ? formStateCopy.watchModel.brand_id
        : "";
      let selectedModelId = modelInfoManually
        ? formStateCopy.modelId
        : formStateCopy.watchModel.model_id
        ? formStateCopy.watchModel.model_id
        : "";
      let selectedRF = modelInfoManually
        ? formStateCopy.referenceNumber
        : formStateCopy.watchModel.referenceNumber
        ? formStateCopy.watchModel.referenceNumber
        : "";

      let uris = [];
      formStateCopy.imageUris.forEach((ele) => {
        if (ele.uri) {
          uris.push(ele.uri);
        }
      });

      let shippingAddressCopy = { ...shippingAddress };

      if (typeof shippingAddressCopy.country === "object") {
        shippingAddressCopy.countryName = shippingAddressCopy.country.value;
      }

      let quotationValues = {
        brandId: selectedBrandId,
        modelId: selectedModelId,
        referenceNumber: selectedRF,
        year: formStateCopy.year ? formStateCopy.year.value : "",
        conditionId: formStateCopy.conditionId,

        serialNumber: formStateCopy.serialNumber,
        functioning: formStateCopy.functioning,
        notFunctionParts: formStateCopy.notFunctionParts,

        hasReceipt: formStateCopy.hasReceipt,
        hasBox: formStateCopy.hasBox,
        hasOrigPaper: formStateCopy.hasOrigPaper,
        origPaperYear: formStateCopy.origPaperYear
          ? formStateCopy.origPaperYear.value
          : "",
        origPaperMonth: formStateCopy.origPaperMonth
          ? formStateCopy.origPaperMonth.value
          : "",

        hasWarranty: formStateCopy.hasWarranty,
        warrantyYear: formStateCopy.warrantyYear
          ? formStateCopy.warrantyYear.value
          : "",
        warrantyMonth: formStateCopy.warrantyMonth
          ? formStateCopy.warrantyMonth.value
          : "",

        hasOrigClasp: formStateCopy.hasOrigClasp,
        hasOrigStrap: formStateCopy.hasOrigStrap,
        hasExtract: formStateCopy.hasExtract,

        hasServicePaper: formStateCopy.hasServicePaper,
        serviceYear: formStateCopy.serviceYear
          ? formStateCopy.serviceYear.value
          : "",
        serviceMonth: formStateCopy.serviceMonth
          ? formStateCopy.serviceMonth.value
          : "",

        buyMethod: formStateCopy.buyMethod ? formStateCopy.buyMethod.value : "",
        notes: formStateCopy.notes,

        imageUris: uris,
        contactInfo: JSON.stringify(shippingAddressCopy),
        listingPrice: watchPrice,
        comission: comission,
        serviceFee: serviceFee,
        buyoutPrice: buyoutPrice
      };

      onSave(quotationValues);
    } else {
      setFormErrors(hasErrors);
    }
  };

  const validate = (values) => {
    const errors = {};
    if (values.imageUris && values.imageUris.length < 1) {
      errors.watchImages = "Please upload at least one photo of your watch.";
    }

    if (!modelInfoManually && !values.watchModel) {
      errors.watchModel = "Please select your watch model.";
    }

    if (modelInfoManually && !values.brandId) {
      errors.brand = "Please select a brand.";
    }

    if (modelInfoManually && !values.modelId) {
      errors.model = "Please select a model.";
    }

    if (modelInfoManually && !values.referenceNumber) {
      errors.referenceNumber = "Please enter your watch reference number.";
    }

    if (!values.year) {
      errors.year = "Please enter your watch year.";
    }

    if (!values.conditionId) {
      errors.condition = "Please select a condition of your watch.";
    }

    return errors;
  };

  ///////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////

  return (
    <div className="w-full pb-20 pt-4">
      {reqStatus.loading ? (
        <Loader mess="Request details..." />
      ) : reqStatus.success ? (
        <div className="w-full flex items-start flex-row">
          <div className="w-3/5">
            <div className="w-full mt-6">
              <div className="">
                <H3 title={"1. Watch Details"} />
                <FieldLabel label={"Watch Images"} />
                <ImagesUploader
                  initialImages={formState.imageUris}
                  numLimit={20}
                  onWatchUpload={getWatchImages}
                  tipsText={
                    "Upload at least 1 photo showing the front face of the watch for accurate suggested listing price."
                  }
                />

                {formErrors.watchImages && (
                  <p className="w-full mt-2 text-red-500">
                    {formErrors.watchImages}
                  </p>
                )}

                {type === "new" && (
                  <>
                    <FieldLabel label={"Watch Model*"} />
                    <SearchConsignmentWatch
                      onSelect={getSelectWatch}
                      readOnly={modelInfoManually}
                    />
                    {formErrors.watchModel && (
                      <p className="w-full mt-2 text-red-500">
                        {formErrors.watchModel}
                      </p>
                    )}

                    <RatioField
                      name={"manuallyModelInfo"}
                      label={
                        "Can not find my watch? enter watch model manually"
                      }
                      isOn={modelInfoManually}
                      onChange={(value) => handleManuallyClick(value)}
                    />
                  </>
                )}

                {modelInfoManually && (
                  <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-x-6">
                    <SelectField
                      label="Brand*"
                      placeHolder="Select brand"
                      selectOptions={brandOptions}
                      value={formState.brandId}
                      extractKey="id"
                      onChange={(brandId) =>
                        handleChange({
                          type: "ID",
                          target: { value: brandId, name: "brandId" },
                        })
                      }
                      error={formErrors.brand}
                    />
                    <SelectField
                      label="Model*"
                      placeHolder="Select model"
                      selectOptions={modelOptions}
                      value={formState.modelId}
                      extractKey="id"
                      onChange={(modelId) =>
                        handleChange({
                          type: "ID",
                          target: { value: modelId, name: "modelId" },
                        })
                      }
                      error={formErrors.model}
                    />

                    <TextField
                      type="text"
                      label="Reference Number*"
                      name="referenceNumber"
                      autofocus={true}
                      value={formState.referenceNumber}
                      onChange={(referenceNumber) =>
                        handleChange({
                          type: "TEXT",
                          target: {
                            value: referenceNumber,
                            name: "referenceNumber",
                          },
                        })
                      }
                      error={formErrors.referenceNumber}
                    />
                  </div>
                )}

                <div className="w-full">
                  <SelectField
                    label="Year of Product*"
                    placeHolder="Select year"
                    selectOptions={getYears()}
                    value={formState.year}
                    onChange={(year) =>
                      handleChange({
                        type: "OBJECT",
                        target: { value: year, name: "year" },
                      })
                    }
                    error={formErrors.year}
                  />
                  <SelectField
                    label="Condition*"
                    placeHolder="Select type"
                    selectOptions={
                      optionSuccess ? watchOptions.watchConditions : []
                    }
                    value={formState.conditionId}
                    extractKey="id"
                    onChange={(conditionId) =>
                      handleChange({
                        type: "ID",
                        target: { value: conditionId, name: "conditionId" },
                      })
                    }
                    error={formErrors.condition}
                  />
                  <TextField
                    type="text"
                    label="Serial No."
                    name="serialNumber"
                    autofocus={true}
                    value={formState.serialNumber}
                    onChange={(serialNumber) =>
                      handleChange({
                        type: "TEXT",
                        target: { value: serialNumber, name: "serialNumber" },
                      })
                    }
                    error={formErrors.serialNumber}
                  />

                  <div className="w-full">
                    <div className="w-full flex flex-col lg:flex-row justify-between mt-2">
                      <p className="mt-4">Is your watch fully functioning?</p>
                      <div className="flex flex-row">
                        <div className="mr-4">
                          <RatioField
                            name={"functioningYes"}
                            label={"Yes"}
                            isOn={formState.functioning === true}
                            onChange={(functioning) =>
                              handleChange({
                                type: "BOOL",
                                target: {
                                  value: functioning,
                                  name: "functioning",
                                },
                              })
                            }
                          />
                        </div>

                        <RatioField
                          name={"functioningNo"}
                          label={"No"}
                          isOn={formState.functioning === false}
                          onChange={(functioning) =>
                            handleChange({
                              type: "BOOL",
                              target: {
                                value: !functioning,
                                name: "functioning",
                              },
                            })
                          }
                        />
                      </div>
                    </div>

                    {formState.functioning === false && (
                      <div className="mt-4 bg-lightGray rounded p-4">
                        <TextField
                          hasTopMargin={false}
                          type="text"
                          label="If not, which parts are not functioning?"
                          name="notFunctionParts"
                          autofocus={true}
                          value={formState.notFunctionParts}
                          onChange={(notFunctionParts) =>
                            handleChange({
                              type: "TEXT",
                              target: {
                                value: notFunctionParts,
                                name: "notFunctionParts",
                              },
                            })
                          }
                          error={formErrors.notFunctionParts}
                        />
                      </div>
                    )}
                    {formErrors.functioning && (
                      <p className="w-full mt-2 text-red-500">
                        {formErrors.functioning}
                      </p>
                    )}
                  </div>

                  <div className="w-full">
                    <div className="w-full flex flex-col lg:flex-row justify-between mt-2">
                      <p className="mt-4">
                        Do you have the original purchase receipt?
                      </p>
                      <div className="flex flex-row">
                        <div className="mr-4">
                          <RatioField
                            name={"hasReceiptYes"}
                            label={"Yes"}
                            isOn={formState.hasReceipt === true}
                            onChange={(hasReceipt) =>
                              handleChange({
                                type: "BOOL",
                                target: {
                                  value: hasReceipt,
                                  name: "hasReceipt",
                                },
                              })
                            }
                          />
                        </div>

                        <RatioField
                          name={"hasReceiptNo"}
                          label={"No"}
                          isOn={formState.hasReceipt === false}
                          onChange={(hasReceipt) =>
                            handleChange({
                              type: "BOOL",
                              target: {
                                value: !hasReceipt,
                                name: "hasReceipt",
                              },
                            })
                          }
                        />
                      </div>
                    </div>
                    {formErrors.hasReceipt && (
                      <p className="w-full mt-2 text-red-500">
                        {formErrors.hasReceipt}
                      </p>
                    )}
                  </div>
                  <div className="w-full">
                    <div className="w-full flex flex-col lg:flex-row justify-between mt-2">
                      <p className="mt-4">
                        Does your watch come with its original box?
                      </p>
                      <div className="flex flex-row">
                        <div className="mr-4">
                          <RatioField
                            name={"hasBoxYes"}
                            label={"Yes"}
                            isOn={formState.hasBox === true}
                            onChange={(hasBox) =>
                              handleChange({
                                type: "BOOL",
                                target: { value: hasBox, name: "hasBox" },
                              })
                            }
                          />
                        </div>

                        <RatioField
                          name={"hasBoxNo"}
                          label={"No"}
                          isOn={formState.hasBox === false}
                          onChange={(hasBox) =>
                            handleChange({
                              type: "BOOL",
                              target: { value: !hasBox, name: "hasBox" },
                            })
                          }
                        />
                      </div>
                    </div>
                    {formErrors.hasBox && (
                      <p className="w-full mt-2 text-red-500">
                        {formErrors.hasBox}
                      </p>
                    )}
                  </div>

                  <div className="w-full">
                    <div className="w-full flex flex-col lg:flex-row justify-between mt-2">
                      <p className="mt-4">
                        Does your watch have all of its original papers?
                      </p>
                      <div className="flex flex-row">
                        <div className="mr-4">
                          <RatioField
                            name={"hasOrigPaperYes"}
                            label={"Yes"}
                            isOn={formState.hasOrigPaper === true}
                            onChange={(hasOrigPaper) =>
                              handleChange({
                                type: "BOOL",
                                target: {
                                  value: hasOrigPaper,
                                  name: "hasOrigPaper",
                                },
                              })
                            }
                          />
                        </div>

                        <RatioField
                          name={"hasOrigPaperNo"}
                          label={"No"}
                          isOn={formState.hasOrigPaper === false}
                          onChange={(hasOrigPaper) =>
                            handleChange({
                              type: "BOOL",
                              target: {
                                value: !hasOrigPaper,
                                name: "hasOrigPaper",
                              },
                            })
                          }
                        />
                      </div>
                    </div>

                    {formState.hasOrigPaper === true && (
                      <div className="mt-4 bg-lightGray rounded p-4 grid grid-cols-2 gap-6">
                        <SelectField
                          label="Year"
                          placeHolder="Select year"
                          selectOptions={getYears()}
                          value={formState.origPaperYear}
                          onChange={(origPaperYear) =>
                            handleChange({
                              type: "OBJECT",
                              target: {
                                value: origPaperYear,
                                name: "origPaperYear",
                              },
                            })
                          }
                          hasTopMargin={false}
                        />
                        <SelectField
                          label="Month"
                          placeHolder="Select month"
                          selectOptions={K_MONTHS}
                          value={formState.origPaperMonth}
                          onChange={(origPaperMonth) =>
                            handleChange({
                              type: "OBJECT",
                              target: {
                                value: origPaperMonth,
                                name: "origPaperMonth",
                              },
                            })
                          }
                          hasTopMargin={false}
                        />
                      </div>
                    )}
                    {formErrors.hasOrigPaper && (
                      <p className="w-full mt-2 text-red-500">
                        {formErrors.hasOrigPaper}
                      </p>
                    )}
                  </div>

                  <div className="w-full">
                    <div className="w-full flex flex-col lg:flex-row justify-between mt-2">
                      <p className="mt-4">
                        Is there an active warranty from the brand?
                      </p>
                      <div className="flex flex-row">
                        <div className="mr-4">
                          <RatioField
                            name={"hasWarrantyYes"}
                            label={"Yes"}
                            isOn={formState.hasWarranty === true}
                            onChange={(hasWarranty) =>
                              handleChange({
                                type: "BOOL",
                                target: {
                                  value: hasWarranty,
                                  name: "hasWarranty",
                                },
                              })
                            }
                          />
                        </div>

                        <RatioField
                          name={"hasWarrantyNo"}
                          label={"No"}
                          isOn={formState.hasWarranty === false}
                          onChange={(hasWarranty) =>
                            handleChange({
                              type: "BOOL",
                              target: {
                                value: !hasWarranty,
                                name: "hasWarranty",
                              },
                            })
                          }
                        />
                      </div>
                    </div>

                    {formState.hasWarranty === true && (
                      <div className="mt-4 bg-lightGray rounded p-4 grid grid-cols-2 gap-6">
                        <SelectField
                          label="Year"
                          placeHolder="Select year"
                          selectOptions={getYears()}
                          value={formState.warrantyYear}
                          onChange={(warrantyYear) =>
                            handleChange({
                              type: "OBJECT",
                              target: {
                                value: warrantyYear,
                                name: "warrantyYear",
                              },
                            })
                          }
                          hasTopMargin={false}
                        />
                        <SelectField
                          label="Month"
                          placeHolder="Select month"
                          selectOptions={K_MONTHS}
                          value={formState.warrantyMonth}
                          onChange={(warrantyMonth) =>
                            handleChange({
                              type: "OBJECT",
                              target: {
                                value: warrantyMonth,
                                name: "warrantyMonth",
                              },
                            })
                          }
                          hasTopMargin={false}
                        />
                      </div>
                    )}
                  </div>

                  <div className="w-full flex flex-col lg:flex-row justify-between mt-2">
                    <p className="mt-4">
                      Does your watch have its original clasp?
                    </p>
                    <div className="flex flex-row">
                      <div className="mr-4">
                        <RatioField
                          name={"hasOrigClaspYes"}
                          label={"Yes"}
                          isOn={formState.hasOrigClasp === true}
                          onChange={(hasOrigClasp) =>
                            handleChange({
                              type: "BOOL",
                              target: {
                                value: hasOrigClasp,
                                name: "hasOrigClasp",
                              },
                            })
                          }
                        />
                      </div>

                      <RatioField
                        name={"hasOrigClaspNo"}
                        label={"No"}
                        isOn={formState.hasOrigClasp === false}
                        onChange={(hasOrigClasp) =>
                          handleChange({
                            type: "BOOL",
                            target: {
                              value: !hasOrigClasp,
                              name: "hasOrigClasp",
                            },
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="w-full flex flex-col lg:flex-row justify-between mt-2">
                    <p className="mt-4">
                      Does your watch have its original bracelet/strap?
                    </p>
                    <div className="flex flex-row">
                      <div className="mr-4">
                        <RatioField
                          name={"hasOrigStrapYes"}
                          label={"Yes"}
                          isOn={formState.hasOrigStrap === true}
                          onChange={(hasOrigStrap) =>
                            handleChange({
                              type: "BOOL",
                              target: {
                                value: hasOrigStrap,
                                name: "hasOrigStrap",
                              },
                            })
                          }
                        />
                      </div>

                      <RatioField
                        name={"hasOrigStrapNo"}
                        label={"No"}
                        isOn={formState.hasOrigStrap === false}
                        onChange={(hasOrigStrap) =>
                          handleChange({
                            type: "BOOL",
                            target: {
                              value: !hasOrigStrap,
                              name: "hasOrigStrap",
                            },
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="w-full flex flex-col lg:flex-row justify-between mt-2">
                    <p className="mt-4">
                      Do you have Extract from the Archives from the
                      manufacture?
                    </p>
                    <div className="flex flex-row">
                      <div className="mr-4">
                        <RatioField
                          name={"hasExtractYes"}
                          label={"Yes"}
                          isOn={formState.hasExtract === true}
                          onChange={(hasExtract) =>
                            handleChange({
                              type: "BOOL",
                              target: {
                                value: hasExtract,
                                name: "hasExtract",
                              },
                            })
                          }
                        />
                      </div>

                      <RatioField
                        name={"hasExtractNo"}
                        label={"No"}
                        isOn={formState.hasExtract === false}
                        onChange={(hasExtract) =>
                          handleChange({
                            type: "BOOL",
                            target: {
                              value: !hasExtract,
                              name: "hasExtract",
                            },
                          })
                        }
                      />
                    </div>
                  </div>

                  <div className="w-full">
                    <div className="w-full flex flex-col lg:flex-row justify-between mt-2">
                      <p className="mt-4">Do you have the service papers?</p>
                      <div className="flex flex-row">
                        <div className="mr-4">
                          <RatioField
                            name={"hasServicePaperYes"}
                            label={"Yes"}
                            isOn={formState.hasServicePaper === true}
                            onChange={(hasServicePaper) =>
                              handleChange({
                                type: "BOOL",
                                target: {
                                  value: hasServicePaper,
                                  name: "hasServicePaper",
                                },
                              })
                            }
                          />
                        </div>

                        <RatioField
                          name={"hasServicePaperNo"}
                          label={"No"}
                          isOn={formState.hasServicePaper === false}
                          onChange={(hasServicePaper) =>
                            handleChange({
                              type: "BOOL",
                              target: {
                                value: !hasServicePaper,
                                name: "hasServicePaper",
                              },
                            })
                          }
                        />
                      </div>
                    </div>

                    {formState.hasServicePaper === true && (
                      <div className="mt-4 bg-lightGray rounded p-4 grid grid-cols-2 gap-6">
                        <SelectField
                          label="Last Service Year"
                          placeHolder="Select year"
                          selectOptions={getYears()}
                          value={formState.serviceYear}
                          onChange={(serviceYear) =>
                            handleChange({
                              type: "OBJECT",
                              target: {
                                value: serviceYear,
                                name: "serviceYear",
                              },
                            })
                          }
                          hasTopMargin={false}
                        />
                        <SelectField
                          label="Last Service Month"
                          placeHolder="Select month"
                          selectOptions={K_MONTHS}
                          value={formState.serviceMonth}
                          onChange={(serviceMonth) =>
                            handleChange({
                              type: "OBJECT",
                              target: {
                                value: serviceMonth,
                                name: "serviceMonth",
                              },
                            })
                          }
                          hasTopMargin={false}
                        />
                      </div>
                    )}
                  </div>

                  <SelectField
                    label="Where did you purchase this watch?"
                    placeHolder=""
                    selectOptions={K_BUY_METHODS}
                    value={formState.buyMethod}
                    onChange={(buyMethod) =>
                      handleChange({
                        type: "OBJECT",
                        target: { value: buyMethod, name: "buyMethod" },
                      })
                    }
                  />
                  <TextAreaField
                    type="text"
                    label="Any other details you'd like to share about your watch?"
                    name="notes"
                    value={formState.notes}
                    rows={2}
                    placeholder=""
                    onChange={(notes) =>
                      handleChange({
                        type: "TEXT",
                        target: { value: notes, name: "notes" },
                      })
                    }
                  />
                </div>
              </div>

              <div className="mt-6">
                <H3 title={"2. Contact Details"} />
                <AddressCollector
                  formState={shippingAddress}
                  onChange={(address) => setShippingAddress(address)}
                  errors={shippingAddressErrors}
                  required={false}
                />
              </div>
              {
                type === "edit" && <div className="mt-6 w-full">
                <Button
                  name={`Update`}
                  action={handleSubmit}
                />
              </div>
              }

        
            </div>
          </div>
          {type === "new" && (
            <div className="sticky top-32 w-2/5 ml-8 ">
              <H3 title={"Order Summary"} />
              <div className="w-full mt-6">
               
                <div className="w-full bg-lightGray rounded p-4">
                  <p className="text-lg font-medium">1.Standard Consignment</p>
                  <div className="flex py-3 justify-between items-center mt-4">
                    <p className="w-[120px]">Listing Price</p>
                    <PureTextField
                      type="text"
                      name="keyword"
                      placeholder="Price"
                      autofocus={true}
                      value={watchPrice}
                      hasBorder={true}
                      showSearchIcon={false}
                      dollarIcon={true}
                      onChange={(watchPrice) =>
                        handlePriceChange({
                          target: { value: watchPrice, name: "watchPrice" },
                        })
                      }
                    />
                  </div>
                  <div className="flex py-3 justify-between items-center">
                    <p className="w-[120px]">Comission (7%)</p>
                    <PureTextField
                      type="text"
                      name="keyword"
                      placeholder="Commission"
                      autofocus={true}
                      value={comission}
                      hasBorder={true}
                      showSearchIcon={false}
                      dollarIcon={true}
                      onChange={(comission) =>
                        handlePriceChange({
                          target: { value: comission, name: "comission" },
                        })
                      }
                    />
                  </div>

                  <div className="flex py-3 justify-between items-center">
                    <p className="w-[120px]">Service Fee</p>
                    <PureTextField
                      type="text"
                      name="keyword"
                      placeholder="Service fee (if available)"
                      autofocus={true}
                      value={serviceFee}
                      hasBorder={true}
                      showSearchIcon={false}
                      dollarIcon={true}
                      onChange={(serviceFee) =>
                        handlePriceChange({
                          target: { value: serviceFee, name: "serviceFee" },
                        })
                      }
                    />
                  </div>

                  <div className="flex border-t border-solid border-borderGray py-4 font-bold text-xl justify-between items-center">
                    <span>Total</span>
                    <span>
                      $
                      {convtPrice(
                        Number(watchPrice) -
                          Number(comission) -
                          Number(serviceFee)
                      )}
                    </span>
                  </div>
                </div>
                <div className="w-full bg-lightGray rounded p-4 mt-6">
                  <p className="text-lg font-medium">2.Buyout</p>
                  <div className="flex py-3 justify-between items-center mt-4">
                    <p className="w-[120px]">Buyout Price</p>
                    <PureTextField
                      type="text"
                      name="keyword"
                      placeholder="Buyout Price"
                      autofocus={true}
                      value={buyoutPrice}
                      hasBorder={true}
                      showSearchIcon={false}
                      dollarIcon={true}
                      onChange={(buyoutPrice) =>
                        handlePriceChange({
                          target: { value: buyoutPrice, name: "buyoutPrice" },
                        })
                      }
                    />
                  </div>

                  <div className="flex border-t border-solid border-borderGray py-4 font-bold text-xl justify-between items-center">
                    <span>Total</span>
                    <span>
                      ${convtPrice(Number(buyoutPrice))}
                    </span>
                  </div>
                </div>
              </div>

              <div className="mt-6 w-full">
                <Button
                  name={`${type === "new" ? "Submit" : "Update"}`}
                  action={handleSubmit}
                />
              </div>
            </div>
          )}
        </div>
      ) : reqStatus.error ? (
        <ErrorMessage mess={reqStatus.error} />
      ) : (
        <></>
      )}
    </div>
  );
}
