import Breadcrumbs from "@mui/material/Breadcrumbs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import Pagination from "../../components/Pagination";
import ScreenTitle from "../../components/ScreenTitle";
import { EditLinkButton, LinkButton } from "../../components/buttons";
import { ErrorMessage, Loader } from "../../components/smallComponents";
import { brandListRequest } from "../../redux/actions/watchActions";
import commonStyle from "../../style/commonStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle";
import { SearchField } from "../../components/inputs";

const S3_DOMAIN = process.env.REACT_APP_S3_DOMAIN;

export default function BrandList() {
  useDocumentTitle("Brands");
  const dispatch = useDispatch();

  const { loading, brands, success, error } = useSelector(
    (state) => state.brandList
  );
  const pageSize = 20;
  const [pageNum, setPageNum] = useState(0);
  const [keyword, setKeyword] = useState("");

  const onPageChangeRequest = (page) => {
    setPageNum(page.selected);
  };

  useEffect(() => {
    dispatch(brandListRequest(keyword, pageNum, pageSize));
  }, [dispatch, pageNum, keyword]);

  const handleSearchChange = (value) => {
    setKeyword(value);
    setPageNum(0);
  };

  return (
    <div className={commonStyle.pageContainer}>
      <div className={commonStyle.pageCardContentContainer}>
        <div className="w-full p-8">
          <Breadcrumbs aria-label="breadcrumb">
            <NavLink underline="hover" color="inherit" to="/">
              Home
            </NavLink>
            <NavLink underline="hover" color="inherit" to="/watch/brands">
              Brands
            </NavLink>
          </Breadcrumbs>

          <ScreenTitle title="Watch Brands" />

          {/* hold the product list table */}
          <div className="w-1/2">
            <div className="w-full flex justify-end">
              <LinkButton
                path="/watch/brand/new"
                name="Add New Brand"
                width="fit"
              />
            </div>
            <SearchField
              placeholder="Type brand name to search..."
              initialQuery={keyword}
              onChange={handleSearchChange}
            />

            {loading ? (
              <Loader mess="Requesting brands..." />
            ) : success ? (
              <div>
                <table className={commonStyle.pageTable}>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Brnand Name</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {success && brands.brands ? (
                      brands.brands.map((item) => (
                        <React.Fragment key={item.id}>
                          <tr>
                            <td className="w-1/3 h-24 my-2">
                              <div className="w-24 h-24">
                                <img
                                  alt={item.imageUri}
                                  src={
                                    item.imageUri
                                      ? S3_DOMAIN + item.imageUri
                                      : "/default-watch.svg"
                                  }
                                  className="w-full h-full object-contain"
                                  style={{
                                    maxWidth: "100%",
                                    maxHeight: "100%",
                                  }}
                                />
                              </div>
                            </td>
                            <td>{item.name}</td>
                            <td>
                              <EditLinkButton
                                path={`/watch/brand/edit/${item.id}`}
                                data={item}
                              />
                            </td>
                          </tr>
                        </React.Fragment>
                      ))
                    ) : (
                      <tr>
                        <td>
                          <p>There are no brands available.</p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>

                {brands.brands.length > 0 ? (
                  <Pagination
                    totalPageNum={brands.totalPages}
                    forcePage={pageNum}
                    onPageChange={onPageChangeRequest}
                  />
                ) : (
                  <></>
                )}
              </div>
            ) : error ? (
              <ErrorMessage mess={error} />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
