import React, { useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import commonStyle from "../../style/commonStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle";
import ScreenTitle from "../../components/ScreenTitle";
import { NavLink } from "react-router-dom";
import { watchListRequest, deleteWatchRequest } from "../../redux/actions/watchActions";
import { ErrorMessage, Loader } from "../../components/smallComponents";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../components/Pagination";
import { DeleteButton, EditLinkButton } from "../../components/buttons";
import { SearchField } from "../../components/inputs";

const S3_DOMAIN = process.env.REACT_APP_S3_DOMAIN;


export default function WatchList() {
  useDocumentTitle("Watch List");
  const dispatch = useDispatch();

  const { loading, watches, success, error } = useSelector(
    (state) => state.watchList
  );
  const { loading:deleteLoading, success: deleteSuccess, error: deleteError } = useSelector(
    (state) => state.deleteWatch
  );

  const pageSize = 20;
  const [pageNum, setPageNum] = useState(0);
  const [keyword, setKeyword] = useState("");

  const onPageChangeRequest = (page) => {
    setPageNum(page.selected);
  };
  const handleSearchChange = (value) => {
    setKeyword(value);
    setPageNum(0);
  };

  useEffect(() => {
    dispatch(watchListRequest(pageNum, pageSize, keyword));
  }, [dispatch, pageNum, keyword, deleteSuccess]);

  
  const deleteWatch = (watchId) => {
    dispatch(deleteWatchRequest(watchId))
  }

  

  return (
    <div className={commonStyle.pageContainer}>
      <div className="w-full p-8">
        <Breadcrumbs aria-label="breadcrumb">
          <NavLink underline="hover" color="inherit" to="/">
            Home
          </NavLink>
          <NavLink underline="hover" color="inherit" to="/watches">
            Watches
          </NavLink>
        </Breadcrumbs>
        <ScreenTitle title="Watch List" />
        <div className="w-1/2 mt-8">
          <SearchField
            placeholder="Type brand,model, reference number to search..."
            initialQuery={keyword}
            onChange={handleSearchChange}
          />
        </div>

        {loading ? (
          <Loader mess="Requesting watches..." />
        ) : success ? (
          <div className={commonStyle.longDataTable}>
            <table className={commonStyle.pageTable}>
              <thead>
                <tr>
                  <th>Watch Image</th>
                  <th>Brand</th>
                  <th>Model</th>
                  <th>Watch Type</th>
                  <th>Case Diameter</th>
                  <th>Case Material</th>
                  <th>Dial Color</th>
                  <th>Reference Number</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {watches.watches && watches.watches.length > 0 ? (
                  watches.watches.map((item, index) => (
                    <tr key={index}>
                      <td className="p-2 w-24 h-24">
                        <img
                          alt={"watch image " + item.watchImageUri ?  + item.watchImageUri : "/default-watch"}
                          src={S3_DOMAIN + item.watchImageUri ? S3_DOMAIN +item.watchImageUri : "/default-watch.svg"}
                          className="w-24 object-contain"
                        />
                      </td>

                      <td>{item.brandName}</td>
                      <td>{item.modelName}</td>
                      <td>{item.watchTypeName}</td>
                      <td>{item.caseDiameter}</td>
                      <td>{item.caseMaterial}</td>
                      <td>{item.dialColor}</td>
                      <td>{item.referenceNumber}</td>
                      <td>
                        <div className="flex items-center">
                        <EditLinkButton  path={`/watch/edit/${item.id}`} />
                        <DeleteButton action={()=>deleteWatch(item.id)}/>

                        </div>
                       
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>
                      There are no watch available.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {watches.watches && watches.watches.length > 0 ? (
              <Pagination
                totalPageNum={watches.totalPages}
                forcePage={pageNum}
                onPageChange={onPageChangeRequest}
              />
            ) : (
              <></>
            )}
          </div>
        ) : error ? (
          <ErrorMessage mess={error} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
