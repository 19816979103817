import Breadcrumbs from "@mui/material/Breadcrumbs";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { addNewEmailTask } from "../../api/marketingEmailTaksRequets";
import ScreenTitle from "../../components/ScreenTitle";
import { SimpleDialog } from "../../components/dialogs";
import commonStyle from "../../style/commonStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle";
import EmailTaskForm from "./EmailTaskForm";

export default function NewEmailTask() {
  useDocumentTitle("New Email Task");
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  const [saveStatus, setSaveStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const [dialog, setDialog] = useState(false);

  // Calculate three days later
  const getThreeDaysLater = () => {
    const today = new Date();
    today.setDate(today.getDate() + 3); // Add 3 days
    return today;
  };

  const initialvalues = {
    subject: "",
    startTime: getThreeDaysLater(),
    content: "",
    image: "",
    productsType: {id:2, label: "Manually Add", value: "manually"}
  };

  //  dialog
  const handleSave = async (values) => {
    try {
      setDialog(true);
      setSaveStatus({ ...saveStatus, loading: true });
      const responseData = await addNewEmailTask(values, config, dispatch);
      if (responseData.data.success) {
        setSaveStatus({ ...saveStatus, loading: false, success: true });
      } else {
        throw responseData.data.message;
      }
    } catch (error) {
      setSaveStatus({ ...saveStatus, loading: false, error: error });
    }
  };


  const closeDialogWithSuccess = () => {
    setDialog(false);
    navigate("/marketing-email/tasks", { replace: true, from: location });
  };

  return (
    <div className={commonStyle.pageContainer}>
      <SimpleDialog
        title="Add New Email Task"
        successMessage="Email task added successfully."
        isDialogOpen={dialog}
        closeDialog={() => setDialog(false)}
        loading={saveStatus.loading}
        loadingMessage="Processing request..."
        success={saveStatus.success}
        error={saveStatus.error}
        confirmAction={closeDialogWithSuccess}
      />

      <div className="w-full p-8">
        <Breadcrumbs aria-label="breadcrumb">
          <NavLink underline="hover" color="inherit" to="/">
            Home
          </NavLink>
          <NavLink
            underline="hover"
            color="inherit"
            to="/marketing-email/tasks"
          >
            Email Tasks
          </NavLink>
          <NavLink
            underline="hover"
            color="text.primary"
            to={`/marketing-email/tasks/new`}
            aria-current="page"
          >
            New Task
          </NavLink>
        </Breadcrumbs>
        <ScreenTitle title="Add New Email Task" />

        <EmailTaskForm
          type="new"
          onSave={handleSave}
          initialValues={initialvalues}
        />
      </div>
    </div>
  );
}
