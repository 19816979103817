import Breadcrumbs from "@mui/material/Breadcrumbs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { addNewProductByDealerRequest, getWatchDetailsRequest } from "../../api/productsRequests";
import ScreenTitle from "../../components/ScreenTitle";
import { SimpleDialog } from "../../components/dialogs";
import { ErrorMessage, Loader } from "../../components/smallComponents";
import {
  watchConstantOptionsRequest
} from "../../redux/actions/watchActions";
import commonStyle from "../../style/commonStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle";
import ProductDetailsForm from "./ProductDetailsForm";
import { Button } from "../../components/buttons";
import { SelectField } from "../../components/inputs";
import { userListOptionsRequest } from "../../redux/actions/userActions";
import SearchWatch from "./SearchWatch";

export default function NewProductByDealer() {
  useDocumentTitle("Add New Product");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo ? userInfo.data.token : ""}`,
    },
  };

  const { loading, watchOptions, success, error } = useSelector(
    (state) => state.watchConstantOptions
  );
  const { loading:userOptionsLoading, userOptions, success:userOptionsSuccess, error:userOptionsError } = useSelector(
    (state) => state.userOptionsList
  );

  const [formState, setFormState] = useState({});

  const [dialog, setDialog] = useState(false);
  const [reqStatus, setReqStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const [saveStatus, setSaveStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const [watchId, setWatchId] = useState("");
  const [dealerId, setDealerId] = useState(null);
  const [dealerIdError, setDealerIdErro] = useState("");
  const [showDealerField, setShowDealerField] = useState(true);
  const [showSearchField, setShowSearchField] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const [productId, setProductId] = useState("");

  useEffect(() => {
    const getWatchInfo = async () => {
      try {
        setReqStatus((preState) => ({
          ...preState,
          loading: true,
        }));
        const responseData = await getWatchDetailsRequest(
          watchId,
          config,
          dispatch
        );
        if (responseData.data.success) {
          let watch = responseData.data.data;
          watch.year = "";
          watch.conditionId = "";
          watch.scopeId = "";
          watch.serialNumber = "";
          watch.price = "";
          watch.description = "";
          watch.imageUris = [];
          watch.ownerProofOne = "";
          watch.ownerProofTwo = "";

          setFormState(watch);
          setReqStatus((preState) => ({
            ...preState,
            loading: false,
            success: true,
          }));
          setShowForm(true);
        } else {
          throw responseData.data.message;
        }
      } catch (error) {
        setReqStatus((preState) => ({
          ...preState,
          loading: false,
          error: error,
        }));
      }
    };
    if (watchId) {
      getWatchInfo();
    }
  }, [watchId]);

  const submitListing = async (values) => {
    if(!dealerId){
      setSaveStatus((preState) => ({
        ...preState,
        loading: false,
        success: false,
        error: "Please select a dealer",
      }));
      return;
    }
    let submittedValues = values;
    if (typeof values.contact.country === "object") {
      submittedValues.contact.countryName = values.contact.country.value;
    }
    let uris = submittedValues.imageUris;
    let pureUris = [];
    uris.forEach((x) => {
      if (x.uri) {
        pureUris.push(x.uri);
      }
    });
    submittedValues.productType = "SELLERPRODUCT";
    submittedValues.watchImages = pureUris;
    submittedValues.ownerProofImage1 = values.ownerProofOne.uri
    ? values.ownerProofOne.uri
    : "";
    submittedValues.ownerProofImage2 = values.ownerProofTwo.uri
    ? values.ownerProofTwo.uri
    : "";
   
    try {
      setSaveStatus((preState) => ({
        ...preState,
        loading: true,
      }));
      const responseData = await addNewProductByDealerRequest(
        dealerId,
        "",
        values,
        config,
      );
      if (responseData.data.success) {
        setProductId(responseData.data.data);
        setDialog(true);
        setSaveStatus((preState) => ({
          ...preState,
          loading: false,
          success: true,
        }));
      } else {
        throw responseData.data.message;
      }
    } catch (error) {
      setSaveStatus((preState) => ({
        ...preState,
        loading: false,
        error: error,
      }));
    }
  };

  useEffect(() => {
    dispatch(watchConstantOptionsRequest());
    dispatch(userListOptionsRequest("dealer"));
  }, []);


  const confirmDealerAction = () => {
    if (!dealerId) {
      setDealerIdErro("Please select a dealer");
      return;
    }
    setShowDealerField(false);
    setShowSearchField(true);
  };

  const handleWatchSelect = (id) => {
    setWatchId(id);
    setShowSearchField(false);
    setShowForm(true);
  };

  const closeDialogWithSuccess = () => {
    setDialog(false);
    navigate(`/products/details/${productId}`);

  }

  return (
    <div className={commonStyle.pageContainer}>
      
       <SimpleDialog
        title="Add New Product"
        successMessage="New product added successfully."
        isDialogOpen={dialog}
        closeDialog={() => setDialog(false)}
        loading={saveStatus.loading}
        loadingMessage="Processing request..."
        success={saveStatus.success}
        error={saveStatus.error}
        confirmAction={closeDialogWithSuccess}
      />
      <div className="w-full p-8">
        <Breadcrumbs aria-label="breadcrumb">
          <NavLink underline="hover" color="inherit" to="/">
            Home
          </NavLink>
          <NavLink underline="hover" color="inherit" to="/products">
            Products
          </NavLink>
          <NavLink
            underline="hover"
            color="text.primary"
            to={`/products/new/`}
            aria-current="page"
          >
            New Product
          </NavLink>
        </Breadcrumbs>
        <ScreenTitle title="Add New Product on Behalf of Dealer" />

        {showDealerField && (
          <div className="w-1/2">
            <SelectField
              label="Select a dealer"
              placeHolder="Select dealer"
              selectOptions={userOptionsSuccess ? userOptions : []}
              value={dealerId}
              extractKey="id"
              onChange={(id) => setDealerId(id)}
              error={dealerIdError}
            />
            <div className="mt-4">
              <Button name="Next" action={confirmDealerAction} />
            </div>
          </div>
        )}

        {showSearchField && (
          <div className="mt-8 w-1/2">
            <p className="mb-2 text-base ">Select a watch to start</p>
            <SearchWatch onSelect={handleWatchSelect} />
          </div>
        )}

        {success && showForm ? (
          <>
            {reqStatus.loading ? (
              <Loader mess="Requeting watch options..." />
            ) : reqStatus.success ? (
              <ProductDetailsForm
                type={"new"}
                onSave={submitListing}
                submitLoading={saveStatus.loading}
                submitError={saveStatus.error}
                initialDetails={formState}
                dealerId={dealerId}
                contactInfoRequired={true}
              />
            ) : reqStatus.error ? (
              <ErrorMessage mess={reqStatus.error} />
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
