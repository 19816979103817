function formatDate(
  date = new Date(),
  format = "DD/MM/YYYY",
  timeZone = "Pacific/Auckland",
  withTime = false
) {
  let formattedDate = "";

  const year = date.toLocaleString("en-US", {
    timeZone: timeZone,
    year: "numeric",
  });
  const month = date.toLocaleString("default", {
    month: "2-digit",
    timeZone: timeZone,
  });
  const day = date.toLocaleString("en-US", {
    timeZone: timeZone,
    day: "2-digit",
  });

  const time = date.toLocaleTimeString("default", { timeZone: timeZone });

  if (withTime) {
    if (format === "MM/DD/YYYY") {
      formattedDate = [month, day, year].join("/") + "  " + time;
    } else if (format === "DD/MM/YYYY") {
      formattedDate = [day, month, year].join("/") + "  " + time;
    } else if (format === "YYYY/MM/DD") {
      formattedDate = [year, month, day].join("/") + "  " + time;
    } else {
      formattedDate = [day, month, year].join("/") + "  " + time;
    }
  } else {
    if (format === "MM/DD/YYYY") {
      formattedDate = [month, day, year].join("/");
    } else if (format === "DD/MM/YYYY") {
      formattedDate = [day, month, year].join("/");
    } else if (format === "YYYY/MM/DD") {
      formattedDate = [year, month, day].join("/");
    } else {
      formattedDate = [day, month, year].join("/");
    }
  }

  return formattedDate;
}

function getTimeStamp(day, timeZone) {
  //new Date() is current time (whatever the zone - time object)
  let date = new Date();

  //displays the current date (no time, so midnight) in the specified zone (string of time with zone ref)
  let userTimeZoneDate = date.toLocaleDateString("en-US", {
    timeZone: timeZone,
  });
  //new Date(<above midnight time in zone>) parse the midnight time in zone and display corresponding current timezone time (time object)
  let newDate = new Date(userTimeZoneDate);
  // convert to timeStamp
  let timeStamp = Math.floor(newDate.getTime() / 1000);
  return timeStamp;
}

function getTimeStamps(day, timeZone) {
  let now = new Date();
  let todayMidnight = new Date(
    now.toLocaleDateString("en-US", { timeZone: timeZone })
  );

  let todayStart = Math.floor(todayMidnight.getTime() / 1000);
  let todayEnd = Math.floor(
    (todayMidnight.setDate(todayMidnight.getDate() + 1) - 1) / 1000
  );

  let startTimestamp = todayStart;
  let endTimestamp = todayEnd;

  let startDate;
  let endDate;
  switch (day) {
    case "today":
      return [startTimestamp, endTimestamp];
    case "7days":
      startDate = new Date(todayMidnight.setDate(todayMidnight.getDate() - 8));
      startTimestamp = Math.floor(startDate.getTime() / 1000);

      endDate = new Date(startDate.setDate(startDate.getDate() + 6));
      endTimestamp = Math.floor(endDate.setHours(23, 59, 59, 999) / 1000);

      return [startTimestamp, endTimestamp];
    case "15days":
      startDate = new Date(todayMidnight.setDate(todayMidnight.getDate() - 16));
      startTimestamp = Math.floor(startDate.getTime() / 1000);

      endDate = new Date(startDate.setDate(startDate.getDate() + 14));
      endTimestamp = Math.floor(endDate.setHours(23, 59, 59, 999) / 1000);

      return [startTimestamp, endTimestamp];

    case "30days":
      startDate = new Date(todayMidnight.setDate(todayMidnight.getDate() - 31));
      startTimestamp = Math.floor(startDate.getTime() / 1000);

      endDate = new Date(startDate.setDate(startDate.getDate() + 29));
      endTimestamp = Math.floor(endDate.setHours(23, 59, 59, 999) / 1000);

      return [startTimestamp, endTimestamp];
    default:
      return [startTimestamp, endTimestamp];
  }
}

function convToNZDate(date) {

  //new Date() is current time (whatever the zone - time object)
  //displays the current date (no time, so midnight) in the specified zone (string of time with zone ref)
  let nzTimeZoneDate = date.toLocaleDateString("en-US", {
    timeZone: "Pacific/Auckland",
  });
  //new Date(<above midnight time in zone>) parse the midnight time in zone and display corresponding current timezone time (time object)
  let nzdate = new Date(nzTimeZoneDate);

  // convert to timeStamp
  let timeStamp = Math.floor(nzdate.getTime() / 1000);
  return timeStamp;
}

function convtPrice(num){
  let org_num = Number(num);
  let new_num = (Math.round(org_num * 100) / 100).toFixed(2);
  return new_num
  
}

function convtQuantity(num){
  const org_num = Number(num);
  const roundedNumber = Number(org_num.toFixed(3)); // Round to three decimal places
  return roundedNumber.toString();

}

function convToLocalTime(timestamp) {
  const date = new Date(timestamp * 1000);
  const formattedDate = date.toLocaleDateString("en-US");
  let hour = date.getHours();
  let mins = date.getMinutes();

  const formattedTime = formattedDate + " " + hour + ":" + mins

  return formattedTime;
}


function convToLocalDate(timestamp) {
  const date = new Date(timestamp * 1000);
  const formattedDate = date.toLocaleDateString("en-US");
  return formattedDate;
}


export { formatDate, getTimeStamp, getTimeStamps, convToNZDate, convtPrice, convtQuantity, convToLocalDate, convToLocalTime };
