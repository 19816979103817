import Breadcrumbs from "@mui/material/Breadcrumbs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import AddressCollector from "../../components/AddressCollector";
import ScreenTitle from "../../components/ScreenTitle";
import SingleImageUploader from "../../components/SingleImageUploader";
import { Button } from "../../components/buttons";
import { SimpleDialog } from "../../components/dialogs";
import { SelectField, TextAreaField, TextField } from "../../components/inputs";
import { H3 } from "../../components/smallComponents";
import { newUserRequest } from "../../redux/actions/userActions";
import commonStyle from "../../style/commonStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle";
import CompanyInfoCollector from "./CompanyInfoCollector";

export default function NewUser() {
  useDocumentTitle("Add New User");
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const { loading, success, userId, error } = useSelector(
    (state) => state.newUser
  );

  const [isSeller, setIsSeller] = useState(true);

  const [address, setAddress] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    streetLine1: "",
    city: "",
    region: "",
    country: "",
    postalCode: "",
  });

  const [companyInfo, setCompanyInfo] = useState({
    companyName: "",
    einNumber: "",
    companyWeb: "",
    description: "",
  });

  const [formState, setFormState] = useState({
    email: "",
    userType: { id: 1, label: "Dealer", value: "Dealer" },
    avatar: "",
  });

  const [formErrors, setFormErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
    if(value && value.value && value.value === "Normal User"){
      setIsSeller(false);
    }
  };
  

  const getUploadedImages = (file) => {
    if (file.uri) {
      setFormState({ ...formState, ["avatar"]: file.uri });
    }
  };

  // // // // // //

  const validate = (values) => {
    const errors = {};
    if (!values.accountInfo.email) {
      errors.email = "User email is required.";
    }
    if(isSeller){
      if (!values.companyInfo.companyName) {
        errors.companyName = "Company name is required.";
      }
    }
    
    if (!values.contact.firstName) {
      errors.firstName = "First name is required.";
    }
    if (!values.contact.lastName) {
      errors.lastName = "Last name is required.";
    }
    if (!values.contact.phone) {
      errors.phone = "Phone is required.";
    }
    if (!values.contact.streetLine1) {
      errors.streetLine1 = "street line is required.";
    }
    if (!values.contact.country) {
      errors.country = "Country is required.";
    }
    if (!values.contact.postalCode) {
      errors.postalCode = "Postal code is required.";
    }
    if (!values.contact.city) {
      errors.city = "City is required.";
    }
    return errors;
  };

  //  dialog
  const [dialog, setDialog] = useState(false);

  const checkValidation = () => {
    let checkValues = {
      contact: address,
      companyInfo: companyInfo,
      accountInfo: formState,
    };

    const hasErrors = validate(checkValues);

    if (Object.keys(hasErrors).length === 0) {
      setFormErrors({});
      setDialog(true);
      let loginValues = {
        name: isSeller ? companyInfo.companyName : address.firstName,
        email: formState.email,
      };

      let contactInfo = { ...address };

      if (typeof contactInfo.country === "object") {
        contactInfo.countryName = contactInfo.country.value;
      }

      let registerValues = {
        loginDetails: loginValues,
        contact: contactInfo,
        companyInfo: companyInfo,
        frontImage: "",
        backImage: "",
        avatar: formState.avatar,
      };
      dispatch(newUserRequest(formState.userType.value, registerValues));
    } else {
      setFormErrors(hasErrors);
    }
  };

  const closeDialogWithSuccess = () => {
    setDialog(false);
    navigate(`/users/details/${userId}`, { replace: true, from: location });
  };

  return (
    <div className={commonStyle.pageContainer}>
      <SimpleDialog
        title="New User"
        successMessage="New User added successfully."
        isDialogOpen={dialog}
        closeDialog={() => setDialog(false)}
        loading={loading}
        loadingMessage="Processing request..."
        success={success}
        error={error}
        confirmAction={closeDialogWithSuccess}
      />

      <div className="w-full p-8">
        <Breadcrumbs aria-label="breadcrumb">
          <NavLink underline="hover" color="inherit" to="/">
            Home
          </NavLink>
          <NavLink underline="hover" color="inherit" to="/users">
            Users
          </NavLink>
          <NavLink
            underline="hover"
            color="text.primary"
            to={`/users/new`}
            aria-current="page"
          >
            New User
          </NavLink>
        </Breadcrumbs>
        <ScreenTitle title="Add New User" />
        <div className="w-full flex flex-row mt-6">
          <div className="w-2/3">
            <div className="">
              <H3 title="Account Information" />
            </div>

            <SelectField
              label="User Type*"
              placeHolder=""
              selectOptions={[
                { id: 1, label: "Dealer", value: "Dealer" },
                { id: 2, label: "Verified Seller", value: "Verified Seller" },
                { id: 2, label: "General User", value: "Normal User" },
              ]}
              value={formState.userType}
              onChange={(userType) =>
                handleChange({
                  target: { value: userType, name: "userType" },
                })
              }
              error={formErrors.userType}
            />

            <TextField
              type="text"
              label="Email*"
              name="email"
              autofocus={true}
              value={formState.email}
              onChange={(email) =>
                handleChange({
                  target: { value: email, name: "email" },
                })
              }
              error={formErrors.email}
              readOnly={loading ? true : false}
            />

            {isSeller && (
                <div className="mt-6">
                  <H3 title="Company Information" />
                  <CompanyInfoCollector
                    formState={companyInfo}
                    errors={formErrors}
                    onChange={(details) => setCompanyInfo(details)}
                  />
                </div>
              )}

            <div className="mt-6">
              <H3 title="Contact Information" />
            </div>

            <AddressCollector
              formState={address}
              errors={formErrors}
              onChange={(address) => setAddress(address)}
            />
            <div className="my-4">
              <Button name="Save" action={checkValidation} />
            </div>
          </div>
          <div className="w-1/3 px-6">
            <h2 className="mb-2">User Logo</h2>
            <SingleImageUploader
              onUpload={getUploadedImages}
              imageError={formErrors.avatar}
              uri={formState.avatar}
              folderName="user"
              compress={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
