import React, { useEffect, useState } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import AddressCollector from "../../components/AddressCollector";
import {
  BackButton,
  Button,
  LoadingButton
} from "../../components/buttons";
import {
  FieldLabel,
  RatioField,
  ReadOnlyField,
  SelectField,
  TextAreaField,
  TextField,
} from "../../components/inputs";
import { ErrorMessage, H1, H2 } from "../../components/smallComponents";
import { K_COUNTRIES } from "../../constantData/K_COUNTRIES";
import { userDetailsRequest } from "../../redux/actions/userActions";
import ImagesUploader from "./ImagesUploader";
import ListingSteps from "./ListingSteps";
import WatchPreviewImage from "./WatchPreviewImage";
import { getAllBrands, getModelsByBrand } from "../../api/watchRequests";


export default function ProductDetailsForm({
  dealerId,
  type,
  onSave,
  submitLoading,
  submitError,
  initialDetails,
}) {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo ? userInfo.data.token : ""}`,
    },
  };

  const { loading, details, success, error } = useSelector(
    (state) => state.userDetails
  );

  const { watchOptions } = useSelector((state) => state.watchConstantOptions);

  const [formState, setFormState] = useState(initialDetails);
  const [proofOne, setProofOne] = useState("");
  const [proofTwo, setProofTwo] = useState("");

  const [formErrors, setFormErrors] = useState({});
  const [showAdditionalInfo, setShowAdditionalInfo] = useState("");
  const [formContactErrors, setFormContactErrors] = useState({});
  const [formImagesError, setFormImagesError] = useState("");

  const [step1, setStep1] = useState(true);
  const [step2, setStep2] = useState(false);
  const [step3, setStep3] = useState(false);
  const [step4, setStep4] = useState(false);

  const [enterModalName, setEnterModalName] = useState(false);
  const [brandOptions, setBrandOptions] = useState([]);
  const [modelOptions, setModelOptions] = useState([]);
  const [brandReqStatus, setBrandReqStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const [modelReqStatus, setModelReqStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });



  useEffect(() => {
    const getBrandOptions = async () => {
      try {
        setBrandReqStatus((preState) => ({
          ...preState,
          loading: true,
        }));
        const responseData = await getAllBrands(config);
        if (responseData.data.success) {
          setBrandOptions(responseData.data.data);
          setBrandReqStatus((preState) => ({
            ...preState,
            loading: false,
            success: true,
          }));
        } else {
          throw responseData.data.message;
        }
      } catch (error) {
        setBrandReqStatus((preState) => ({
          ...preState,
          loading: false,
          error: error,
        }));
      }
    };
    getBrandOptions();
  }, []);

  useEffect(() => {
    const getModelOptions = async () => {
      try {
        setModelReqStatus((preState) => ({
          ...preState,
          loading: true,
        }));

        const responseData = await getModelsByBrand(formState.brandId, config);
        if (responseData.data.success) {
          let options = [];
          let models = responseData.data.data;
          models.forEach((ele) => {
            options.push({
              id: ele.id,
              label: ele.name,
              value: ele.name,
            });
          });
          setModelOptions(options);
          setModelReqStatus((preState) => ({
            ...preState,
            loading: false,
            success: true,
          }));
        } else {
          throw responseData.data.message;
        }
      } catch (error) {
        setModelReqStatus((preState) => ({
          ...preState,
          loading: false,
          error: error,
        }));
      }
    };
    if (formState.brandId) {
      getModelOptions();
    }
  }, [formState.brandId]);


  useEffect(() => {
    if (type === "new") {
      dispatch(userDetailsRequest(dealerId));
    }
  }, [type]);

  useEffect(() => {
    if (type === "new") {
      let contact = {
        firstName: "",
        lastName: "",
        phone: "",
        streetLine1: "",
        region: "",
        country: "",
        city: "",
        postalCode: "",
      };
      let copy = { ...formState };

      if (success) {
        let contactDetails = {
          firstName: details.firstName,
          lastName: details.lastName,
          phone: details.phone,
          streetLine1: details.streetLine1,
          region: details.region,
          country: K_COUNTRIES.filter((x) => x.value === details.country)[0],
          city: details.city,
          postalCode: details.postalCode,
        };

        copy.contact = contactDetails;
      } else {
        copy.contact = contact;
      }
      setFormState(copy);
    }
  }, [success, type]);

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    const handleManuallyNameChange = (e) => {
      const { name, value } = e.target;
      const type = e.type;
  
      setFormState({
        ...formState,
        [name]: value,
        ["modelId"]: "",
      });
    };
    const handleModelIdChange = (e) => {
      const { name, value } = e.target;
      const type = e.type;
      let find_new_model = modelOptions.find((x) => x.id === value);
      let new_model_name = "";
      if (find_new_model) {
        new_model_name = find_new_model.label;
      }
  
      setFormState({
        ...formState,
        [name]: value,
        ["modelName"]: new_model_name,
      });
    };
  
    const handleModelNameInput = (e) => {
      e.preventDefault();
      setEnterModalName(true);
    };
  //////////////////////////////////////////////////////////////////
  const handleShowButton = (e, value) => {
    e.preventDefault();
    if (value === showAdditionalInfo) {
      setShowAdditionalInfo("");
    } else {
      setShowAdditionalInfo(value);
    }
  };
  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  const handleChange = (e) => {
    const { name, value } = e.target;
    const type = e.type;

    if (type === "FLOAT") {
      const validated = value.match(/^(\d*\.{0,1}\d{0,2}$)/);
      if (!validated) {
        return;
      }
    }
    if (type === "INT") {
      const validated = value.match(/^(?:\d+)?$/g);
      if (!validated) {
        return;
      }
    }

    setFormState({ ...formState, [name]: value });
  };

  const handleRatioChange = (e) => {
    const { id, value } = e.target;
    const type = e.type;

    let copy = { ...formState };
    let typeArray = copy[type]; // an array

    // check if id exist

    if (typeArray.includes(id)) {
      let existIndex = typeArray.indexOf(id);
      if (!value) {
        typeArray.splice(existIndex, 1);
      }
    } else {
      if (value) {
        typeArray.push(id);
      }
    }
    setFormState(copy);
  };

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
  const getWatchImages = (files) => {
    setFormState({
      ...formState,
      ["imageUris"]: files,
    });
  };

  const handleDetailsSubmit = () => {
    const hasErrors = validate(formState);

    if (Object.keys(hasErrors).length === 0) {
      setFormErrors({});
      setStep1(false);
      setStep2(true);
    } else {
      setFormErrors(hasErrors);
    }
  };

  const validate = (values) => {
    const errors = {};

    if (!values.modelName) {
      errors.modelName = "Watch model is required.";
    }
    if (!values.watchTypeId) {
      errors.watchType = "Watch type is required.";
    }

    if (!values.genderId) {
      errors.gender = "Watch gender is required.";
    }

    if (!values.movementId) {
      errors.movement = "Watch movement is required.";
    }

    if (!values.conditionId) {
      errors.condition = "Watch condition is required.";
    }
    if (!values.scopeId) {
      errors.scope = "Watch scope of delivery is required.";
    }
    if (!values.price) {
      errors.price = "Watch price is required.";
    }

    return errors;
  };

  const handleContactDetailsSubmit = () => {
    const hasErrors = contactValidate(formState.contact);

    if (Object.keys(hasErrors).length === 0) {
      setFormContactErrors({});

      setStep3(false);
      setStep4(true);
    } else {
      setFormContactErrors(hasErrors);
    }
  };

  const contactValidate = (values) => {
    const errors = {};

    if (!values.firstName) {
      errors.firstName = "First name is required.";
    }

    if (!values.lastName) {
      errors.lastName = "Last name is required.";
    }

    if (!values.phone) {
      errors.phone = "Phone is required.";
    }

    if (!values.streetLine1) {
      errors.streetLine1 = "Street is required.";
    }
    if (!values.city) {
      errors.city = "City is required.";
    }
    if (!values.country) {
      errors.country = "Country is required.";
    }
    if (!values.postalCode) {
      errors.postalCode = "Postal code is required.";
    }

    return errors;
  };

  const handleImagesSubmit = () => {
    const hasErrors = imagesValidate({
      watchImages: formState.imageUris,
      ownerProofOne: proofOne,
      ownerProofTwo: proofTwo,
    });

    if (!hasErrors) {
      setFormImagesError("");
      setStep2(false);
      setStep3(true);
    } else {
      setFormImagesError(hasErrors);
    }
  };
  const imagesValidate = (values) => {
    let error = "";

    if (!values.watchImages.length) {
      error = "Please add at least one watch photo.";
    }

    return error;
  };

  const handleStepBack = (stepName) => {
    if (stepName === "step1") {
      setStep1(true);
      setStep2(false);
    }
    if (stepName === "step2") {
      setStep2(true);
      setStep3(false);
    }
    if (stepName === "step3") {
      setStep3(true);
      setStep4(false);
    }
  };

  ///////////////////////////////////////////////////////////////

  const handleSaveListing = () => {
    let submittedValues = { ...formState };
    submittedValues.ownerProofOne = proofOne;
    submittedValues.ownerProofTwo = proofTwo;
    onSave(submittedValues);
  };

  //////////////////////////////////////////////////////////////

  const AdditionalInfoTitle = ({ title, num }) => {
    return (
      <div className="flex items-center">
        <p>{title}</p>
        <span className="text-brandYellow text-sm ml-4">{num} Suggestions</span>
      </div>
    );
  };

  return (
    <div className="w-full pb-20 pt-4">
      <ListingSteps step1={step1} step2={step2} step3={step3} step4={step4} />

      <div className="w-full flex">
        <div className="w-full lg:w-3/5">
          {/* step 1 start */}
          {step1 && (
            <div className="w-full">
              <div className="w-full">
                <H1 title="Watch Details" />
                <div className="mt-6">
                  <H2 title="Basic Information" subText="(Required)" />
                </div>

                <div className="w-full flex flex-col lg:flex-row">
                  <div className="w-full lg:w-1/2 lg:mr-8">
                  <SelectField
                    label="Brand*"
                    placeHolder="Select brand"
                    selectOptions={brandReqStatus.success ? brandOptions : []}
                    value={formState.brandId}
                    clearable={false}
                    extractKey="id"
                    onChange={(brandId) =>
                      handleChange({
                        type: "ID",
                        target: { value: brandId, name: "brandId" },
                      })
                    }
                    error={formErrors.brand}
                  />
                    <SelectField
                      label="Type of Watch*"
                      placeHolder="Select type"
                      selectOptions={watchOptions.watchTypes}
                      value={formState.watchTypeId}
                      extractKey="id"
                      onChange={(watchTypeId) =>
                        handleChange({
                          type: "ID",
                          target: { value: watchTypeId, name: "watchTypeId" },
                        })
                      }
                      error={formErrors.watchType}
                    />
                    <SelectField
                      label="Gender*"
                      placeHolder="Select gender"
                      selectOptions={watchOptions.genders}
                      value={formState.genderId}
                      extractKey="id"
                      onChange={(genderId) =>
                        handleChange({
                          type: "ID",
                          target: { value: genderId, name: "genderId" },
                        })
                      }
                      error={formErrors.gender}
                    />
                    <div className="flex w-full">
                      <div className="w-1/2">
                        <TextField
                          type="text"
                          label="Case Diameter"
                          name="caseDiameterWidth"
                          autofocus={true}
                          value={formState.caseDiameterWidth}
                          onChange={(caseDiameterWidth) =>
                            handleChange({
                              type: "FLOAT",
                              target: {
                                value: caseDiameterWidth,
                                name: "caseDiameterWidth",
                              },
                            })
                          }
                          unit="mm"
                        />
                      </div>

                      <span className="mx-4 mt-16">X</span>
                      <div className="mt-6 w-1/2">
                        <TextField
                          type="text"
                          label=""
                          name="caseDiameterHeight"
                          autofocus={true}
                          value={formState.caseDiameterHeight}
                          onChange={(caseDiameterHeight) =>
                            handleChange({
                              type: "FLOAT",
                              target: {
                                value: caseDiameterHeight,
                                name: "caseDiameterHeight",
                              },
                            })
                          }
                          unit="mm"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="w-full lg:w-1/2">
                  {enterModalName ? (
                      <div>
                        <TextField
                          type="text"
                          label="Model*"
                          name="modelName"
                          value={formState.modelName}
                          onChange={(modelName) =>
                            handleManuallyNameChange({
                              type: "TEXT",
                              target: {
                                value: modelName,
                                name: "modelName",
                              },
                            })
                          }
                          error={formErrors.modelName}
                        />
                        <p className="mt-2 w-full text-gray text-right hover:brandYellow">
                          Enter modal name manually
                        </p>
                      </div>
                    ) : (
                      <div>
                        <SelectField
                          label="Model*"
                          placeHolder="Select model"
                          selectOptions={
                            modelReqStatus.success ? modelOptions : []
                          }
                          clearable={false}
                          value={formState.modelId}
                          extractKey="id"
                          onChange={(modelId) =>
                            handleModelIdChange({
                              type: "ID",
                              target: { value: modelId, name: "modelId" },
                            })
                          }
                          error={formErrors.modelName}
                        />
                        <button
                          className="w-full mt-2"
                          onClick={handleModelNameInput}
                        >
                          <p className="w-full text-gray text-right hover:brandYellow">
                            Can not find my model?
                          </p>
                        </button>
                      </div>
                    )}

                    <TextField
                      type="text"
                      label="Reference Number*"
                      name="referenceNumber"
                      autofocus={true}
                      value={formState.referenceNumber}
                      onChange={(referenceNumber) =>
                        handleChange({
                          type: "TEXT",
                          target: {
                            value: referenceNumber,
                            name: "referenceNumber",
                          },
                        })
                      }
                      error={formErrors.referenceNumber}
                    />

                    <SelectField
                      label="Movement*"
                      placeHolder=""
                      selectOptions={watchOptions.movemnets}
                      value={formState.movementId}
                      extractKey={"id"}
                      onChange={(movementId) =>
                        handleChange({
                          type: "ID",
                          target: { value: movementId, name: "movementId" },
                        })
                      }
                      error={formErrors.movement}
                    />

                    <TextField
                      type="text"
                      label="Year of Product"
                      name="referenceNumber"
                      autofocus={true}
                      value={formState.year}
                      onChange={(year) =>
                        handleChange({
                          type: "INT",
                          target: { value: year, name: "year" },
                        })
                      }
                      error={formErrors.year}
                    />
                  </div>
                </div>
                <div className="mt-6">
                  <H2 title="Price" subText="(Required)" />
                </div>

                <div className="w-full">
                  <TextField
                    type="text"
                    label="Price*"
                    name="price"
                    autofocus={true}
                    value={formState.price}
                    onChange={(price) =>
                      handleChange({
                        type: "FLOAT",
                        target: { value: price, name: "price" },
                      })
                    }
                    dollarIcon={true}
                    error={formErrors.price}
                  />

                  <SelectField
                    label="Condition*"
                    placeHolder="Select type"
                    selectOptions={watchOptions.watchConditions}
                    value={formState.conditionId}
                    extractKey="id"
                    onChange={(conditionId) =>
                      handleChange({
                        type: "ID",
                        target: { value: conditionId, name: "conditionId" },
                      })
                    }
                    error={formErrors.condition}
                  />
                  <SelectField
                    label="Scope of delivery*"
                    placeHolder=""
                    selectOptions={watchOptions.watchScopes}
                    value={formState.scopeId}
                    extractKey="id"
                    onChange={(scopeId) =>
                      handleChange({
                        type: "ID",
                        target: { value: scopeId, name: "scopeId" },
                      })
                    }
                    error={formErrors.scope}
                  />
                  <TextAreaField
                    type="text"
                    label="Description"
                    name="description"
                    value={formState.description}
                    rows={6}
                    placeholder="For example, where did you buy the watch? Does it still have a warranty ? Has it been repaired? Is the watch damaged?"
                    onChange={(description) =>
                      handleChange({
                        type: "TEXT",
                        target: { value: description, name: "description" },
                      })
                    }
                  />
                </div>

                <div>
                  <div className="mt-6">
                    <H2 title="Additional Info" subText="Optional" />
                  </div>

                  <button
                    className={`w-full h-12 bg-lightGray flex items-center px-2 justify-between mt-6`}
                    onClick={(e) => handleShowButton(e, "caliber")}
                  >
                    <AdditionalInfoTitle
                      title="Caliber"
                      num={formState.caliberCount}
                    />
                    <MdKeyboardArrowDown
                      className={`transform transition-transform duration-300 ease-in-out origin-center ${
                        showAdditionalInfo === "caliber"
                          ? "rotate-180"
                          : "rotate-0"
                      }`}
                    />
                  </button>
                  {showAdditionalInfo === "caliber" ? (
                    <div>
                      <div className="w-full flex flex-col lg:flex-row">
                        <div className="w-full lg:w-1/2 lg:mr-8">
                          <TextField
                            type="text"
                            label="Caliber/movement"
                            name="caliMovement"
                            autofocus={true}
                            value={formState.caliMovement}
                            onChange={(caliMovement) =>
                              handleChange({
                                type: "TEXT",
                                target: {
                                  value: caliMovement,
                                  name: "caliMovement",
                                },
                              })
                            }
                          />
                          <TextField
                            type="text"
                            label="Base caliber"
                            name="baseCaliber"
                            autofocus={true}
                            value={formState.baseCaliber}
                            onChange={(baseCaliber) =>
                              handleChange({
                                type: "TEXT",
                                target: {
                                  value: baseCaliber,
                                  name: "baseCaliber",
                                },
                              })
                            }
                          />
                          <TextField
                            type="text"
                            label="Frequency"
                            name="frequency"
                            autofocus={true}
                            value={formState.frequency}
                            onChange={(frequency) =>
                              handleChange({
                                type: "INT",
                                target: {
                                  value: frequency,
                                  name: "frequency",
                                },
                              })
                            }
                            unit="A/h"
                          />
                        </div>
                        <div className="w-full lg:w-1/2">
                          <TextField
                            type="text"
                            label="Power Reserve"
                            name="powerReserve"
                            autofocus={true}
                            value={formState.powerReserve}
                            onChange={(powerReserve) =>
                              handleChange({
                                type: "FLOAT",
                                target: {
                                  value: powerReserve,
                                  name: "powerReserve",
                                },
                              })
                            }
                            unit="h"
                          />
                          <TextField
                            type="text"
                            label="Number of Jewels"
                            name="jewelNumbers"
                            autofocus={true}
                            value={formState.jewelNumbers}
                            onChange={(jewelNumbers) =>
                              handleChange({
                                type: "INT",
                                target: {
                                  value: jewelNumbers,
                                  name: "jewelNumbers",
                                },
                              })
                            }
                          />
                        </div>
                      </div>
                      <FieldLabel label="Other Details" />
                      <ul className="w-full flex flex-wrap">
                        {watchOptions.caliberOtherDetails.map((item, index) => (
                          <li key={index} className="w-1/2">
                            <RatioField
                              name={item.name}
                              label={item.name}
                              isOn={
                                formState["otherAttributeIds"].length
                                  ? formState["otherAttributeIds"].includes(
                                      item.id
                                    )
                                  : false
                              }
                              onChange={(details) =>
                                handleRatioChange({
                                  type: "otherAttributeIds",
                                  target: { id: item.id, value: details },
                                })
                              }
                            />
                          </li>
                        ))}
                      </ul>
                    </div>
                  ) : (
                    <></>
                  )}

                  <button
                    className={`w-full h-12 bg-lightGray flex items-center px-2 justify-between mt-6`}
                    onClick={(e) => handleShowButton(e, "case")}
                  >
                    <AdditionalInfoTitle
                      title="Case"
                      num={formState.caliberCount}
                    />
                    <MdKeyboardArrowDown
                      className={`transform transition-transform duration-300 ease-in-out origin-center ${
                        showAdditionalInfo === "case"
                          ? "rotate-180"
                          : "rotate-0"
                      }`}
                    />
                  </button>

                  {showAdditionalInfo === "case" ? (
                    <div className="w-full">
                      <div className="w-full flex flex-col lg:flex-row">
                        <div className="w-full lg:w-1/2 mr-8">
                          <SelectField
                            label="Case Material"
                            placeHolder=""
                            selectOptions={watchOptions.caseMaterials}
                            value={formState.caseMaterialId}
                            extractKey={"id"}
                            onChange={(caseMaterialId) =>
                              handleChange({
                                type: "ID",
                                target: {
                                  value: caseMaterialId,
                                  name: "caseMaterialId",
                                },
                              })
                            }
                          />

                          <SelectField
                            label="Bezel Material"
                            placeHolder=""
                            selectOptions={watchOptions.caseMaterials}
                            value={formState.bezelMaterialId}
                            extractKey={"id"}
                            onChange={(bezelMaterialId) =>
                              handleChange({
                                type: "ID",
                                target: {
                                  value: bezelMaterialId,
                                  name: "bezelMaterialId",
                                },
                              })
                            }
                          />
                          <TextField
                            type="text"
                            label="Thickness"
                            name="thickness"
                            autofocus={true}
                            value={formState.thickness}
                            onChange={(thickness) =>
                              handleChange({
                                type: "FLOAT",
                                target: {
                                  value: thickness,
                                  name: "thickness",
                                },
                              })
                            }
                            unit="mm"
                          />
                        </div>
                        <div className="w-full lg:w-1/2">
                          <SelectField
                            label="Crystal"
                            placeHolder=""
                            selectOptions={watchOptions.caseCrystals}
                            value={formState.glassId}
                            extractKey={"id"}
                            onChange={(glassId) =>
                              handleChange({
                                type: "ID",
                                target: {
                                  value: glassId,
                                  name: "glassId",
                                },
                              })
                            }
                          />
                          <SelectField
                            label="Watch Resistance"
                            placeHolder=""
                            selectOptions={watchOptions.watchResistances}
                            value={formState.waterResistanceId}
                            extractKey={"id"}
                            onChange={(waterResistanceId) =>
                              handleChange({
                                type: "ID",
                                target: {
                                  value: waterResistanceId,
                                  name: "waterResistanceId",
                                },
                              })
                            }
                          />
                        </div>
                      </div>
                      <FieldLabel label="Other Details" />
                      <ul className="w-full flex flex-wrap">
                        {watchOptions.caseOtherDetails.map((item, index) => (
                          <li key={index} className="w-1/2">
                            <RatioField
                              name={item.name}
                              label={item.name}
                              isOn={
                                formState["otherAttributeIds"]
                                  ? formState["otherAttributeIds"].includes(
                                      item.id
                                    )
                                  : false
                              }
                              onChange={(details) =>
                                handleRatioChange({
                                  type: "otherAttributeIds",
                                  target: { id: item.id, value: details },
                                })
                              }
                            />
                          </li>
                        ))}
                      </ul>
                    </div>
                  ) : (
                    <></>
                  )}

                  <button
                    className={`w-full h-12 bg-lightGray flex items-center px-2 justify-between mt-6`}
                    onClick={(e) => handleShowButton(e, "dial")}
                  >
                    <AdditionalInfoTitle
                      title="Dial and hands"
                      num={formState.dialCount}
                    />
                    <MdKeyboardArrowDown
                      className={`transform transition-transform duration-300 ease-in-out origin-center ${
                        showAdditionalInfo === "dial"
                          ? "rotate-180"
                          : "rotate-0"
                      }`}
                    />
                  </button>

                  {showAdditionalInfo === "dial" ? (
                    <div className="w-full">
                      <div className="w-full flex flex-col lg:flex-row">
                        <div className="w-full lg:w-1/2 lg:mr-8">
                          <SelectField
                            label="Dial Color"
                            placeHolder=""
                            selectOptions={watchOptions.dialColors}
                            value={formState.dialColorId}
                            extractKey={"id"}
                            onChange={(dialColorId) =>
                              handleChange({
                                type: "ID",
                                target: {
                                  value: dialColorId,
                                  name: "dialColorId",
                                },
                              })
                            }
                          />
                        </div>
                        <div className="w-full lg:w-1/2">
                          <SelectField
                            label="Dial Numerals"
                            placeHolder=""
                            selectOptions={watchOptions.dialNumerals}
                            value={formState.dialNumeralsId}
                            extractKey={"id"}
                            onChange={(dialNumeralsId) =>
                              handleChange({
                                type: "ID",
                                target: {
                                  value: dialNumeralsId,
                                  name: "dialNumeralsId",
                                },
                              })
                            }
                          />
                        </div>
                      </div>
                      <FieldLabel label="Dial Features" />
                      <ul className="w-full flex flex-wrap">
                        {watchOptions.dialFeatures.map((item, index) => (
                          <li key={index} className="w-1/2">
                            <RatioField
                              name={item.name}
                              label={item.name}
                              isOn={
                                formState["otherAttributeIds"]
                                  ? formState["otherAttributeIds"].includes(
                                      item.id
                                    )
                                  : false
                              }
                              onChange={(details) =>
                                handleRatioChange({
                                  type: "otherAttributeIds",
                                  target: { id: item.id, value: details },
                                })
                              }
                            />
                          </li>
                        ))}
                      </ul>
                      <FieldLabel label="Hand Details" />
                      <ul className="w-full flex flex-wrap">
                        {watchOptions.handDetails.map((item, index) => (
                          <li key={index} className="w-1/2">
                            <RatioField
                              name={item.name}
                              label={item.name}
                              isOn={
                                formState["otherAttributeIds"]
                                  ? formState["otherAttributeIds"].includes(
                                      item.id
                                    )
                                  : false
                              }
                              onChange={(details) =>
                                handleRatioChange({
                                  type: "otherAttributeIds",
                                  target: { id: item.id, value: details },
                                })
                              }
                            />
                          </li>
                        ))}
                      </ul>
                    </div>
                  ) : (
                    <></>
                  )}

                  <button
                    className={`w-full h-12 bg-lightGray flex items-center px-2 justify-between mt-6`}
                    onClick={(e) => handleShowButton(e, "bracelet")}
                  >
                    <AdditionalInfoTitle
                      title="Bracelet/Strap"
                      num={formState.braceletCount}
                    />
                    <MdKeyboardArrowDown
                      className={`transform transition-transform duration-300 ease-in-out origin-center ${
                        showAdditionalInfo === "bracelet"
                          ? "rotate-180"
                          : "rotate-0"
                      }`}
                    />
                  </button>

                  {showAdditionalInfo === "bracelet" ? (
                    <div className="w-full flex flex-col lg:flex-row">
                      <div className="w-full lg:w-1/2 lg:mr-8">
                        <SelectField
                          label="Bracelet Material"
                          placeHolder=""
                          selectOptions={watchOptions.breceletMaterials}
                          value={formState.braceletMaterialId}
                          extractKey={"id"}
                          onChange={(braceletMaterialId) =>
                            handleChange({
                              type: "ID",
                              target: {
                                value: braceletMaterialId,
                                name: "braceletMaterialId",
                              },
                            })
                          }
                        />

                        <SelectField
                          label="Bracelet Color"
                          placeHolder=""
                          selectOptions={watchOptions.breceletColors}
                          value={formState.braceletColorId}
                          extractKey={"id"}
                          onChange={(braceletColorId) =>
                            handleChange({
                              type: "ID",
                              target: {
                                value: braceletColorId,
                                name: "braceletColorId",
                              },
                            })
                          }
                        />
                      </div>
                      <div className="w-full lg:w-1/2">
                        <SelectField
                          label="Type of clasp"
                          placeHolder=""
                          selectOptions={watchOptions.watchClasps}
                          value={formState.claspId}
                          extractKey={"id"}
                          onChange={(claspId) =>
                            handleChange({
                              type: "ID",
                              target: { value: claspId, name: "claspId" },
                            })
                          }
                        />
                        <SelectField
                          label="Clasp Material"
                          placeHolder=""
                          selectOptions={watchOptions.breceletMaterials}
                          value={formState.claspMaterialId}
                          extractKey={"id"}
                          onChange={(claspMaterialId) =>
                            handleChange({
                              type: "ID",
                              target: {
                                value: claspMaterialId,
                                name: "claspMaterialId",
                              },
                            })
                          }
                        />
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}

                  <button
                    className={`w-full h-12 bg-lightGray flex items-center px-2 justify-between mt-6`}
                    onClick={(e) => handleShowButton(e, "function")}
                  >
                    <AdditionalInfoTitle
                      title="Functions"
                      num={formState.functionCount}
                    />
                    <MdKeyboardArrowDown
                      className={`transform transition-transform duration-300 ease-in-out origin-center ${
                        showAdditionalInfo === "function"
                          ? "rotate-180"
                          : "rotate-0"
                      }`}
                    />
                  </button>

                  {showAdditionalInfo === "function" ? (
                    <ul className="w-full flex flex-wrap">
                      {watchOptions.watchFunctions.map((item, index) => (
                        <li key={index} className="w-1/2">
                          <RatioField
                            name={item.name}
                            label={item.name}
                            isOn={
                              formState["watchFunctionsIds"].length > 0
                                ? formState["watchFunctionsIds"].includes(
                                    item.id
                                  )
                                : false
                            }
                            onChange={(details) =>
                              handleRatioChange({
                                type: "watchFunctionsIds",
                                target: { id: item.id, value: details },
                              })
                            }
                          />
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="mt-8 w-full">
                  <Button name={"Next"} action={handleDetailsSubmit} />
                </div>
              </div>
            </div>
          )}
          {/* step 1 end */}
          {/* step 2 start */}
          {step2 && (
            <div className="w-full">
              <H1 title="Watch Images" />
              <ImagesUploader
                initialImages={formState.imageUris}
                numLimit={20}
                onWatchUpload={getWatchImages}
                tipsText={
                  "Upload at least 3 photos of the watch."
                }
              />

              {formImagesError ? (
                <div className="mt-8 w-full">
                  <ErrorMessage mess={formImagesError} />
                </div>
              ) : (
                <></>
              )}

              <div className="mt-8 w-full flex justify-between">
                <BackButton
                  name={"Back to Previous Step"}
                  action={() => handleStepBack("step1")}
                />
                <Button
                  name={"Next"}
                  action={handleImagesSubmit}
                  isActive={true}
                />
              </div>
            </div>
          )}
          {/* step 2 end */}
          {/* step 3 start */}
          { step3 && (
            <>
              {((type === "new" && success) || type === "edit") && (
                <div className="w-full">
                  <H1 title="Seller Details" />
                  <div className="mt-6">
                    <H2 title="Contact" subText="(Required)" />
                  </div>

                  <AddressCollector
                    formState={formState.contact}
                    errors={formContactErrors}
                    onChange={(contact) =>
                      handleChange({
                        type: "OBJECT",
                        target: { value: contact, name: "contact" },
                      })
                    }
                  />
                  <div className="mt-8 w-full flex justify-between">
                    <BackButton
                      name={"Back to Previous Step"}
                      action={() => handleStepBack("step2")}
                    />
                    <Button
                      name={"Next"}
                      action={handleContactDetailsSubmit}
                      isActive={true}
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        {step1 || step2 || step3 ? (
          <div
            className={`hidden lg:block lg:w-2/5 lg:px-8 sticky top-80 h-fit`}
          >
            <WatchPreviewImage
              brandName={formState.brandName}
              modelName={formState.modelName}
              referenceNumber={formState.referenceNumber}
              price={formState.price}
              image={
                formState.imageUris && formState.imageUris.length
                  ? formState.imageUris[0].uri
                  : ""
              }
            />
          </div>
        ) : (
          <></>
        )}
      </div>

      {/* step 3 end */}
      {/* step 4 start */}
      {step4 && (
        <div className="w-full flex items-start">
          <div className="w-full lg:w-3/5">
            <H1 title="Summary" />
            <WatchPreviewImage
              brandName={formState.brandName}
              modelName={formState.modelName}
              referenceNumber={formState.referenceNumber}
              price={formState.price}
              image={
                formState.imageUris && formState.imageUris.length
                  ? formState.imageUris[0].uri
                  : ""
              }
            />

            {submitError && (
              <div className="mt-8 w-full">
                <ErrorMessage mess={submitError} />
              </div>
            )}

            <div className="mt-8 w-full flex justify-between">
              <BackButton
                name={"Back to Previous Step"}
                action={() => handleStepBack("step3")}
              />
              {submitLoading ? (
                <LoadingButton name="Submitting..." />
              ) : (
                <Button name={"Submit Listing"} action={handleSaveListing} />
              )}
            </div>
          </div>
        </div>
      )}
      {/* step 4 end */}
    </div>
  );
}
