import Breadcrumbs from "@mui/material/Breadcrumbs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import {
  collectionList,
  deleteCollectionById
} from "../../../api/webContentRequets";
import Pagination from "../../../components/Pagination";
import ScreenTitle from "../../../components/ScreenTitle";
import {
  DeleteButton,
  EditLinkButton,
  LinkButton,
} from "../../../components/buttons";
import { ErrorMessage, Loader } from "../../../components/smallComponents";
import commonStyle from "../../../style/commonStyle.module.css";
import useDocumentTitle from "../../../useDocumentTitle";

const S3_DOMAIN = process.env.REACT_APP_S3_DOMAIN;

export default function CollectionList() {
  useDocumentTitle("Collections");
  const dispatch = useDispatch();

  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  const pageSize = 20;
  const [pageNum, setPageNum] = useState(0);
  const [reqStatus, setReqStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const [deleteStatus, setDeleteStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const [collections, setCollections] = useState([]);
  const [deleteCount, setDeleteCount] = useState(1);

  const onPageChangeRequest = (page) => {
    setPageNum(page.selected);
  };

  useEffect(() => {
    const getCollectionList = async () => {
      try {
        setReqStatus((preState) => ({
          ...preState,
          loading: true,
        }));

        const responseData = await collectionList(
          pageNum,
          pageSize,
          config,
          dispatch
        );
        if (responseData.data.success) {
          setReqStatus((preState) => ({
            ...preState,
            loading: false,
            success: true,
          }));
          setCollections(responseData.data.data);
        } else {
          throw responseData.data.message;
        }
      } catch (error) {
        setReqStatus((preState) => ({
          ...preState,
          loading: false,
          error: error,
        }));
      }
    };

    getCollectionList();
  }, [dispatch, pageNum, deleteCount]);

  const deleteCollectionAction = async (collectionId) => {
    try {
      setDeleteStatus((preState) => ({
        ...preState,
        loading: true,
      }));

      const responseData = await deleteCollectionById(collectionId, config, dispatch);
      if (responseData.data.success) {
        setDeleteCount(deleteCount + 1);
        setDeleteStatus((preState) => ({
          ...preState,
          loading: false,
          success: true,
        }));
      } else {
        throw responseData.data.message;
      }
    } catch (error) {
      setDeleteStatus((preState) => ({
        ...preState,
        loading: false,
        error: error,
      }));
    }
  };

  return (
    <div className={commonStyle.pageContainer}>
      <div className="w-full p-8">
        <Breadcrumbs aria-label="breadcrumb">
          <NavLink underline="hover" color="inherit" to="/">
            Home
          </NavLink>
          <NavLink
            underline="hover"
            color="inherit"
            to="/web-content/collections"
          >
            Collections
          </NavLink>
        </Breadcrumbs>
        <ScreenTitle title="Collections" />

        {reqStatus.loading ? (
          <Loader mess="Requesting collections..." />
        ) : reqStatus.success ? (
          <div className={commonStyle.longDataTable}>
            <div className="w-full flex justify-end">
              <LinkButton
                path="/web-content/collections/new"
                name="Add New Collection"
                width="fit"
              />
            </div>
            <table className={commonStyle.pageTable}>
              <thead>
                <tr>
                  <th>Collection Image</th>
                  <th>Collection Name</th>
                  <th>Collection Position</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {collections.collections.length > 0 ? (
                  collections.collections.map((item, index) => (
                    <tr key={index}>
                      <td className="w-1/6 p-4 ">
                        <div
                          className="w-full"
                          style={{ aspectRatio: "1 / 1" }}
                        >
                          {item.image ? (
                            <img
                              alt={item.image}
                              src={S3_DOMAIN + item.image}
                              className="w-full h-full object-cover rounded"
                              style={{
                                maxWidth: "100%",
                                maxHeight: "100%",
                              }}
                            />
                          ) : (
                            <img
                              alt={"default-watch"}
                              src="/default-watch.svg"
                              className="w-full h-full object-cover"
                              style={{
                                maxWidth: "30%",
                                maxHeight: "30%",
                              }}
                            />
                          )}
                        </div>
                      </td>
                      <td><div className="w-fit text-sm bg-brandYellow rounded-full text-white px-2 py-1">{item.name}</div></td>
                      <td>{item.position}</td>
                      <td>
                        <div className="flex flex-row items-center">
                          <NavLink
                            to={`/web-content/collections/manage-products/${item.id}`}
                            className={` text-blue-500 hover:cursor-pointer hover:underline`}
                          >
                            Manage Products
                          </NavLink>
                        </div>
                      </td>

                      <td>
                        <div className="flex flex-row items-center">
                          <EditLinkButton
                            path={`/web-content/collections/edit/${item.id}`}
                          />
                          <DeleteButton action={() => deleteCollectionAction(item.id)} />
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>
                      There are no collection available. Please add a new one
                      now.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {collections.collections.length > 0 ? (
              <Pagination
                totalPageNum={collections.totalPages}
                forcePage={pageNum}
                onPageChange={onPageChangeRequest}
              />
            ) : (
              <></>
            )}
          </div>
        ) : reqStatus.error ? (
          <ErrorMessage mess={reqStatus.error} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
