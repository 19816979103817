import React, { useState, useEffect } from "react";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { FaFileCsv, FaFileImage } from "react-icons/fa";
import { useSelector } from "react-redux";
import { ErrorMessage, Loader } from "../../components/smallComponents";
import { uploadImage } from "../../api/fileUpload";
import { CloseButton } from "../../components/buttons";

const S3_DOMAIN = process.env.REACT_APP_S3_DOMAIN;

export default function ImagesUploader({
  initialImages,
  numLimit,
  onWatchUpload,
  tipsText
}) {
  const { userInfo } = useSelector((state) => state.userLogin);

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo ? userInfo.token : ""}`,
      "Content-Type": "multipart/form-data",
    },
  };

  // drag state
  const [dragActive, setDragActive] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState(initialImages);
  const [fileError, setFileError] = useState("");

  useEffect(() => {
    onWatchUpload(uploadedFiles);
  }, [uploadedFiles]);

  // triggers when file is dropped
  const dropHandler = (ev) => {
    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();
    setFileError("");

    let allowedFiles = [];

    if (ev.dataTransfer && ev.dataTransfer.items) {
      const files = [...ev.dataTransfer.items]
        .filter((item) => item.kind === "file")
        .map((item) => item.getAsFile());

      allowedFiles = files.filter((file) => file.type.startsWith("image/"));
    } else if (ev.target.files) {
      const files = [...ev.target.files];
      allowedFiles = files.filter((file) => file.type.startsWith("image/"));
    }

    if (allowedFiles.length === 0) {
      setFileError("Only image files accepted.");
      return;
    }
    setDragActive(false);

    let uploadedFilesCopy = [...uploadedFiles];
    // remove current error images
    uploadedFilesCopy = uploadedFilesCopy.filter((x) => x.uri);

    // Filter out files with duplicate names
    let uniqueFiles = allowedFiles.filter(
      (file) => !uploadedFilesCopy.some((x) => x.fileName === file.name)
    );

    uniqueFiles.map((file) =>
      uploadedFilesCopy.push({
        fileName: file.name,
        uri: "",
        isLoading: true, // Initially set loading to true
        error: "",
      })
    );

    setUploadedFiles(uploadedFilesCopy);
    uploadFiles(uniqueFiles, "watch");
  };

  const dragOverHandler = (ev) => {
    ev.preventDefault();
    setDragActive(true);
  };

  const deleteFile = (index) => {
    setUploadedFiles((prevFiles) => prevFiles.filter((file, i) => i !== index));
  };

  const updateFileStatus = (fileName, uri, error) => {
    setUploadedFiles((prevFiles) =>
      prevFiles.map((file) => {
        if (file.fileName === fileName) {
          return { ...file, uri, isLoading: false, error };
        }
        return file;
      })
    );
  };

  const uploadFiles = async (files, type) => {
    for (let file of files) {
      const formData = new FormData();
      formData.append("image", file);
      formData.append("folderName", "product");

      try {
        const responseData = await uploadImage(formData, config);
        if (responseData.data.success) {
          updateFileStatus(file.name, responseData.data.data.url, "");
        } else {
          if (typeof responseData.data.message === "string") {
            throw responseData.data.message;
          } else {
            throw "Failed to upload";
          }
        }
      } catch (error) {
        updateFileStatus(file.name, "", error);
      }
    }
  };

  return (
    <div className="w-full mt-6">
      <p className="text-gray text-sm mt-4">
       {tipsText}
      </p>

      {fileError ? <ErrorMessage mess={fileError} /> : <></>}

      <div className="flex justify-end mb-2">
        <span style={{ color: "var(--main-color)" }}>
          {uploadedFiles.length}
        </span>{" "}
        /<span>{numLimit}</span>
      </div>

      <div className="w-full grid grid-cols-4 gap-4 mt-4">
        {uploadedFiles.map((file, index) => (
          <div
            key={index}
            className="relative min-w-0 border border-solid border-borderGray rounded"
            style={{aspectRatio: "1 / 1"}}
          >
            {file.isLoading ? (
              <Loader />
            ) : file.uri ? (
              <div className="relative w-full h-full flex flex-col items-center justify-center rounded">
                <img
                  alt={file.fileName}
                  src={S3_DOMAIN + file.uri}
                  className="w-full h-full object-contain rounded"
                  style={{ width: "100%", height: "100%" }}
                />

                <div className="w-5 h-5 rounded absolute top-0 right-0 flex items-center justify-center">
                  <CloseButton action={() => deleteFile(index)} />
                </div>
              </div>
            ) : file.error ? (
              <div>
                <ErrorMessage mess={file.error} />
              </div>
            ) : (
              <></>
            )}
          </div>
        ))}

        {uploadedFiles.length < numLimit ? (
          <div
            className="min-w-0 text-center relative"
            style={{aspectRatio: "1 / 1"}}
            onDrop={dropHandler}
            onDragOver={dragOverHandler}
          >
            <input
              type="file"
              id="input-file-upload"
              className="hidden"
              accept="image/*"
              multiple
              onChange={dropHandler}
            />
            <label
              id="label-file-upload"
              htmlFor="input-file-upload"
              className={`flex w-full items-center justify-center h-full border rounded border-dashed hover:cursor-pointer ${
                dragActive ? "border-black" : "border-borderGray"
              }`}
            >
              <div className="flex flex-col items-center py-6 hover:cursor-pointer">
                <AiOutlineCloudUpload className="w-12 h-12 text-gray" />
                <div className="flex flex-col items-cente mt-2 hover:cursor-pointer">
                  <span className="lg:hidden text-blue-500">Add images</span>
                  <span className="hidden lg:block text-blue-500">
                    Browse images
                  </span>
                  <span className="hidden lg:block text-gray">
                    or drag and drop image here
                  </span>
                </div>
              </div>
            </label>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
