import Breadcrumbs from "@mui/material/Breadcrumbs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import { inviteUserRequest, rejectUserRequest, verifyUserRequest } from "../../api/userRequests";
import ScreenTitle from "../../components/ScreenTitle";
import { Button, LoadingButton } from "../../components/buttons";
import { ErrorMessage, Loader } from "../../components/smallComponents";
import { userDetailsRequest } from "../../redux/actions/userActions";
import commonStyle from "../../style/commonStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle";
import { ImageViewDialog, SimpleDialog } from "../../components/dialogs";

const S3_DOMAIN = process.env.REACT_APP_S3_DOMAIN;

export default function UserDetails() {
  const { id } = useParams();
  useDocumentTitle("User Details");
  const dispatch = useDispatch();

  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  const { loading, details, success, error } = useSelector(
    (state) => state.userDetails
  );
  const [previewImage, setPreviewImage] = useState("");
  const [modal, setModal] = useState(false);
  const [saveStatus, setSaveStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const [rejectStatus, setRejectStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const [dialog, setDialog] = useState(false);
  const [inviteStatus, setInviteStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  useEffect(() => {
    dispatch(userDetailsRequest(id));
    setSaveStatus({
      loading: false,
      success: false,
      error: "",
    });
    setRejectStatus({
      loading: false,
      success: false,
      error: "",
    });
  }, [dispatch, saveStatus.success, rejectStatus.success]);

  const openImagePreviewModal = (image) => {
    setModal(true);
    setPreviewImage(image);
  };

  const ViewItem = ({ label, value }) => {
    return (
      <div className="w-full mt-6">
        <p className="text-gray">{label}</p>
        <p className="mt-2">{value}</p>
      </div>
    );
  };

  const getAddressString = () => {
    const parts = [
      details.streetLine1,
      details.city,
      details.region,
      details.country,
      details.postalCode,
    ];

    const formattedAddress = parts.filter(Boolean).join(", ");

    return formattedAddress;
  };

  const verifyUser = async () => {
    try {
      setSaveStatus({ ...saveStatus, loading: true });

      const responseData = await verifyUserRequest(id, config);
      if (responseData.data.success) {
        setSaveStatus({ ...saveStatus, loading: false, success: true });
      } else {
        throw responseData.data.message;
      }
    } catch (error) {
      setSaveStatus({ ...saveStatus, loading: false, error: error });
    }
  };

  const rejectUser = async () => {
    try {
      setRejectStatus({ ...rejectStatus, loading: true });

      const responseData = await rejectUserRequest(id, config);
      if (responseData.data.success) {
        setRejectStatus({ ...rejectStatus, loading: false, success: true });
      } else {
        throw responseData.data.message;
      }
    } catch (error) {
      setRejectStatus({ ...rejectStatus, loading: false, error: error });
    }
  };

  const inviteUser = async () => {
    try {
      setInviteStatus({ ...inviteStatus, loading: true });
      setDialog(true);
      const responseData = await inviteUserRequest(id, config);
      if (responseData.data.success) {
        setInviteStatus({ ...inviteStatus, loading: false, success: true });
      } else {
        throw responseData.data.message;
      }
    } catch (error) {
      setInviteStatus({ ...inviteStatus, loading: false, error: error });
    }
  };

  return (
    <div className={commonStyle.pageContainer}>
      <ImageViewDialog
        isDialogOpen={modal}
        closeDialog={() => setModal(false)}
        imageUri={previewImage}
      />

      <SimpleDialog
        title="Invite User"
        successMessage="Invite user email send successfully."
        isDialogOpen={dialog}
        closeDialog={() => setDialog(false)}
        loading={inviteStatus.loading}
        loadingMessage="Processing request..."
        success={inviteStatus.success}
        error={error}
        confirmAction={() => setDialog(false)}
      />

      <div className="w-full p-8">
        <Breadcrumbs aria-label="breadcrumb">
          <NavLink underline="hover" color="inherit" to="/">
            Home
          </NavLink>
          <NavLink underline="hover" color="inherit" to="/users">
            Users
          </NavLink>
          <NavLink underline="hover" color="inherit">
            Details
          </NavLink>
        </Breadcrumbs>
        <ScreenTitle title="User Details" />

        {loading ? (
          <Loader mess="Requesting details..." />
        ) : success ? (
          <div>
            <div className="w-full border border-borderGray rounded px-4 py-1 mt-6">
              <div className=" w-full flex ">
                <div className="w-1/3">
                  <h2 className="mt-12">Basic Info</h2>
                  <ViewItem label="User Name" value={details.userName} />
                  <ViewItem label="Email" value={details.email} />
                  <ViewItem label="Role" value={details.role} />
                  <ViewItem label="Log in method" value={details.loginMethod} />
                </div>
                <div className="w-1/3">
                  <h2 className="mt-12">Verify Info</h2>
                  <ViewItem
                    label="Activated"
                    value={details.activated === 1 ? "Yes" : "No"}
                  />
                  <ViewItem label="Apply Role" value={details.applyRole} />
                  <ViewItem label="Verify Status" value={details.verifStatus} />
                </div>
                <div className="w-1/3">
                  <h2 className="mt-12">User Avatar</h2>
                  <div className="w-48 h-48 rounded-full">
                    <img
                      alt={"User Icon"}
                      src={S3_DOMAIN + details.avatar}
                      className="w-full h-full object-contain mt-6"
                      style={{ maxWidth: "100%", maxHeight: "100%" }}
                    />
                  </div>
                </div>
              </div>

              <div className=" w-full flex ">
                <div className="w-1/3">
                  <h2 className="mt-12">Contact Info</h2>
                  <ViewItem label="First Name" value={details.firstName} />
                  <ViewItem label="Last Name" value={details.lastName} />
                  <ViewItem label="Phone" value={details.phone} />
                  <ViewItem label="Address" value={getAddressString()} />
                </div>
                <div className="w-1/3">
                  <h2 className="mt-12">Company Info</h2>
                  <ViewItem label="Company Name" value={details.companyName} />
                  <ViewItem label="EIN number" value={details.einNumber} />
                  <ViewItem
                    label="Company Website"
                    value={details.companyWeb}
                  />
                </div>
              </div>
              <div className=" w-full flex">
                <div className="w-2/3">
                  <h2 className="mt-12">Identity</h2>
                  <div className="w-full flex mt-6">
                    <div
                      className="w-48 h-48 hover:cursor-pointer"
                      onClick={() => openImagePreviewModal(details.frontImage)}
                    >
                      <img
                        alt={details.frontImage}
                        src={
                          details.frontImage
                            ? S3_DOMAIN + details.frontImage
                            : "/id-example-front.svg"
                        }
                        className="w-full h-full object-contain"
                        style={{
                          maxWidth: "100%",
                          maxHeight: "100%",
                        }}
                      />
                    </div>
                    <div
                      className="w-48 h-48 ml-4 hover:cursor-pointer"
                      onClick={() => openImagePreviewModal(details.backImage)}
                    >
                      <img
                        alt={details.backImage}
                        src={
                          details.backImage
                            ? S3_DOMAIN + details.backImage
                            : "/id-example-back.svg"
                        }
                        className="w-full h-full object-contain"
                        style={{
                          maxWidth: "100%",
                          maxHeight: "100%",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {saveStatus.error ? (
                <ErrorMessage mess={saveStatus.error} />
              ) : (
                <></>
              )}
              <div className="w-fit mt-6">
                {details.verifStatus === "Waiting Review" ? (
                  saveStatus.loading ? (
                    <LoadingButton name="Verifiing..." />
                  ) : (
                    <Button name="Verify User" action={verifyUser} />
                  )
                ) : (
                  <></>
                )}
              </div>
              <div className="w-fit mt-6">
                {details.verifStatus === "Waiting Review" ? (
                  rejectStatus.loading ? (
                    <LoadingButton name="Proceeding..." />
                  ) : (
                    <Button name="Reject User" action={rejectUser} />
                  )
                ) : (
                  <></>
                )}
              </div>
              {details.activated === 0 ? (
                <div className="w-fit mt-6">
                  {inviteStatus.loading ? (
                    <LoadingButton name="Requesting..." />
                  ) : (
                    <Button name="Invite User" action={inviteUser} />
                  )}
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        ) : error ? (
          <ErrorMessage mess={error} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
