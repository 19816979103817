import Breadcrumbs from "@mui/material/Breadcrumbs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import { convToLocalDate, convtPrice } from "../../../api/functions";
import ScreenTitle from "../../../components/ScreenTitle";
import { LinkButton, TextLinkButton } from "../../../components/buttons";
import { ProductImageListPreview } from "../../../components/productComponents";
import {
  AddressLine,
  ConsignmentOrderStatusTag,
  ErrorMessage,
  H3,
  Loader,
  OrderStatusTag,
  UserAvatar,
} from "../../../components/smallComponents";
import { consignmentQuoteDetailsRequest } from "../../../redux/actions/orderActions";
import commonStyle from "../../../style/commonStyle.module.css";
import useDocumentTitle from "../../../useDocumentTitle";
import {
  ActionWithFileButton,
  AdminConfimAgreementButton,
  ContactButton,
  MakeAnOfferButton,
  OrderActionButton,
} from "../orderActionButtons";

const S3_DOMAIN = process.env.REACT_APP_S3_DOMAIN;

export default function ConsignmentOrderDetails() {
  const { id } = useParams();
  useDocumentTitle("Consignment Details");
  const dispatch = useDispatch();

  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  const { loading, details, success, error } = useSelector(
    (state) => state.consignmentDetails
  );

  const [saveStatus, setSaveStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const [courierInfo, setCourierInfo] = useState("");
  const [showAdminConfirmAgreementBtn, setShowAdminConfirmAgreementBtn] = useState(false);

  useEffect(() => {
    dispatch(consignmentQuoteDetailsRequest(id));
    setSaveStatus({
      loading: false,
      success: false,
      error: "",
    });
  }, [dispatch, saveStatus.success, id]);

  useEffect(() => {
    if (success) {
      if (
        details.actionRecords.filter(
          (x) => x.action === "SHIPPED_PRODUCT_TO_BUYER"
        ).length
      ) {
        setCourierInfo(
          JSON.parse(
            details.actionRecords.filter(
              (x) => x.action === "SHIPPED_PRODUCT_TO_BUYER"
            )[0].action_info
          )
        );
      };

      let show_confir_agreement_btn = false;
      if(Number(details.adminId) > 0) {
        if(Number(details.adminId) === Number(userInfo.data.user_id)){
          let order_create_time = details.atime;
          let now_date = new Date();
          let now_date_timestamp = Math.floor(now_date.getTime() / 1000);
          const seven_days_gap = 7 * 24 * 60 * 60;
          let time_diff = now_date_timestamp - order_create_time;
          if( time_diff >=  seven_days_gap ){
            show_confir_agreement_btn = true;
          }
        }
      }
      setShowAdminConfirmAgreementBtn(show_confir_agreement_btn);

    } else {
      setCourierInfo("");
    }
  }, [success]);

  const successActionDone = () => {
    dispatch(consignmentQuoteDetailsRequest(id));
  };

  const ViewItem = ({ name, value }) => {
    return (
      <div className="mt-6">
        <p className="text-sm text-gray">{name}</p>
        <p className="text-sm mt-2">{value}</p>
      </div>
    );
  };

  const BoolViewItem = ({ name, value, additionText = "" }) => {
    return (
      <div className="mt-6">
        <p className="text-sm text-gray">{name}</p>
        <p className="text-sm mt-2">
          {value === 1 ? "Yes" : value === 0 ? "No" : ""}
        </p>
        {additionText && <p className="text-sm mt-2">{additionText}</p>}
      </div>
    );
  };

  const OrderViewItem = ({ name, value }) => {
    return (
      <div className={`flex flex-row justify-between mt-4`}>
        <p>{name}</p>
        <p>{value}</p>
      </div>
    );
  };

  return (
    <div className={commonStyle.pageContainer}>
      <div className="w-full p-8">
        <Breadcrumbs aria-label="breadcrumb">
          <NavLink underline="hover" color="inherit" to="/">
            Home
          </NavLink>
          <NavLink
            underline="hover"
            color="inherit"
            to="/consignment/quote-list"
          >
            Consignment
          </NavLink>
          <NavLink underline="hover" color="inherit">
            Details
          </NavLink>
        </Breadcrumbs>
        <ScreenTitle title="Quote Details" />

        {loading ? (
          <Loader mess="Requesting quote details..." />
        ) : success ? (
          <div className="w-full mt-6">
            <div className="w-full flex  flex-row">
              <div className="w-fit flex flex-row">
                <span>Quote Number:</span>
                <span>{details.orderNumber}</span>
              </div>
              <OrderStatusTag currState={details.currStateDesc} />
            </div>
            {details.offerConfirmed ? (
              <div className="mt-6">
                <H3 title={"Offer Details"} />
                <div className="mt-2 rounded">
                  {details.type === "DEFAULT" ? (
                    <div className="bg-lightGray p-4 mt-4 rounded">
                      <div className="mt-0 flex flex-row justify-between">
                        <p className="">Type</p>
                        <p className="mt-2">Standard Consignment</p>
                      </div>
                      <div className="mt-4 flex flex-row justify-between">
                        <p className="">Listed Price</p>
                        <p className="mt-2">
                          {"$" + convtPrice(details.offerPrice)}
                        </p>
                      </div>
                      <OrderViewItem
                        name={"WatchSpace Fee"}
                        value={"$" + convtPrice(details.commision)}
                      />
                      {Number(details.serviceFee) > 0 ? (
                        <OrderViewItem
                          name={"Service Fee"}
                          value={"$" + convtPrice(details.serviceFee)}
                        />
                      ) : (
                        <></>
                      )}
                      <div
                        className={`flex flex-row justify-between mt-4 border-t border-solid border-borderGray border-black pt-4`}
                      >
                        <p className="font-medium">{"Customer Earnings"}</p>
                        <p className="font-medium">
                          {"$" +
                            convtPrice(
                              details.orderTotal ? details.orderTotal : 0
                            )}
                        </p>
                      </div>
                    </div>
                  ) : details.type === "BUYOUT" ? (
                    <div className="bg-lightGray p-4 mt-4 rounded">
                      <div className="mt-0 flex flex-row justify-between">
                        <p className="">Type</p>
                        <p className="mt-2">Buyout</p>
                      </div>
                      <OrderViewItem
                        name={"Buyout Price"}
                        value={"$" + convtPrice(details.buyoutPrice)}
                      />
                    </div>
                  ) : (
                    <></>
                  )}

                  {details.orderPdfUri && (
                    <div className="bg-lightGray p-4 mt-4 rounded">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={S3_DOMAIN + details.orderPdfUri}
                        className="block w-full text-right mt-4 underline hover:text-brandYellow"
                      >
                        Download Order Certificate
                      </a>
                    </div>
                  )}
                </div>
              </div>
            ) : Number(details.offerPrice) > 0 ||
              Number(details.buyoutPrice) > 0 ? (
              <div className="mt-6">
                <H3 title={"Offer Details"} />
                <div className="mt-2 rounded">
                  <div className="bg-lightGray p-4 mt-4 rounded">
                    <p className="mb-4 font-medium">Standard offer</p>
                    <div className="mt-0 flex flex-row justify-between">
                      <p className="">Listed Price</p>
                      <p className="mt-2">
                        {Number(details.offerPrice) > 0
                          ? "$" + convtPrice(details.offerPrice)
                          : "Not Offered"}
                      </p>
                    </div>
                    <OrderViewItem
                      name={"WatchSpace Fee"}
                      value={
                        Number(details.offerPrice) > 0
                          ? "$" + convtPrice(details.commision)
                          : "Not Offered"
                      }
                    />
                    {Number(details.serviceFee) > 0 ? (
                      <OrderViewItem
                        name={"Service Fee"}
                        value={
                          Number(details.offerPrice) > 0
                            ? "$" + convtPrice(details.serviceFee)
                            : "Not Offered"
                        }
                      />
                    ) : (
                      <></>
                    )}
                    <div
                      className={`flex flex-row justify-between mt-4 border-t border-solid border-borderGray border-black pt-4`}
                    >
                      <p className="font-medium">{"Customer Earnings"}</p>
                      <p className="font-medium">
                        {Number(details.offerPrice) > 0
                          ? "$" +
                            convtPrice(
                              details.orderTotal ? details.orderTotal : 0
                            )
                          : "Not Offered"}
                      </p>
                    </div>
                  </div>

                  <div className="bg-lightGray p-4 mt-4 rounded">
                    <p className="font-medium">Buyout offer</p>
                    <OrderViewItem
                      name={"Buyout Price"}
                      value={
                        Number(details.buyoutPrice) > 0
                          ? "$" + convtPrice(details.buyoutPrice)
                          : "Not Offered"
                      }
                    />
                  </div>

                  {details.orderPdfUri && (
                    <div className="bg-lightGray p-4 mt-4 rounded">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={S3_DOMAIN + details.orderPdfUri}
                        className="block w-full text-right mt-4 underline hover:text-brandYellow"
                      >
                        Download Order Certificate
                      </a>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <></>
            )}

            {details.type === "DEFAULT" &&
            details.sellOrderStatus === "COMPLETED" &&
            details.actions.includes("SEND_PAYMENT") ? (
              <div className="w-full mt-4">
                <ActionWithFileButton
                  orderType={"consignment"}
                  actionName="SEND_PAYMENT"
                  actionButtonName="Send Payment"
                  modalTitle="Send Payment"
                  modalText="Please upload the payment result"
                  successActionText="Payment result has been sent successfully"
                  modalButtonText="Confirm"
                  orderId={details.id}
                  userType={"platform"}
                  successActionDone={successActionDone}
                />
              </div>
            ) : (
              <></>
            )}
            {details.type === "BUYOUT" &&
            details.actions.includes("SEND_PAYMENT") ? (
              <div className="w-full mt-4">
                <ActionWithFileButton
                  orderType={"consignment"}
                  actionName="SEND_PAYMENT"
                  actionButtonName="Send Payment"
                  modalTitle="Send Payment"
                  modalText="Please upload the payment result"
                  successActionText="Payment result has been sent successfully"
                  modalButtonText="Confirm"
                  orderId={details.id}
                  userType={"platform"}
                  successActionDone={successActionDone}
                />
              </div>
            ) : (
              <></>
            )}

            {details.currState === "COMPLETED" && (
              <div className="mt-6">
                <H3 title={"Product Status"} />
                <div className="bg-lightGray p-4 mt-2 rounded">
                  <ViewItem
                    name={"Product Status"}
                    value={details.productStatus}
                  />
                  {Number(details.sellOrderId) > 0 ? (
                    <div className="mt-6">
                      <p className="text-sm text-gray">Order Status</p>
                      <p className="text-sm text-green-500 my-2">
                        {details.sellOrderStatusDesc}
                      </p>
                      <TextLinkButton
                        name={"View Order Details"}
                        path={`/orders/details/${details.sellOrderId}`}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                  <ViewItem
                    name={"Order Status"}
                    value={details.orderCompleted ? "Order Completed" : ""}
                  />
                </div>
              </div>
            )}

            {/* order actions */}
            <div className="grid grid-cols-2 gap-6">
              {details.actions.includes("SEND_OFFER") ? (
                <div className="w-full mt-4">
                  <MakeAnOfferButton
                    orderType={"consignment"}
                    actionName="SEND_OFFER"
                    actionButtonName="Make An Offer"
                    modalTitle="Make An Offer"
                    modalText="Send an offer to client"
                    successActionText="Offer send successfully"
                    modalButtonText="Confirm Offer"
                    orderId={details.id}
                    userType={"platform"}
                    successActionDone={successActionDone}
                    needOfferPrice={true}
                    needCommision={true}
                    needService={false}
                    needBuyoutOffer={true}
                    oriOfferPrice={
                      Number(details.offerPrice) > 0 ? details.offerPrice : ""
                    }
                    oriCommision={
                      Number(details.commision) > 0 ? details.commision : ""
                    }
                    oriServiceFee={
                      Number(details.serviceFee) > 0 ? details.serviceFee : ""
                    }
                    oriBuyoutOffer={
                      Number(details.buyoutPrice) > 0 ? details.buyoutPrice : ""
                    }
                  />
                </div>
              ) : (
                <></>
              )}
              {details.actions.includes("REJECT_ORDER") ? (
                <div className="w-full mt-4">
                  <OrderActionButton
                    orderType={"consignment"}
                    actionName="REJECT_ORDER"
                    actionButtonName="Watch Not Accepted"
                    modalTitle="Watch Not Accepted"
                    modalText="Are you sure you want to reject this watch?"
                    successActionText="Quote has been proceeded successfully"
                    modalButtonText="Yes, Proceed"
                    orderId={details.id}
                    userType={"platform"}
                    successActionDone={successActionDone}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>

            {details.actions.includes("UPDATE_OFFER") ? (
              <div className="w-full mt-4">
                <MakeAnOfferButton
                  orderType={"consignment"}
                  actionName="UPDATE_OFFER"
                  actionButtonName="Update Offer"
                  modalTitle="Update Offer"
                  modalText="Update offer"
                  successActionText="Offer updated successfully"
                  modalButtonText="Confirm Offer"
                  orderId={details.id}
                  userType={"platform"}
                  successActionDone={successActionDone}
                  needOfferPrice={true}
                  needCommision={true}
                  needService={false}
                  needBuyoutOffer={true}
                  oriOfferPrice={
                    Number(details.offerPrice) > 0 ? details.offerPrice : ""
                  }
                  oriCommision={
                    Number(details.commision) > 0 ? details.commision : ""
                  }
                  oriServiceFee={
                    Number(details.serviceFee) > 0 ? details.serviceFee : ""
                  }
                  oriBuyoutOffer={
                    Number(details.buyoutPrice) > 0 ? details.buyoutPrice : ""
                  }
                />
              </div>
            ) : (
              <></>
            )}

            {details.actions.includes("SEND_SHIPPING_LABEL_TO_CLIENT") ? (
              <div className="w-full mt-4">
                <ActionWithFileButton
                  orderType={"consignment"}
                  actionName="SEND_SHIPPING_LABEL_TO_CLIENT"
                  actionButtonName="Send Shipping Label"
                  modalTitle="Send Shipping Label"
                  modalText="Please upload the shipping label"
                  successActionText="Shipping label has been sent successfully"
                  modalButtonText="Confirm"
                  orderId={details.id}
                  userType={"platform"}
                  successActionDone={successActionDone}
                />
              </div>
            ) : (
              <></>
            )}

            {details.actions.includes("PRODUCT_RECEIVED") ? (
              <div className="w-full mt-4">
                <OrderActionButton
                  orderType={"consignment"}
                  actionName="PRODUCT_RECEIVED"
                  actionButtonName="Watch Received"
                  modalTitle="Watch Received"
                  modalText="Are you sure you have received the watch?"
                  successActionText="Watch has been received successfully"
                  modalButtonText="Yes, Proceed"
                  orderId={details.id}
                  userType={"platform"}
                  successActionDone={successActionDone}
                />
              </div>
            ) : (
              <></>
            )}

            {details.currState === "PRODUCT_RECEIVED_BY_PLATFORM" && (
              <div className="w-full flex">
                {details.actions.includes("PRODUCT_INSPECTION_PASS") ? (
                  <div className="w-1/2 mt-4">
                    <MakeAnOfferButton
                      orderType={"consignment"}
                      actionName="PRODUCT_INSPECTION_PASS"
                      actionButtonName="Passing the Inspection"
                      modalTitle="Passing the Inspection"
                      modalText="Watch inspection passed, please confirm the final listing price"
                      successActionText="Product successfully passed inspection."
                      modalButtonText="Submit"
                      orderId={details.id}
                      userType={"platform"}
                      successActionDone={successActionDone}
                      needOfferPrice={true}
                      needCommision={true}
                      needService={false}
                      needBuyoutOffer={true}
                      oriOfferPrice={
                        Number(details.offerPrice) > 0 ? details.offerPrice : ""
                      }
                      oriCommision={
                        Number(details.commision) > 0 ? details.commision : ""
                      }
                      oriServiceFee={
                        Number(details.serviceFee) > 0 ? details.serviceFee : ""
                      }
                      oriBuyoutOffer={
                        Number(details.buyoutPrice) > 0
                          ? details.buyoutPrice
                          : ""
                      }
                    />
                  </div>
                ) : (
                  <></>
                )}
                {details.actions.includes("PRODUCT_INSPECTION_FAIL") ? (
                  <div className="w-1/2 mt-4 ml-4">
                    <MakeAnOfferButton
                      orderType={"consignment"}
                      actionName="PRODUCT_INSPECTION_FAIL"
                      actionButtonName="Failing the Inspection"
                      modalTitle="Failing the Inspection"
                      modalText="Please add a service fee to this quote"
                      successActionText="Update service fee successfully!"
                      modalButtonText="Submit"
                      orderId={details.id}
                      userType={"platform"}
                      successActionDone={successActionDone}
                      needOfferPrice={true}
                      needCommision={true}
                      needService={true}
                      needBuyoutOffer={true}
                      oriOfferPrice={
                        Number(details.offerPrice) > 0 ? details.offerPrice : ""
                      }
                      oriCommision={
                        Number(details.commision) > 0 ? details.commision : ""
                      }
                      oriServiceFee={
                        Number(details.serviceFee) > 0 ? details.serviceFee : ""
                      }
                      oriBuyoutOffer={
                        Number(details.buyoutPrice) > 0
                          ? details.buyoutPrice
                          : ""
                      }
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            )}

            <div className="w-full mt-4 grid grid-cols-2 gap-6">
              {details.actions.includes("UPDATE_SERVICE_FEE") && (
                <MakeAnOfferButton
                  orderType={"consignment"}
                  actionName="UPDATE_SERVICE_FEE"
                  actionButtonName="Update Offer"
                  modalTitle="Update Offer"
                  modalText="Update offer"
                  successActionText="Offer updated successfully"
                  modalButtonText="Confirm Offer"
                  orderId={details.id}
                  userType={"platform"}
                  successActionDone={successActionDone}
                  needOfferPrice={true}
                  needCommision={true}
                  needService={true}
                  needBuyoutOffer={true}
                  oriOfferPrice={
                    Number(details.offerPrice) > 0 ? details.offerPrice : ""
                  }
                  oriCommision={
                    Number(details.commision) > 0 ? details.commision : ""
                  }
                  oriServiceFee={
                    Number(details.serviceFee) > 0 ? details.serviceFee : ""
                  }
                  oriBuyoutOffer={
                    Number(details.buyoutPrice) > 0 ? details.buyoutPrice : ""
                  }
                />
              )}
              {details.actions.includes("CONFIRM_SERVICE_FEE") &&
                showAdminConfirmAgreementBtn && (
                  <AdminConfimAgreementButton
                    orderType={"consignment"}
                    actionName="CONFIRM_SERVICE_FEE"
                    actionButtonName="Confirm Agreement"
                    modalTitle="Confirm Agreement"
                    modalText="Please select the consignment type"
                    successActionText="Agreement updated successfully"
                    modalButtonText="Confirm Agreement"
                    orderId={details.id}
                    userType={"platform"}
                    successActionDone={successActionDone}
                    oriOfferPrice={
                      Number(details.offerPrice) > 0 ? details.offerPrice : ""
                    }
                    oriCommision={
                      Number(details.commision) > 0 ? details.commision : ""
                    }
                    oriServiceFee={
                      Number(details.serviceFee) > 0 ? details.serviceFee : ""
                    }
                    oriOrderTotal={Number(details.orderTotal)}
                    oriBuyoutOffer={
                      Number(details.buyoutPrice) > 0 ? details.buyoutPrice : ""
                    }
                  />
                )}
            </div>

            <div className="w-full mt-4 grid grid-cols-2 gap-6">
              {details.actions.includes("UPDATE_AGREEMENT") && (
                <MakeAnOfferButton
                  orderType={"consignment"}
                  actionName="UPDATE_AGREEMENT"
                  actionButtonName="Update Offer"
                  modalTitle="Update Offer"
                  modalText="Update offer"
                  successActionText="Offer updated successfully"
                  modalButtonText="Confirm Offer"
                  orderId={details.id}
                  userType={"platform"}
                  successActionDone={successActionDone}
                  needOfferPrice={true}
                  needCommision={true}
                  needService={false}
                  needBuyoutOffer={true}
                  oriOfferPrice={
                    Number(details.offerPrice) > 0 ? details.offerPrice : ""
                  }
                  oriCommision={
                    Number(details.commision) > 0 ? details.commision : ""
                  }
                  oriServiceFee={
                    Number(details.serviceFee) > 0 ? details.serviceFee : ""
                  }
                  oriBuyoutOffer={
                    Number(details.buyoutPrice) > 0 ? details.buyoutPrice : ""
                  }
                />
              )}

              {details.actions.includes("CONFIRM_CONSIGNMENT_AGREEMENT") &&
                showAdminConfirmAgreementBtn && (
                  <AdminConfimAgreementButton
                    orderType={"consignment"}
                    actionName="CONFIRM_CONSIGNMENT_AGREEMENT"
                    actionButtonName="Confirm Agreement"
                    modalTitle="Confirm Agreement"
                    modalText="Please select the consignment type"
                    successActionText="Agreement updated successfully"
                    modalButtonText="Confirm Agreement"
                    orderId={details.id}
                    userType={"platform"}
                    successActionDone={successActionDone}
                    oriOfferPrice={
                      Number(details.offerPrice) > 0 ? details.offerPrice : ""
                    }
                    oriCommision={
                      Number(details.commision) > 0 ? details.commision : ""
                    }
                    oriServiceFee={
                      Number(details.serviceFee) > 0 ? details.serviceFee : ""
                    }
                    oriOrderTotal={Number(details.orderTotal)}
                    oriBuyoutOffer={
                      Number(details.buyoutPrice) > 0 ? details.buyoutPrice : ""
                    }
                  />
                )}
            </div>

            {details.actions.includes("COMPLETE_SERVICE") ? (
              <div className="w-full mt-4">
                <OrderActionButton
                  orderType={"consignment"}
                  actionName="COMPLETE_SERVICE"
                  actionButtonName="Complete Service"
                  modalTitle="Complete Service"
                  modalText="Are you sure you have complete the service?"
                  successActionText="Proceeded successfully"
                  modalButtonText="Yes, Proceed"
                  orderId={details.id}
                  userType={"platform"}
                  successActionDone={successActionDone}
                />
              </div>
            ) : (
              <></>
            )}

            {details.currState === "LIST_PRODUCT" ? (
              <div className="mt-4">
                <LinkButton
                  name="List Product"
                  path={`/products/new/consignment/${details.id}`}
                  data={{
                    brandName: details.brandName,
                    modelName: details.modelName,
                    referenceNumber: details.referenceNumber,
                    year: details.year,
                    serialNumber: details.serialNumber,
                    listingPrice: details.offerPrice,
                  }}
                />
              </div>
            ) : (
              <></>
            )}

            {details.currState === "COMPLETED" ? (
              <div className="grid grid-cols-2 gap-4 mt-4">
                <div className="">
                  <LinkButton
                    name="View Product Details"
                    path={`/products/details/${details.productId}`}
                  />
                </div>
                {details.actions.includes("UPDATE_PRODUCT_PRICE") &&
                details.productStatus !== "Sold" ? (
                  <div className="w-full">
                    <MakeAnOfferButton
                      orderType={"consignment"}
                      actionName="UPDATE_PRODUCT_PRICE"
                      actionButtonName="Update Listing Price"
                      modalTitle="Update Listing Price"
                      modalText="Update listing price"
                      successActionText="Listing price updated successfully"
                      modalButtonText="Confirm"
                      orderId={details.id}
                      userType={"platform"}
                      successActionDone={successActionDone}
                      needOfferPrice={true}
                      needCommision={true}
                      needService={false}
                      oriOfferPrice={
                        Number(details.offerPrice) > 0 ? details.offerPrice : ""
                      }
                      oriCommision={
                        Number(details.commision) > 0 ? details.commision : ""
                      }
                      oriServiceFee={
                        Number(details.serviceFee) > 0 ? details.serviceFee : ""
                      }
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <></>
            )}

            {/* order actions */}

            <div className="mt-2 border-b border-solid border-borderGray py-4">
              <H3 title={"Watch Details"} />
              <div className="w-full mt-2">
                <p className="text-sm text-gray mt-4">Watch Images</p>
                <div className="w-full mt-2">
                  <ProductImageListPreview images={details.images} />
                </div>

                <div className="w-fully mt-2">
                  <div className="w-full grid grid-cols-2">
                    <ViewItem name={"Brand"} value={details.brandName} />
                    <ViewItem name={"Model"} value={details.modelName} />
                    <ViewItem
                      name={"Reference Number"}
                      value={details.referenceNumber}
                    />
                    <ViewItem name={"Product of Year"} value={details.year} />
                    <ViewItem
                      name={"Condition"}
                      value={details.watchCondition}
                    />
                  </div>
                  <ViewItem name={"Serial NO."} value={details.serialNumber} />

                  <BoolViewItem
                    name={"Is your watch fully functioning?*"}
                    value={details.functioning}
                    additionText={
                      details.functioning === 0
                        ? `Not functioning parts: ${details.notFunctionParts}`
                        : ""
                    }
                  />
                  <BoolViewItem
                    name={"Do you have the original purchase receipt?*"}
                    value={details.hasReceipt}
                  />
                  <BoolViewItem
                    name={"Does your watch come with its original box?*"}
                    value={details.hasBox}
                  />
                  <BoolViewItem
                    name={"Does your watch have all of its original papers?*"}
                    value={details.hasPaper}
                    additionText={
                      details.hasPaper === 1
                        ? `${details.paperMonth} ${details.paperYear}`
                        : ""
                    }
                  />
                  <BoolViewItem
                    name={"Is there an active warranty from the brand?"}
                    value={details.hasWarranty}
                    additionText={
                      details.hasWarranty === 1
                        ? `${details.warrantyMonth} ${details.warrantyYear}`
                        : ""
                    }
                  />
                  <BoolViewItem
                    name={"Does your watch have its original clasp?"}
                    value={details.hasClasp}
                  />
                  <BoolViewItem
                    name={"Does your watch have its original bracelet/strap?"}
                    value={details.hasStrap}
                  />
                  <BoolViewItem
                    name={
                      "Do you have Extract from the Archives from the manufacture?"
                    }
                    value={details.hasExtract}
                  />
                  <BoolViewItem
                    name={"Do you have the service papers?"}
                    value={details.hasService}
                    additionText={
                      details.hasService === 1
                        ? `${details.serviceMonth} ${details.serviceYear}`
                        : ""
                    }
                  />
                  <ViewItem
                    name={"Where did you purchase this watch?"}
                    value={details.buyMethod}
                  />
                  <ViewItem
                    name={
                      "Any other details you'd like to share about your watch?"
                    }
                    value={details.notes}
                  />
                </div>
              </div>
            </div>

            {/* client info */}
            <div className="w-full h-full border-b border-solid border-borderGray mt-6 py-4">
              <H3 title={"Contact User"} />

              <div className="flex justify-between flex-col lg:flex-row">
                <div className="flex items-center mt-4">
                  <UserAvatar name={details.userName} />{" "}
                  <span className="ml-4">{details.userName}</span>
                </div>

                <div className="w-fit text-sm text-darkGray mt-4">
                  <ContactButton
                    recipientId={details.userId}
                    productId={details.id}
                    refType={"CONSIGNMENT"}
                    buttonName={"Contact User"}
                  />
                </div>
              </div>
            </div>

            {/* buyer info */}
            <div className="w-full h-full border-b border-solid border-borderGray mt-6  py-4">
              <H3 title={"Contact Info"} />
              <div className="text-sm text-darkGray mt-4">
                <AddressLine
                  firstName={details.contactInfo.firstName}
                  lastName={details.contactInfo.lastName}
                  phone={details.contactInfo.phone}
                  streetLine1={details.contactInfo.streetLine1}
                  city={details.contactInfo.city}
                  country={details.contactInfo.countryName}
                  region={details.contactInfo.region}
                  postalCode={details.contactInfo.postalCode}
                  showName={true}
                  showPhone={true}
                />
              </div>
            </div>

            <div className="w-full h-full border-b border-solid border-borderGray mt-4 lg:justify-between py-4">
              <H3 title={"Quote Logs"} />
              <div className="text-sm text-darkGray mt-4">
                <div>
                  <span className="text-sm">
                    {convToLocalDate(details.atime)}
                  </span>
                  <span className="text-sm ml-4">Quote created</span>
                </div>

                {details.actionRecords.length ? (
                  details.actionRecords.map((item, index) => (
                    <div key={index} className="mt-2">
                      <span className="text-sm">
                        {convToLocalDate(item.atime)}
                      </span>
                      <span className="text-sm ml-4"> {item.actionName}</span>
                      {item.userType && (
                        <span className="text-sm">
                          {" by " + item.userType}
                        </span>
                      )}
                    </div>
                  ))
                ) : (
                  <></>
                )}
              </div>
            </div>

            {/* order actions */}
            <div className="w-full flex flex-col justify-start py-4">
              {details.actions.includes("CANCEL_ORDER") ? (
                <div className="mt-4">
                  <OrderActionButton
                    orderType={"consignment"}
                    actionName="CANCEL_ORDER"
                    actionButtonName="Cancel Quote"
                    modalTitle="Cancel Quote"
                    modalText="Are you sure you want to cancel the quote?"
                    successActionText="Quote has been cancelled!"
                    modalButtonText="Yes, Please Cancel The Quote"
                    orderId={details.id}
                    userType={"platform"}
                    successActionDone={successActionDone}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        ) : error ? (
          <ErrorMessage mess={error} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
