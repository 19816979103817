import React, { useEffect, useState } from "react";
import SingleImageUploader from "../../../components/SingleImageUploader";
import { Button } from "../../../components/buttons";
import {
  FieldLabel,
  SelectField,
  TextAreaField,
  TextField,
} from "../../../components/inputs";
import { K_COLLECTION_POSITIONS } from "../../../constantData/K_COLLECTION_POSITIONS";

export default function CollectionForm({ type, initialValues, onSave }) {
  const [formState, setFormState] = useState(initialValues);

  const getUploadedImages = (file) => {
    if (file.uri) {
      setFormState({ ...formState, ["image"]: file.uri });
    }
  };

  const [formErrors, setFormErrors] = useState({});
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };

  // // // // // //
  useEffect(() => {
    if (type === "edit") {
      let find_position = K_COLLECTION_POSITIONS.find(
        (x) => x.value === initialValues.position
      );
      setFormState({
        position: find_position ? find_position : {},
        image: initialValues.image,
        name: initialValues.name,
        pageTitle: initialValues.pageTitle,
        pageDescription: initialValues.pageDescription,
        description: initialValues.description,
        metaTitle: initialValues.metaTitle,
        metaDescription: initialValues.metaDescription,
        imageAlt: initialValues.imageAlt,
      });
    } else {
      setFormState(initialValues);
    }
  }, [type, initialValues]);

  // // // // // //

  const handleSubmit = () => {
    const hasErrors = validate(formState);

    if (Object.keys(hasErrors).length === 0) {
      setFormErrors({});
      let submittedValues = {
        name: formState.name,
        position: formState.position ? formState.position.value : "",
        image: formState.image,
        description: formState.description,
        pageDescription: formState.pageDescription,
        pageTitle: formState.pageTitle,
        metaTitle: formState.metaTitle,
        metaDescription: formState.metaDescription,
        imageAlt: formState.imageAlt,
      };
      onSave(submittedValues);
    } else {
      setFormErrors(hasErrors);
    }
  };

  const validate = (values) => {
    const errors = {};

    if (!values.image) {
      errors.image = "Collection image is required.";
    }
    if (!values.name) {
      errors.name = "Collection name is required.";
    }
    if (!values.pageTitle) {
      errors.pageTitle = "Collection page title is required.";
    }
    if (!values.metaTitle) {
      errors.metaTitle = "Collection meta title is required.";
    }
    if (!values.metaDescription) {
      errors.metaDescription = "Collection meta description is required.";
    }
    if (!values.imageAlt) {
      errors.imageAlt = "Collection main image alt is required.";
    }

    return errors;
  };

  return (
    <div>
      <div className="w-full grid grid-cols-2 gap-6">
        <div className="w-full">
          <TextField
            type="text"
            label="Collection Name"
            name="name"
            value={formState.name}
            onChange={(name) =>
              handleChange({
                target: { value: name, name: "name" },
              })
            }
            error={formErrors.name}
          />
          <TextField
            type="text"
            label="Collection Page Title"
            name="pageTitle"
            value={formState.pageTitle}
            onChange={(pageTitle) =>
              handleChange({
                target: { value: pageTitle, name: "pageTitle" },
              })
            }
            error={formErrors.pageTitle}
          />
          <SelectField
            label="Position"
            placeHolder="Select position"
            selectOptions={K_COLLECTION_POSITIONS}
            value={formState.position}
            onChange={(position) =>
              handleChange({
                target: { value: position, name: "position" },
              })
            }
            error={formErrors.position}
          />

          <TextAreaField
            type="text"
            label="Short Description"
            name="description"
            value={formState.description}
            rows={6}
            placeholder="Collection short description..."
            onChange={(description) =>
              handleChange({
                target: { value: description, name: "description" },
              })
            }
          />
            <TextAreaField
            type="text"
            label="Page Long Description"
            name="description"
            value={formState.pageDescription}
            rows={6}
            placeholder="Collection long description..."
            onChange={(pageDescription) =>
              handleChange({
                target: { value: pageDescription, name: "pageDescription" },
              })
            }
          />

          <TextField
            type="text"
            label="Meta Title"
            name="metaTitle"
            value={formState.metaTitle}
            onChange={(metaTitle) =>
              handleChange({
                target: { value: metaTitle, name: "metaTitle" },
              })
            }
            error={formErrors.metaTitle}
          />

          <TextAreaField
            type="text"
            label="Meta Description"
            name="metaDescription"
            value={formState.metaDescription}
            rows={6}
            placeholder="Meta description..."
            onChange={(metaDescription) =>
              handleChange({
                target: { value: metaDescription, name: "metaDescription" },
              })
            }
            error={formErrors.metaDescription}
          />
          <TextField
            type="text"
            label="Main Image Alt"
            name="imageAlt"
            value={formState.imageAlt}
            onChange={(imageAlt) =>
              handleChange({
                target: { value: imageAlt, name: "imageAlt" },
              })
            }
            error={formErrors.imageAlt}
          />
        </div>
        <div className="w-full">
          <div className="w-full">
            <FieldLabel label="Collection Image" />
            <div className="w-full">
              <div className="w-full">
                <SingleImageUploader
                  onUpload={getUploadedImages}
                  imageError={formErrors.image}
                  uri={formState.image}
                  folderName="collections"
                  compress={false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-8 w-full bg-red-200">
        <Button
          name={type === "new" ? "Save" : "Update"}
          action={handleSubmit}
        />
      </div>
    </div>
  );
}
