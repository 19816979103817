import { Breadcrumbs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { getImageList } from "../../api/webContentRequets";
import ScreenTitle from "../../components/ScreenTitle";
import { EditLinkButton } from "../../components/buttons";
import commonStyle from "../../style/commonStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle";

const S3_DOMAIN = process.env.REACT_APP_S3_DOMAIN;


export default function ImageList() {
  useDocumentTitle("Images");

  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  const [requestStatus, setRequestStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const [imageList, setImageList] = useState([]);

  useEffect(() => {
    const getImages = async () => {
      try {
        setRequestStatus((preState) => ({
          ...preState,
          loading: true,
        }));

        const responseData = await getImageList(config);

        if (responseData.data.success) {
          setRequestStatus((preState) => ({
            ...preState,
            loading: false,
            success: true,
            error: "",
          }));
          setImageList(responseData.data.data);
        } else {
          throw responseData.data.message;
        }
      } catch (error) {
        setRequestStatus((preState) => ({
          ...preState,
          loading: false,
          error: "error",
        }));
      }
    };
    getImages();
  }, []);

  return (
    <div className={commonStyle.pageContainer}>
      <div className="w-full p-8">
        <div className={commonStyle.breadcrumbsContainer}>
          <Breadcrumbs aria-label="breadcrumb">
            <NavLink underline="hover" color="inherit" to="/">
              Home
            </NavLink>
            <NavLink underline="hover" color="inherit" to="/web-content/images">
              Web Images
            </NavLink>
          </Breadcrumbs>
        </div>
        <ScreenTitle title="Images" />
        {requestStatus.success ? (
          <div className={commonStyle.longDataTable}>
            <div className="mt-8">
              <table className={commonStyle.pageTable}>
                <thead>
                  <tr>
                    <th>Page Name</th>
                    <th>Image</th>

                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {imageList.length > 0 ? (
                    imageList.map((item, index) => (
                      <tr key={index}>
                        <td>{item.name}</td>
                        <td className="p-2">
                          <div className="w-[80%] h-[480px]">
                            {item.image ? (
                              <img
                                alt={item.image}
                                src={S3_DOMAIN + item.image}
                                className="w-full h-full object-cover"
                                style={{
                                  maxWidth: "100%",
                                  maxHeight: "100%",
                                }}
                              />
                            ) : (
                              <img
                                alt={"default-watch"}
                                src="/default-watch.svg"
                                className="w-1/3 h-full object-cover"
                                style={{
                                  maxWidth: "30%",
                                  maxHeight: "30%",
                                }}
                              />
                            )}
                          </div>
                        </td>

                        <td>
                          <EditLinkButton path={`/web-content/images/edit/${item.id}`} data={item}/>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td>There are no image available</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
